import { Post, ApiRoutes } from "./Api"

export const EditMissionProps = async (itemId,props) =>{
    try {
        const res = await Post(ApiRoutes.missionEdit, { id: itemId, ...props});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error EditMissionProps');
        }        
    } catch (err) {
        console.error('EditMissionProps Err',err);
    }
};

export const ListMissions = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.missionList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
        console.error('Mission resultList',err);
    }
};

export const DemandMission = async (opId,staff,type) =>{
    try {
        const res = await Post(ApiRoutes.missionDemand, { opId, staff, type});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error DemandMission');
        }
        return false;    
    } catch (err) {
        console.error('DemandMission Err',err);
    }
};