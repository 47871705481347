import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
};

export const missionSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getMission: (state) => {
      return state.value 
    },
    saveMission: (state, action) => {
      state.value = action.payload;
    },
    resetMission: (state) => {
      state.value = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { getMission, saveMission, resetMission } = missionSlice.actions

export default missionSlice.reducer