import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Document, Page, Text, View, Image, StyleSheet, Font } from '@react-pdf/renderer';

import { doS,
    getLevelHospitality, 
    getLanguageLevel, 
    capitalize, 
    isSundayOrHoliday,
    getGender, 
    getDiplomas,
    FormatMissionSlotsByLocation,
    FormatMissionSlotsByRoles,
    calculateHoursDifference,
    getAddress,
    calculateTotals,
    getLanguage,
    getCountry,    
    QuoteTotals,
    formatNumber,
    
    getAge } from "../../../utils/miscHelpers"

import { paymentSpecials } from "../../../utils/constants"
import PoppinsRegular from '../../../assets/font/Poppins-Regular.ttf';
import PoppinsBold from '../../../assets/font/Poppins-Bold.ttf';
import PoppinsItalic from '../../../assets/font/Poppins-Italic.ttf';
import logoBmg from '../../../assets/images/logo_tagline_accent.png'

const locale = require('dayjs/locale/fr');
dayjs.locale(locale);

const CoverPage = ({selectedTypes,storedOperation, staffList, version}) => {

    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.cover}>
                <View style={styles.logoContainer}>
                    <Image style={styles.logo} src={logoBmg} />
                </View>
            </Page>            
        </>
    );

};

const FooterSection = ({selectedTypes, staffList, selectedUsers,page}) =>{
    
    let totalPages = 1;
    if (selectedUsers) totalPages += selectedUsers.length;
    if (selectedTypes) {
        if (selectedTypes.includes('user')) totalPages += selectedTypes.length - 1;
        if (!selectedTypes.includes('user')) totalPages += selectedTypes.length;
    } 

    return(
        <View style={styles.footer}>
            <Text>Ce document et son contenu sont confidentiels. Tous droits réservés - {dayjs().year()}</Text>
            <Text style={styles.smallerFont}>{page} / {totalPages}</Text>            
        </View>
    );
};

const MissionSection = ({storedOperation}) =>{
    // console.log('storedOperation._id :>> ', storedOperation._id);
    const locations = FormatMissionSlotsByLocation(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : [])
    return (
        <View>
            <View style={[styles.block,{marginTop:16}]}>
                {locations && locations.length > 0 && locations.map((location,idx)=>{
                    const roles = FormatMissionSlotsByRoles(location.missionSlots);                           
                    return(
                        <View key={'loca'+idx.toString()}>
                            <Text style={[styles.accent, styles.bold]}>{getAddress(location)}</Text>
                            {roles && Object.keys(roles).length > 0 && Object.keys(roles).map((roleKey,ridx)=>{   
                                const role = roles[roleKey];
                                let roleTotal = 0;
                                let totalDuration = 0;
                                const rKey = 'loca'+idx.toString()+'-role'+ridx.toString();
                                return(
                                    <View key={rKey} style={{marginTop:6,marginBottom:6}}>
                                        <View key={rKey+'-slot0'} style={[styles.rowWithColumns,{marginBottom:6}]}>
                                            <Text style={[styles.bold,{width:500}]}>{capitalize(role.name)} - {formatNumber(role.cost,role.currency.toUpperCase())}/HR</Text>
                                            <Text style={{fontSize:10,width:70,textAlign:'center'}}>Durée</Text>
                                            <Text style={{fontSize:10,marginLeft:16,width:50,textAlign:'center'}}>Staff</Text>
                                            <Text style={{fontSize:10,marginLeft:16,width:150,textAlign:'center'}}>Total</Text>
                                        </View>                                          
                                        {role && role.missionSlots && role.missionSlots.length > 0 && role.missionSlots.map((slot, sidx)=>{
                                            let { totalHrs, overTimeHrs, normalHrs} = calculateHoursDifference(slot.timeSlot.stime,slot.timeSlot.etime);
                                            const isHoliDay = isSundayOrHoliday(slot.eday);
                                            let minHr = false;

                                            // Minimun Hours billed
                                            if (totalHrs < 4) {
                                                let difference = 4 - totalHrs;
                                                normalHrs += difference;
                                                totalHrs = 4;
                                                minHr=true;
                                            }

                                            totalDuration += Number(slot.workForce) * totalHrs;

                                            const normalHrsWorkForce = Number(slot.workForce) * normalHrs;
                                            const overTimeHrsWorkForce = Number(slot.workForce) * overTimeHrs;

                                            const normalHrsmissionTotal   = calculateTotals(role.cost,normalHrsWorkForce, isHoliDay,'normal'); // Number(mission.role?.cost) * ( isHoliDay ? total.holiday : 1) * normalHrsWorkForce ;
                                            const overTimeHrsmissionTotal = calculateTotals(role.cost,overTimeHrsWorkForce,isHoliDay,'overTime');  //Number(mission.role?.cost) * ( isHoliDay ? total.holiday : 1) * total.extra * overTimeHrsWorkForce ;
                                            const slotTotal            = normalHrsmissionTotal + overTimeHrsmissionTotal;

                                            roleTotal += slotTotal;
                                            //console.log('sidx :>> ', sidx,role.missionSlots.length,rKey+'-slot'+(sidx+1) , rKey+'-slot'+(role.missionSlots.length+1));
                                            return(
                                                <View key={rKey+'-slot'+(sidx+1)} style={styles.rowWithColumns}>                                                    
                                                    <Text style={{fontSize:11,width:500}}>{capitalize(dayjs(slot.eday).format('dddd D MMMM YYYY'))} de {slot.timeSlot.stime} à {slot.timeSlot.etime} {isHoliDay?'*':''} {overTimeHrs ? '**':''} {minHr ? '***':''}</Text>
                                                    <Text style={{fontSize:11,width:70,textAlign:'right'}}>{totalHrs}h</Text>
                                                    <Text style={{fontSize:11,marginLeft:16,width:50,textAlign:'center'}}>{slot.workForce}</Text>
                                                    <Text style={{fontSize:11,marginLeft:16,width:150,textAlign:'right'}}>{formatNumber(slotTotal,role.currency.toUpperCase())}</Text>
                                                </View>
                                            )
                                        })}                    
                                        <View key={rKey+'-slot'+(role.missionSlots.length+1)} style={styles.rowWithColumns}>
                                            <Text style={{width:500}}></Text>
                                            <Text style={[styles.bold,{fontSize:11,width:70,textAlign:'right'}]}>{totalDuration}h</Text>
                                            <Text style={{marginLeft:16,width:50}}></Text>
                                            <Text style={[styles.bold,{fontSize:11,marginLeft:16,width:150,textAlign:'right'}]}>{formatNumber(roleTotal,role.currency.toUpperCase())}</Text>
                                        </View>
                                    </View>
                                )
                            })}
                        </View>
                    )
                })}
            </View>
            <View>
                <Text style={{fontSize:9}}>* Dimanche ou jour férié (+{paymentSpecials.holidayPerc})</Text>
                <Text style={{fontSize:9}}>** Contient au moins une heure supplémentaire (+{paymentSpecials.extraPerc})</Text>
                <Text style={{fontSize:9}}>*** Minimum de 4 heures</Text>
            </View>
        </View>            
    );
};

const InfoPage = ({selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {
    if (storedOperation && storedOperation._id) {
        return (
            <>
                <Page size="A4" orientation="portrait" style={styles.page}>
                     <View style={styles.section}>                    
                        <Text style={[styles.bold,styles.h3]}>{capitalize(storedOperation._client.name)}</Text>
                        <Text style={styles.h2}>{capitalize(storedOperation.name)}</Text>
                        <Text >Du {dayjs(storedOperation.sdate).format('dddd D MMMM YYYY')} au {dayjs(storedOperation.edate).format('dddd D MMMM YYYY')}</Text>                    
                        <View style={[styles.block,{marginTop:32,marginBottom:32}]}>
                            <View style={styles.inline}>
                                <Text style={styles.accent}>Type d'Opération: </Text>
                                <Text>{capitalize(storedOperation.type)}</Text>
                            </View>          
                            {/* <View style={styles.inline}>
                                <Text style={styles.accent}>Géré par:  </Text>
                                <Text>{storedOperation._admin ? capitalize(storedOperation._admin.firstName) + ' ' + capitalize(storedOperation._admin.lastName) : '-'}</Text>
                            </View> */}
                            <View style={styles.inline}>
                                <Text style={styles.accent}>Langues souhaitées: </Text>
                                {storedOperation.languages && storedOperation.languages.map((lang,index)=>
                                    <View key={'lang'+index.toString()}>
                                        <Text key={index.toString()} style={{marginRight:'6px'}}>{getLanguage(lang)}</Text>
                                    </View>
                                )}
                            </View>
                            <View style={[styles.inline]}>
                                <Text style={styles.accent}>Staff demandé: </Text>
                                <Text>{storedOperation.bookedTotal ? storedOperation.bookedTotal : '-'}</Text>
                            </View>
                        </View> 
                        <Text style={styles.body}>{storedOperation.bodyClient}</Text>
                        <MissionSection storedOperation={storedOperation}/>      
                        <Text style={[styles.bold,styles.h3]}> </Text>                  
                    </View>   
                    <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
                </Page>            
            </>
        );
    } else {
        return null;
    }
};

const MissionPage = ({selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {    
    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                 <View style={styles.section}>                    
                    <Text style={styles.h2}>Mission{doS(storedOperation.missionSlots)} et Rôles</Text>
                    <MissionSection storedOperation={storedOperation}/>
                </View>   
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );
};

const QuotePage = ({selectedTypes,page,storedOperation, selectedUsers,staffList, version}) => {

    const quoteTotals = QuoteTotals(storedOperation.missionSlots,storedOperation.quoteFees,storedOperation.quoteVat);
 
    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={[styles.section,styles.smallSection]}>
                    <View  style={[styles.rowWithColumns]}>
                        <View>
                            <Text style={[styles.body,styles.bold]}>Be My Guest Sàrl</Text>
                            <Text>Centre administratif</Text>
                            <Text>Chemin des Polonais 16A</Text>
                            <Text>2016 Cortaillod, Switzerland</Text>
                            <Text>+41 78 944 12 79</Text>
                            <Text>info@bemyguest.ch — www.bemyguest.ch</Text>
                            <Text>Numéro de TVA: CHE - 114.565.243 TVA</Text>
                            <View style={[styles.inline,{marginTop:0}]}>
                                <Text style={styles.accent}>Dates de l'évènement: </Text>
                                <Text>{dayjs(storedOperation.sdate).format('DD/MM/YYYY')} au {dayjs(storedOperation.edate).format('DD/MM/YYYY')}</Text>
                            </View>
                        </View>  
                        <View>                 
                            <View style={styles.cartouche}>
                                <Text style={[styles.body,styles.bold]}>{capitalize(storedOperation._client.name)}</Text>
                                <Text>{storedOperation._client.address}</Text>
                                {storedOperation && storedOperation._client.addressAlt &&
                                    <Text>{storedOperation._client.addressAlt}</Text>
                                }
                                <Text>{storedOperation._client.zipcode} {capitalize(storedOperation._client.city)}, {getCountry(storedOperation._client.country)}</Text>
                            </View>  
                            <View style={{display:'flex',alignItems:'flex-end',marginTop:16}}>
                                <Text style={[styles.body,styles.accent,styles.bold]}>Devis - {storedOperation.quote}</Text>
                                <Text >Fait le: {dayjs().format('DD/MM/YYYY')}</Text>   
                                <Text >Date de validité: {dayjs(storedOperation.quoteExpire).format('DD/MM/YYYY')}</Text>
                            </View>       
                        </View>                    
                    </View>        
                    
                    <View style={{marginTop:16,marginBottom:10}}>  
                        <Text>{storedOperation.quoteHeader}</Text>
                    </View>
                    {(quoteTotals.locations && Object.keys(quoteTotals.locations).length > 0) &&
                        quoteTotals.locations.map((location,index)=>{
                            return(
                                <View key={'location-'+index.toString()} style={{marginTop:6}} >
                                    {/* <hr style={[styles.hr]} /> */}
                                    <View>
                                        <View style={[styles.row,{marginBottom:16}]}>
                                            <Text style={styles.bold} >{capitalize(storedOperation.name)} / </Text>                       
                                            <Text className='capitalize h2'>{getAddress(location.location)}</Text>
                                        </View>
                                        {(location.roles && location.roles.length > 0) &&
                                            location.roles.map((role,idx)=>{
                                                const cost = formatNumber(role.cost,storedOperation.quoteCurrency);
                                                const totalNormal = formatNumber(role.totalNormal,storedOperation.quoteCurrency);                                                
                                                return(
                                                    <View key={'role-'+idx} >
                                                        <View  style={styles.rowWithColumns}>
                                                            <Text style={[styles.column,styles.left]}>{capitalize(role.name)}</Text>
                                                            <Text style={styles.column}>{role.employees} Poste{doS(role.employees,'number')}</Text>
                                                            <Text style={styles.column}>{role.edays.length} Jr{role.edays && role.edays.length ? doS(role.edays):''}</Text>
                                                            <Text style={[styles.uppercase,styles.column]}>{cost}/hr</Text>
                                                            <Text style={styles.column}>{role.totalNormalHrs} Hrs</Text>
                                                            <Text style={[styles.uppercase,styles.column]}>{totalNormal}</Text>
                                                        </View>
                                                        {role.totalOverTimeHrs !== 0 &&
                                                            <View style={styles.rowWithColumns}>
                                                                <Text style={[styles.column]}>Overtime</Text>
                                                                <Text style={styles.column}>{role.workForce} Poste{doS(role.workForce,'number')}</Text>
                                                                <Text style={styles.column}>{role.edays.length} Jrs</Text>
                                                                <Text style={[styles.uppercase,styles.column]}>{formatNumber((role.cost * quoteTotals.extra),storedOperation.quoteCurrency)}/hr</Text>
                                                                <Text style={styles.column}>{role.totalOverTimeHrs} Hrs</Text>
                                                                <Text style={[styles.uppercase,styles.column]}>{formatNumber(role.totalOverTime,storedOperation.quoteCurrency)}</Text>
                                                            </View>  
                                                        }                   
                                                    </View>
                                                )
                                            })
                                        }   
                                    </View>
                                </View>
                            )
                        })
                    } 
                    <hr style={[styles.hr]} />
                    <View style={[styles.rowWithColumns]}>
                        <Text>Total Missions</Text>
                        <Text style={styles.uppercase}>{formatNumber(quoteTotals.missions,storedOperation.quoteCurrency)}</Text>
                    </View>    
                    <hr style={styles.hr} />                    
                    {(storedOperation.quoteFees && storedOperation.quoteFees.length !== 0) &&
                        storedOperation.quoteFees.map((quoteFee,index)=>{
                            return(
                                <View key={'fee-'+index.toString()} style={styles.rowWithColumns}>
                                    <Text>{capitalize(quoteFee.name)}</Text>
                                    <Text style={styles.uppercase}>{formatNumber(quoteFee.price,storedOperation.quoteCurrency)}</Text>
                                </View>
                            )
                        })
                    }
                    <hr style={styles.hr} />
                    {(storedOperation.quoteFees && storedOperation.quoteFees.length !== 0) &&                     
                        <View style={styles.rowWithColumns}>
                            <Text>Total Extra</Text>
                            <Text style={styles.uppercase}>{formatNumber(quoteTotals.fees,storedOperation.quoteCurrency)}</Text>
                        </View> 
                    } 
                    <hr style={styles.hr} />
                    <View style={styles.rowWithColumns}>
                        <Text style={styles.bold}>Total Net</Text>
                        <Text style={[styles.uppercase,styles.bold]}>{formatNumber(quoteTotals.net,storedOperation.quoteCurrency)}</Text>
                    </View>        
                    <View style={[styles.rowWithColumns,{marginTop:6,marginBottom:6}]}>
                        <Text>TVA ({storedOperation.quoteVat}%)</Text>
                        <Text style={styles.uppercase}>{formatNumber(quoteTotals.vat,storedOperation.quoteCurrency)}</Text>
                    </View>  
                    <View style={styles.rowWithColumns}>
                        <Text style={styles.bold}>Total</Text>
                        <Text style={[styles.uppercase,styles.bold]}>{formatNumber(quoteTotals.full,storedOperation.quoteCurrency)}</Text>
                    </View>  
                    <hr style={styles.hr} />                                                                  
                    <View style={{marginTop:16,marginBottom:16}}>
                        <Text>{storedOperation.quoteFooter}</Text>
                    </View>
                    <View>
                        <Text style={styles.bold}>TERMES & CONDITIONS</Text>
                    </View>
                    <View style={[styles.smallFont,{marginTop:10,}]}>
                        <Text style={styles.bold}>Pour des paiements en virement bancaire :</Text>
                        <Text>UBS IBAN : CH05 0026 4264 6507 0201 C</Text>
                        <Text>BIC : UBSWCHZH80A</Text>
                    </View>                    
                    <View style={[styles.smallFont,{marginTop:10,marginBottom:10}]}>
                        <Text style={styles.bold}>Ce document contractuel fait office de bon de commande.</Text>
                        <Text>Pour confirmer votre commande, retournez ce devis en mentionnant sur cette page “ Bon pour accord ”, daté et signé.</Text>
                    </View>   
                    <View style={styles.smallFont}>
                        <Text style={styles.bold}>Conditions d'annulation :</Text>
                        <Text style={{marginLeft:16}}>- En cas d'annulation entre 30 à 15 jours avant le mandat, 25% du montant total de l'offre sera facturé.</Text>
                        <Text style={{marginLeft:16}}>- En cas d'annulation entre 14 jours à 8 jours avant le mandat, 50% du montant total de l'offre sera facturé.</Text>
                        <Text style={{marginLeft:16}}>- En cas d'annulation entre 7 jours à 3 jours avant le mandat, 75% du montant total de l'offre sera facturé.</Text>
                        <Text style={{marginLeft:16}}>- Moins de 48h00 avant le mandat, 100% de l'offre sera facturé.</Text>
                    </View>  
                    <View style={[styles.smallFont,{marginTop:10}]}>
                        <Text>Be My Guest Sàrl en qualité d'employeur décisionnaire du personnel mis à disposition durant la mission veillera à ce que les conditions de travail soient respectées au regard de la loi.</Text>
                    </View> 
                    <View style={[styles.inline,styles.smallFont,{marginTop:10}]}>
                        <Text style={{marginRight:160}}>Fait le,</Text>
                        <Text>Votre signature pour accord : </Text>
                    </View>                                                        
                </View>
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );

};  

const ContactPage = ({selectedTypes,page,storedOperation,selectedUsers, selectedContacts, staffList, version}) => {

    return (
        <>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.h2}>Staff Be My Guest</Text>
                    <View style={styles.block}>
                        {staffList && staffList.length > 0 && staffList.map((staff,index)=>{
                            if (!selectedContacts.includes(staff._id)) return false;
                            page++;
                            return (
                                <>                                
                                    <View style={[styles.rowWithColumns,{marginTop:8,marginBottom:8}]}>
                                        <Text style={[styles.bold,{width:200}]}>{capitalize(staff.firstName)} {capitalize(staff.lastName)}</Text>
                                        <Text style={{textAlign:'left',width:300}}>{capitalize(staff.email)}</Text>    
                                        <Text>{capitalize(staff.phone)}</Text>                                    
                                    </View>
                                </>
                            );
                        })}
                    </View>
                </View>
                <FooterSection selectedTypes={selectedTypes} page={page} selectedUsers={selectedUsers} staffList={staffList}/>
            </Page>            
        </>
    );

};  
  


const UserPage =  ({selectedTypes,page,storedOperation, selectedUsers, staffList, version}) => {
    const { t } = useTranslation();   
    let userPage = page;
    
    return(
        <>
            {staffList && staffList.length > 0 && staffList.map((staff,index)=>{
                if (!selectedUsers.includes(staff._id)) return false;                                

                userPage++;
                page++;
                
                // Need to get 2 Media selected and make sure the media order Index match the file array indexes
                const mediasIdx = [];
                const medias = staff.medias.filter((media)=>media.section === 'media'); // Same order & number as Files
                for (const mediaIdx in medias) {
                    if (medias[mediaIdx] && medias[mediaIdx].selected === true) mediasIdx.push(mediaIdx);
                }

                return (
                    <>
                        <Page key={index.toString()} size="A4" orientation="portrait" style={styles.pageUnWrappable}>
                            <View style={styles.sectionImage}>
                                {mediasIdx && mediasIdx.length > 0 && mediasIdx.slice(0, 2).map((mediaIdx,idx)=>{
                                    return(
                                        <View key={idx.toString()}>
                                            <Image style={styles.staffImage} src={staff.files[mediaIdx]} />
                                        </View>
                                    )
                                })}
                            </View>
                            <View style={[styles.sectionUnWrappable,{paddingTop:0}]}>
                                <View style={{margin:0,paddingTop:0,paddingBottom:16}}>
                                    <Text style={styles.h2}>{capitalize(staff.firstName)} {capitalize(staff.lastName[0])}.</Text>
                                    <Text style={styles.h4}>{getAge(staff.birthday)} ans ({getGender(staff.gender)})</Text>
                                </View>
                                <View style={[styles.rowWithColumns,{paddingBottom:0}]}>
                                    <View style={[styles.blockNeutral,{paddingRight:'10px',width:400}]}>
                                        {staff.height && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('user.height')} : </Text>
                                                <Text >{staff.height ? staff.height+'cm' : '-'}</Text>
                                            </View>  
                                        }
                                        {staff.bodyUp && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>Jacket : </Text>
                                                <Text style={[styles.uppercase,{marginRight:16}]}>{staff.bodyUp}</Text>
                                                <Text style={styles.accent}>Trouser : </Text>                          
                                                <Text style={[styles.uppercase,{marginRight:16}]}>{staff.bodyDown}</Text>  
                                                <Text style={styles.accent}>{t('user.shoeSize')} : </Text>
                                                <Text style={styles.uppercase}>{staff.shoeSize}</Text>
                                            </View>  
                                        }                                                                                   
                                        {staff.languages  &&
                                            <View style={styles.inline}>
                                                <Text style={[styles.accent]}>Langue(s) : </Text>
                                                <View style={{marginLeft:'40px',width:400}}>
                                                    {staff.languages && [...new Set(staff.languages)].map((lang,index)=>{
                                                        return(
                                                            <View key={index.toString()} >
                                                                <Text style={{marginRight:'6px'}}>{getLanguage(lang.code)} - {getLanguageLevel(lang.level)}</Text>
                                                            </View>                                                            
                                                        )
                                                    })}  
                                                </View>                        
                                            </View> 
                                        }                                                                                    
                                    </View>  
                                    <View style={[styles.blockNeutral,{paddingRight:'10px',width:400}]}>

                                        {staff.job && 
                                            <View style={[styles.inline,{width:200}]}>
                                                <Text style={[styles.accent,{marginRight:16}]}>{t('user.job')} : </Text>
                                                <Text>{capitalize(staff.job)}</Text>
                                            </View>  
                                        }                                          
                                        {staff.diploma && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('user.diploma')} : </Text>
                                                <Text >{getDiplomas(staff.diploma)} {staff.ongoingStudies && staff.ongoingStudies === 1 ? '(Étude en cours)' : ''}</Text>
                                            </View>  
                                        }
                                        {staff.levelHospitality && 
                                            <View style={styles.inline}>
                                                <Text style={styles.accent}>{t('user.levelHospitality')} : </Text>
                                                <Text>{getLevelHospitality(staff.levelHospitality)}</Text>
                                            </View> 
                                        }         
                                        {staff.areaExpertises  &&
                                            <View style={styles.inline}>
                                                <Text style={[styles.accent]}>{t('user.areaExpertises')} : </Text>
                                                <View style={{marginLeft:'40px',width:400}}>
                                                    {staff.areaExpertises && [...new Set(staff.areaExpertises)].map((areaExpertise,index)=>
                                                        <Text key={index.toString()} style={{marginRight:'6px'}}>{capitalize(areaExpertise)}</Text>
                                                    )}
                                                </View>                        
                                            </View> 
                                        }                                     
                                    </View>                                            
                                </View> 
                                {((staff.pdfShowStudiesDescription && staff.studiesDescription) || (staff.pdfShowExpertiseDescription && staff.expertiseDescription)) && 
                                    <View>
                                        <View style={styles.inline}>
                                            <Text style={styles.accent}>Commentaires : </Text>                                                                                  
                                        </View>
                                        {staff.pdfShowStudiesDescription && staff.studiesDescription && 
                                            <View style={styles.inline}>                                        
                                                <Text style={styles.bodySmall}>{capitalize(staff.studiesDescription)}</Text>
                                            </View>  
                                        }
                                        {staff.pdfShowExpertiseDescription && staff.expertiseDescription && 
                                            <View style={styles.inline}>                                        
                                                <Text style={styles.bodySmall}>{capitalize(staff.expertiseDescription)}</Text>
                                            </View>  
                                        }                                       
                                    </View>
                                }
                            </View>
                            <FooterSection selectedTypes={selectedTypes} page={userPage} selectedUsers={selectedUsers} staffList={staffList}/>
                        </Page>            
                    </>
                );
            })}
        </>
    );
};    

const OperationPdf = ({selectedTypes,selectedUsers,selectedContacts,storedOperation, staffList, version}) => {
    let page = 1;
     console.log('OperationPdf selectedUsers',selectedUsers, selectedUsers.length);
    return (
        <Document>
            {selectedTypes && selectedTypes.includes('cover') &&
                <CoverPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    version={version}             
                />
            }
            {selectedTypes && selectedTypes.includes('info') &&
                <InfoPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}     
                    page={++page} 
                />
            }
            {selectedTypes && selectedTypes.includes('user') &&
                <UserPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedUsers={selectedUsers}
                    selectedTypes={selectedTypes}
                    version={version}     
                    page={page}
                />
            }  
            {selectedTypes && selectedTypes.includes('contact') &&
                <ContactPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    selectedContacts={selectedContacts}
                    version={version}      
                    page={(selectedUsers.length ? selectedUsers.length : 0)+(++page)}    
                />
            }   
            {selectedTypes && selectedTypes.includes('quote') &&
                <QuotePage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}   
                    page={(selectedUsers.length ? selectedUsers.length : 0)+(++page)}    
                />
            }  
            {selectedTypes && selectedTypes.includes('mission') &&
                <MissionPage
                    storedOperation={storedOperation} 
                    staffList={staffList} 
                    selectedTypes={selectedTypes}
                    selectedUsers={selectedUsers}
                    version={version}   
                    page={(selectedUsers.length ? selectedUsers.length : 0)+(++page)}    
                />
            }                                                       
        </Document>
      );
};
  
  Font.register({
    family: 'Poppins',
    fonts: [
      { src: PoppinsRegular },
      { src: PoppinsBold, fontWeight: 'bold' },
      { src: PoppinsItalic, fontStyle: 'italic' },
    ], 
});

const styles = StyleSheet.create({
    cover: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor:'#433F3A'
        
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Poppins', 
        fontSize: 12,    
        padding:16,
    },
    section: {
        margin: 16,
        // padding: 10,
        //   flexGrow: 1
    },
      pageUnWrappable: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        fontFamily: 'Poppins', 
        fontSize: 12,
      },      
     sectionUnWrappable: {
        margin: 16,
        padding: 16,
        //   flexGrow: 1
    },      

    logoContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    svg: {
        width: '50%', // Adjust as needed
        height: '50%', // Adjust as needed
    },  
    logo: {
        width: 300,
        height: 190,
        color:'#ff0000'
    },
    staffImage : {
        width: 300,
        height: 360,
        objectFit:"cover"
    },
    sectionImage : {
        // flexGrow: 1
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',        
    },
    smallSection:{
        fontSize:10,
    },
    inline : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        marginTop:3,
        marginBottom:3,
    },
    row : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
    },      
    rowWithColumns : {
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
    },   
    semibold : {
        fontWeight:400,
    },
    left: {
        textAlign:'left',
    },
    column : {
        width:'16%',  
        textAlign:'right',      
        border:'solid 1px black'
    },
    uppercase : {
        textTransform:"uppercase",
    },
    h2: {
        fontSize: 28,        
        color:'#C9B39B',
        fontWeight:600,
    },
    h3: {
        fontSize: 18,        
    },  
    h4: {
        fontSize: 14,   
        fontWeight:600,
    },      
    accent : {
        color:'#C9B39B',
    },
    bold : {
        fontWeight:600,
    },
    cartouche : {
        border: '0.5px solid #C9B39B',
        borderRadius:6,
        padding:6,
        backgroundColor:'#C9B39B',
    },
    body: {
        fontSize: 12,    
    }, 
    bodySmall: {
        fontSize: 11,  
        // fontStyle:'italic',
    },      
    block:{
        marginTop:32,
        marginBottom:64,
    },
    blockNeutral:{
        marginTop:0,
        marginBottom:0,
    }, 
    hr : { 
        width: '100%', 
        border: '0.3px solid #C9B39B',
        marginTop:6,
        marginBottom:6
    },
    big:{
        fontSize:33,
        display:'inline',
    },
    smallFont : {
        fontSize:9,
    },
    smallerFont : {
        fontSize:7,
    },    
    footer: {
        position: 'absolute',
        bottom: '16px', // 20px from the bottom
        left: '0',
        right: '0',
        textAlign: 'center',
        fontSize:8,
        color: '#808080',
    },      
});
  

export default OperationPdf;
