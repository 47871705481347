import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';

import { capitalize, prepareDates } from '../../../utils/miscHelpers'
import {MissionBmgStatus} from "../../../utils/constants"

import UserGeneral from "./UserGeneral"
import GanttBlock from "../../../components/elements/GanttBlock";
import GanttToolTip from "../../../components/elements/GanttToolTip";

const TimelineTab = ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 
        
    const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : {});
    
    const [tasks, setTasks] = useState([]);

    const doData = () =>{
        
        const defaultColor = '#433F3A'; // BMG BLACK secondary-color    

        let data = [];
        if (storedUser && storedUser.missions && storedUser.missions.length) {
            for (const mission of storedUser.missions) {                    
                if (mission.slots && mission.slots.length) {
                    for(const slot of mission.slots){
                        const date = prepareDates(slot);                            
                        const slotColor = MissionBmgStatus[slot.status] ? MissionBmgStatus[slot.status].color :  defaultColor;
                        
                        if (isNaN(date.startTime) || isNaN(date.endTime)) {
                            console.error('Invalid date format', date);
                        } else {  
                            data.push({
                                start: date.startTime,
                                end: date.endTime,
                                name: capitalize(mission._operation.name),
                                id: slot._missionSlot,
                                ref:mission._id,
                                body: <GanttToolTip staffName={capitalize(mission._operation.name)} missionSlot={slot}/>,
                                styles: { 
                                    backgroundSelectedColor: slotColor, 
                                    backgroundColor:slotColor, 
                                    progressColor: slotColor,
                                    progressSelectedColor: slotColor,
                                },                                
                            });
                        }
                    }
                }                
            }
        }

        return setTasks(data);        
    };

    useEffect(() => {
        doData();
    }, [storedUser]);

    return(
        <div className="tab-content">
            <UserGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className={'tab-block user-block '+ (openGeneral ? '' : 'folded')}>
                <GanttBlock 
                    section={section}
                    events={tasks}
                    // selectType={selectType}
                    // onSelectType={onSelectType}
                    displayTypeSelector={false}
                    />
            </div>
        </div>
    )
};

export default TimelineTab;