
import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/AuthProvider";
import { Spin, Input, Button } from 'antd';
import { getToken } from "../../services/Api"
import { useTranslation } from 'react-i18next';
import { notification,Divider } from 'antd';
import { Config } from '../../config'
import { ErrorMsg } from '../../utils/errorHelpers'

const LoginScreen = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type,msg) => {
    api[type]({
      duration: Config.notif.duration,
      message: msg,
    });
  };

  useEffect(() => {
    const token = getToken();
    if (token){
      navigate("/dashboard");
    }
  }); 

  const LoginEvent = (e) => {
    e.preventDefault();
    if (!isLoggingIn && input.email !== "" && input.password !== "") {
      setIsLoggingIn(true);
      auth.loginAction(input);
      const interval = setInterval(() => {
        setIsLoggingIn(false);
      }, 30000);
      return () => clearInterval(interval);
    }  else {
      if (!isLoggingIn) openNotification('error',ErrorMsg(t,["badfields"]));
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
      {contextHolder}
      <div className="login-container">
        <img alt="logo" src="/img/logo_no_tagline.svg" width={"66%"} height={'auto'} style={{marginBottom:'10px'}}/>
        <h2>Interface d'administration</h2>
        <Divider                   
          style={{padding:0,height: '1px' , marginTop:'-10px', marginBottom:'10px', minWidth: '80%',width: '80%', borderColor: 'var(--accent-color)'}}
        />        
        <div>
          <Input
            type="email"
            id="user-email"
            name="email"
            onChange={handleInput}
            placeholder={t('inputs.email')}
            className="text-input"
          />      
        </div>
        <div>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={handleInput}
            placeholder={t('inputs.password')}
            className="text-input"
          />
        </div>
        {isLoggingIn &&
          <div className="spinner" style={{marginTop:'6px'}}>
            <Spin/>
          </div>
        }
        {!isLoggingIn && 
          <Button className="save button" style={{marginTop:'1px'}}type="primary" onClick={LoginEvent}>{t('buttons.login')}</Button>
        }        
      </div>
     
    </div>
  );
};

export default LoginScreen;