import React, { useState,useEffect } from 'react';
import { Input,Select } from 'antd';
// import { useTranslation } from 'react-i18next';
import { CountriesFr } from "../../../utils/constants"
import { importBmgFilters, changeArrayToLabelValue } from "../../../utils/miscHelpers"
import { useNotification } from '../../../hooks/NotificationProvider';

import { SaveClient, EditClientProps } from '../../../services/Client';

const ClientForm = ({data, open, scale , setOpen, handlerMethod}) => {

    //const { t } = useTranslation();
    //const dispatch = useDispatch();
    const { openNotification } = useNotification(); 
    
    const contactFields = {
        firstName : '',
        lastName : '',
        email : '',
        phone : '',
    }

    const [BmgFilters, setBmgFilters] = useState(null); 
    const [name, setName] = useState(data && data.name ? data.name : '')
    const [email, setEmail] = useState(data && data.email ? data.email : '');
    const [activity, setActivity] = useState(data && data.activity ? data.activity : '');
    const [address, setAddress] = useState(data && data.address ? data.address : '');
    const [addressAlt, setAddressAlt] = useState(data && data.addressAlt ? data.addressAlt : '');
    const [zipcode, setZipcode] = useState(data && data.zipcode ? data.zipcode : '');
    const [city, setCity] = useState(data && data.city ? data.city : '');
    const [country, setCountry] = useState(data && data.country ? data.country : '');
    const [web, setWeb] = useState(data && data.web ? data.web : '');
    // const [siret, setSiret] = useState(data && data.siret ? data.siret : '');
    // const [compta, setCompta] = useState(data && data.compta ? data.compta : '');
    const [vat, setVat] = useState(data && data.vat ? data.vat : '');    
    const [contacts, setContacts] = useState(data && data.contacts ? data.contacts : [ contactFields ]);
    //const [vtaExo, setVtaExo] = useState(data && data.vta_exo ? data.vta_exo : '');

    const margin = '16px';

    const addContact = () =>{
        const newContacts = [...contacts];
        newContacts.push(contactFields);
        setContacts(newContacts);  
    };

    const deleteContact = (contact,index) =>{
        if (window.confirm('Êtes-vous sur de vouloir supprimer ce contact : '+contact.firstName+' '+contact.lastName+' ?')) {
            const newContacts = contacts.filter((ele,idx) => idx !== index);
            setContacts(newContacts);  
        }
    };

    const onCancel = ()=>{
        setOpen(false);
    };

    useEffect(() => {
        const fetchBmgFilters = async () => {      
            const bmgFiltersList = await importBmgFilters();
            setBmgFilters(bmgFiltersList);      
        };
        fetchBmgFilters(); 
    }, []);

    useEffect(() => {
        if (open !== undefined) resetSets();
    }, [open]);

    const resetSets = ()=>{        
        setName('');
        setEmail('');
        setActivity('');
        setAddress('');
        setAddressAlt('');        
        setZipcode('');
        setCity('');
        setCountry('');
        setWeb('');
        // setSiret('');
        // setCompta('');
        setVat('');
        setContacts([contactFields]);
    };
    
    const onSubmit = async ()=>{

        if (!name || name === '') {
            openNotification('error',"Le champs Nom est obligatoire");     
            return;
        }
        
        const props = {
            name,
            email,
            activity,
            address,
            addressAlt,
            zipcode,
            city,
            country,
            web,
            // siret,
            // compta,
            vat,
            contacts
        };

        let entryUpdated = null;
        if (data && data._id) {
            entryUpdated = await EditClientProps(data._id, props);
        } else {
            entryUpdated = await SaveClient(props);
        }
        
        if (entryUpdated && entryUpdated._id) {
            openNotification('success',"Information Sauvegardée");     
            
            // Return here
            handlerMethod(entryUpdated);
            
            setOpen(false);

            // Clean here
            resetSets();

        } else {
            openNotification('error',"Un problème est survenu lors de la sauvegarde");     
        }

    };

    const onChangeInput = (e,field,key,idx) =>{
        const value = e?.target?.value;   
        switch(field){
            case 'contact' : 
                let newContacts = contacts.map(ele => ({...ele }));
                newContacts[idx][key] = value;   
                setContacts(newContacts);  
            break;            
            case 'setName' : 
                setName(value);
            break;
            case 'setAddress' : 
                setAddress(value);
            break;
            case 'setAddressAlt' : 
                setAddressAlt(value);
            break;                        
            case 'setZipcode' : 
                setZipcode(value);
            break;
            case 'setCity' : 
                setCity(value);
            break;
            case 'setCountry' : 
                setCountry(value);
            break;
            case 'setEmail' : 
                setEmail(value);
            break;    
            case 'setWeb' : 
                setWeb(value);
            break;  
            // case 'setCompta' : 
            //     setCompta(value);
            // break;  
            // case 'setSiret' : 
            //     setSiret(value);
            // break;  
            case 'setVat' : 
                setVat(value);
            break;                                                    
            default:
        }
    };

    return (
        <div style={{width:scale === 'light' ? '400px' : '100%'}}>
            {(!data || !data._id) && 
                <span className='h2' style={{marginBottom:'16px'}}>Nouveau Client</span>            
            }
            
            {/* <div className={scale === 'light' || scale === 'full'? '' : 'tab-form'} style={{}}> */}
            <div >
                <div className='tab-form-block'>
                    <div className="form-label-name">
                        <span className="label">Nom du client</span>
                        <Input 
                            title="Nom du client"
                            value={name}
                            id="name"
                            name="name"
                            placeholder="Nom du client" 
                            className=""
                            onChange={(e)=>onChangeInput(e,'setName')}
                        />
                    </div>                    
                </div>
                <div className='tab-form-block'>
                    <div className='tab-form-block-column'>
                        <div className="form-label-name">
                            <span className="label">Email général</span>
                            <Input 
                                title="Email général"
                                value={email}
                                id="email"
                                name="email"
                                placeholder="Email général" 
                                className=""
                                onChange={(e)=>onChangeInput(e,'setEmail')}
                            />
                        </div>
                    </div>
                    <div className='tab-form-block-column'>
                        <div className="form-label-name">
                            <span className="label">Activité</span>
                            <Select
                                style={{
                                width: '100%',
                                }}
                                optionLabelProp="label"
                                optionFilterProp="label"
                                placeholder="Activité"
                                defaultValue={[]}
                                value={activity}
                                showSearch
                                onChange={setActivity}
                                options={BmgFilters && BmgFilters.client ? BmgFilters.client.activity : []}
                                className="filter-select"
                            />  
                        </div>                    
                    </div>
                </div>                  
                <div className='tab-form-block'>
                    <div className='tab-form-block-column'>
                        <div className="form-label-name">
                            <span className="label">Adresse</span>
                            <Input 
                                title="Adresse"                                            
                                value={address}
                                id="address"
                                name="address"
                                placeholder="Adresse" 
                                className=""
                                onChange={(e)=>onChangeInput(e,'setAddress')}
                            />
                        </div>
                    </div>
                    <div className='tab-form-block-column'>
                        <div className="form-label-name">
                            <span className="label">Adresse complémentaire</span>
                            <Input 
                                title="Adresse complémentaire"                                            
                                value={addressAlt}
                                id="addrtessAlt"
                                name="addrtessAlt"
                                placeholder="Adresse complémentaire" 
                                className=""
                                onChange={(e)=>onChangeInput(e,'setAddressAlt')}
                            />
                        </div>                    
                    </div>
                </div>
                <div className='tab-form-block'>
                    <div className='tab-form-block-column'>                        
                        <div className="form-label-name">
                            <span className="label">Code Postal</span>
                            <Input 
                                title="Code Postal"                                            
                                value={zipcode}
                                id="zipcode"
                                name="zipcode"
                                placeholder="Code Postal" 
                                className=""
                                onChange={(e)=>onChangeInput(e,'setZipcode')}
                            />
                        </div>  
                    
                    </div>
                    <div className='tab-form-block-column'>
                        <div className="form-label-name">
                            <span className="label">Ville</span>
                            <Input 
                                title="Ville"                                            
                                value={city}
                                id="city"
                                name="city"
                                placeholder="Ville" 
                                className=""
                                onChange={(e)=>onChangeInput(e,'setCity')}
                            />
                        </div>
                    </div>                                      
                </div>
                {scale === 'full' && 
                    <>
                        <div className='tab-form-block'>
                            <div className='tab-form-block-column'>
                                <div className="form-label-name">
                                    <span className="label">Pays</span>
                                    <Select
                                        allowSearch
                                        style={{
                                            width: '100%',                                    
                                        }}
                                        optionLabelProp="label"
                                        optionFilterProp="label"
                                        placeholder="Pays"
                                        defaultValue={[]}
                                        value={country}
                                        showSearch
                                        onChange={setCountry}
                                        options={changeArrayToLabelValue(CountriesFr)}
                                        className="filter-select"
                                    />
                                </div>
                            </div>
                            <div className='tab-form-block-column'>
                                
                            </div>                                      
                        </div>
                        <div className='tab-form-block'>
                            <div className='tab-form-block-column'>                        
                            <div className="form-label-name">
                                    <span className="label">Site Internet</span>
                                    <Input 
                                        title="Site Internet"                                            
                                        value={web}
                                        id="web"
                                        name="web"
                                        placeholder="Site Internet" 
                                        className=""
                                        onChange={(e)=>onChangeInput(e,'setWeb')}
                                    />
                                </div>                            
                            </div>
                            <div className='tab-form-block-column'>
                                <div className="form-label-name">
                                    <span className="label">Numéro TVA</span>
                                    <Input 
                                        title="Numéro TVA"                                            
                                        value={vat}
                                        id="vat"
                                        name="vat"
                                        placeholder="Numéro TVA" 
                                        className=""
                                        onChange={(e)=>onChangeInput(e,'setVat')}
                                    />
                                </div>
                            </div>                                      
                        </div>                        
                    </>
                }
                <div className='h2' style={{marginTop:margin}}>Contact Client</div>
                {contacts && contacts.map((contact,index)=>
                    <div style={{marginBottom:'16px',borderTop: index ? 'solid 1px var(--accent-color)' : 'none'}}>
                        <div className='flex' style={{fontSize:'11px',paddingTop:'16px',justifyContent: 'space-between',alignItems: 'center'}}>
                            <span>Contact {index+1}</span>
                            {index > 0 &&
                                <div className='link' onClick={()=>deleteContact(contact,index)}>Effacer ce contact</div>
                            }
                        </div>
                        <div key={index.toString() } className='tab-form-block'>
                            <div className='tab-form-block-column'>                        
                                <div className="form-label-name">
                                    <span className="label">Prénom</span>
                                    <Input 
                                        title="Prénom"                                            
                                        value={contact.firstName}
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Prénom" 
                                        className=""
                                        onChange={(e)=>onChangeInput(e,'contact','firstName',index)}
                                    />
                                </div>
                            
                            </div>
                            <div className='tab-form-block-column'>
                                <div className="form-label-name">
                                    <span className="label">Nom</span>
                                    <Input 
                                        title="Nom"                                            
                                        value={contact.lastName}
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Nom" 
                                        className=""
                                        onChange={(e)=>onChangeInput(e,'contact','lastName',index)}
                                    />
                                </div>
                            </div>                                      
                        </div>
                        <div className='tab-form-block'>
                            <div className='tab-form-block-column'>                        
                                <div className="form-label-name">
                                    <span className="label">Email</span>
                                    <Input 
                                        title="Email"                                            
                                        value={contact.email}
                                        id="email"
                                        name="email"
                                        placeholder="Email" 
                                        className=""
                                        onChange={(e)=>onChangeInput(e,'contact','email',index)}
                                    />
                                </div>
                            
                            </div>
                            <div className='tab-form-block-column'>
                                <div className="form-label-name">
                                    <span className="label">Téléphone</span>
                                    <Input 
                                        title="Téléphone"                                            
                                        value={contact.phone}
                                        id="phone"
                                        name="phone"
                                        placeholder="Téléphone" 
                                        className=""
                                        onChange={(e)=>onChangeInput(e,'contact','phone',index)}
                                    />
                                </div>
                            </div>                                      
                        </div>
                    </div>        
                )}
            </div>
            <div style={{textAlign:'right',fontSize:'11px'}} className='link' onClick={addContact}>+ Ajouter un contact</div>
            <div style={{display:'flex',marginTop:margin,justifyContent: "space-between"}}>
                <div onClick={onCancel} className='button button-tiny button-secondary'>Annuler</div>
                <div onClick={()=>{onSubmit()}} className='button button-tiny button-with-accent'>Sauvegarder</div>
            </div>    
        </div>
    );
};
export default ClientForm;