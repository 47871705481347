import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUser: (state) => {
      return state.value 
    },
    saveUser: (state, action) => {
      state.value = action.payload;
    },
    resetUser: (state) => {
      state.value = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { getUser, saveUser, resetUser } = userSlice.actions

export default userSlice.reducer