import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
};

export const operationSlice = createSlice({
  name: 'operation',
  initialState: initialState,
  reducers: {
    getOperation: (state) => {
      return state.value 
    },
    saveOperation: (state, action) => {
      state.value = action.payload;
    },
    resetOperation: (state) => {
      state.value = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { getOperation, saveOperation, resetOperation } = operationSlice.actions

export default operationSlice.reducer