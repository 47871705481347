import axios from 'axios';

// Api Routes to Backend

export const ApiRoutes = {
    login: '/auth/admin/login',
    signup: '/auth/admin/signup',
    code: '/auth/admin/check/code',
    complete: '/auth/admin/signup/complete',
    forgot: '/auth/admin/forgot',
    change: '/auth/admin/change/password',
    constants: '/admin/misc/constants',

    userMe: '/admin/me',
    userList : '/admin/user/list',
    userGet : '/admin/user/get',
    userEdit : '/admin/user/edit',

    missionList : '/admin/operation/list',
    missionEdit : '/admin/operation/edit',

    opList : '/admin/operation/list',
    opEdit : '/admin/operation/edit', 
    opAdd : '/admin/operation/add',
    opDelete: '/admin/operation/delete',
    opClone:'/admin/operation/clone',
    opGet : '/admin/operation/get',
    opCount : '/admin/operation/count',

    missionDemand : '/admin/mission/demand',
    
    clientList : '/admin/client/list',
    clientEdit : '/admin/client/edit',
    clientAdd : '/admin/client/add',
    clientMissionQuote : '/admin/client/mission/quote',

    messageList : '/admin/message/list',
    messageSend : '/admin/message/send',
    messageGet : '/admin/message/get',
    messageDel : '/admin/message/delete',

    mediaFile : '/admin/file/get',
    addFile : '/admin/file/import',
    deleteFile : '/admin/file/delete',

    dashboardMetrics: '/admin/dashboard/metrics',
    dashboardMsg: '/admin/dashboard/messages',
    dashboardUsers: '/admin/dashboard/users',
    dashboardMissions: '/admin/dashboard/missions',
    dashboardTodo: '/admin/dashboard/todos',

    notificationList : '/admin/notification/list',
    notificationView : '/admin/notification/view',

    jobofferList : '/admin/joboffer/list',
    jobofferEdit : '/admin/joboffer/edit',
    jobofferAdd : '/admin/joboffer/add',
    jobofferGet : '/admin/joboffer/get',
    jobofferDel : '/admin/joboffer/delete',

    todoList : '/admin/todo/list',
    todoEdit : '/admin/todo/edit',
    todoAdd : '/admin/todo/add',
    todoGet : '/admin/todo/get',
    todoDel : '/admin/todo/delete',

    labelList : '/admin/label/list',
    labelEdit : '/admin/label/edit',
    labelAdd : '/admin/label/add',
    labelAdds : '/admin/label/adds',    
    labelGet : '/admin/label/get',
    labelDel : '/admin/label/delete',    

    adminAdd : '/admin/admin/add',
    adminList : '/admin/admin/list',
    adminEdit : '/admin/admin/edit',
    adminDel : '/admin/admin/delete',

    agendaList : '/admin/agenda/list',

}; 

// Create Client

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    'Content-Type': 'application/json', 
    'Accept-Language' : process.env.REACT_APP_DEFAULT_LANG,
  },   
});    

// Set token

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(process.env.REACT_APP_AUTH_TOKEN_NAME, token);
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};
 
// get token

export const getToken = () => {
  try {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME);
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return token;
  } catch(e) {
    // read error
  }
};

// Remove token

export const removeToken = () => {
  localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN_NAME);
  localStorage.removeItem(process.env.REACT_APP_AUTH_ADMIN_NAME);
  delete apiClient.defaults.headers.common['Authorization'];
};

// Init token

export const loadToken = () => {
  const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME);
  if (token) {
    setToken(token);
  }
};

// Set token

export const setAdmin = (payLoad) => {
  if (payLoad) {
    localStorage.setItem(process.env.REACT_APP_AUTH_ADMIN_NAME, JSON.stringify(payLoad));
  }
};
 
// get token

export const getAdmin = () => {
  try {
    const payLoad = localStorage.getItem(process.env.REACT_APP_AUTH_ADMIN_NAME);
    return JSON.parse(payLoad);
  } catch(e) {
    // read error
  }
};

// GET Method

export const Get = async (url, options = {}) => {
  try {
    const response = await apiClient.get(url, options);
    return response.data;
  } catch (error) {
    //console.log(error);
    throw error;   
  }
};

export const GetFile = async (url, options = {}) => {
  try {
    const response = await apiClient.get(url, { ...options, responseType: 'arraybuffer' });
    const base64 = btoa(
      new Uint8Array(response.data)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:${response.headers['content-type']};base64,${base64}`;
  } catch (error) {
    //console.log(error);
    throw error;   
  }
};

// DOWNLOAD OCTET STREAM - Use of Fetch not axios for header simplicty

export const DownloadFile = async (filePath) => {
  try {

    const token = localStorage.getItem(process.env.REACT_APP_AUTH_TOKEN_NAME);    
    const response = await fetch(process.env.REACT_APP_PUBLIC_API_URL + filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
        'Authorization':  `Bearer ${token}`,       
      },
    });
    return {
      binary : await response.blob(),
      contentType : response.headers.get('Content-Type')
    };
  } catch (error) {
    console.error('Error downloading file:', error);
  }
};

// POST Method

export const Post = async (url, data, options = {}) => {
  try {
    const token = getToken();    
    if (options.headers) {
      apiClient.defaults.headers = options.headers;
    } else {
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`; 
      apiClient.defaults.headers.common['Content-Type'] = "application/json";
      apiClient.defaults.headers['Content-Type'] = "application/json";    
    }
    const response = await apiClient.post(url, data, options);
    return response.data;
  } catch (error) {
    console.error('API POST Error',error);
    removeToken();
    alert('Erreur de connexion avec le Serveur backend. Recharger cette page.');
    //throw error;    
  }
};

// Upload file

export const Upload = async (url, data, options = {}) => {
  try {    
    const token = getToken();
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`; 
    apiClient.defaults.headers.common['Content-Type'] = "multipart/form-data";
    apiClient.defaults.headers['Content-Type'] = "multipart/form-data";    
    options.headers = apiClient.defaults.headers;
    const response =  await Post(url, data, options);
    if (!response.success) {
      return {error: ["badresponse"]};
    }
    return response;
  } catch (error) {
    //throw error;
    return {error: ["badresponse"]};
  }
};