import React, { useState,useEffect } from 'react';
import { Input } from 'antd';
import { useNotification } from '../../../hooks/NotificationProvider';


const RoleForm = ({data, open, setOpen, handlerMethod}) => {

    const { openNotification } = useNotification();

    const [name,setName] = useState(data.name);
    const [price,setPrice] = useState(data.price);
    const [cost,setCost] = useState(data.cost);
   
    const margin = '8px';

    useEffect(() => {
        const fetchData = async () => {
            try {    
                setName(data.name);
                setPrice(data.price);
                setCost(data.cost);

              return;
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          fetchData();        
    }, [data]);   
    
    const onCancel = ()=>{
        resetSets();
        setOpen(false);
    };

    const resetSets = ()=>{        
        setName(data.name);
        setPrice(data.price);
        setCost(data.cost);
    };
    
    const onSubmit = async ()=>{

        if (!name || name === '') {
            openNotification('error',"Le champs Rôle  est obligatoire");     
            return;
        }
        
        if (!price || !price || !cost || !cost) {
            openNotification('error',"Les Prix sontobligatoires");     
            return;
        }

        const props = {      
            ...data,  
            ...{
                name,
                price,
                cost,
            },
        };
        
        // Return here
        handlerMethod(props);
        
        setOpen(false);

        // Clean here
        resetSets();

    };

    const changeEntry = (e,field) => {
        switch(field){
            case 'name' :
                setName(e.target.value);
                break;
            case 'price' :
                setPrice(e.target.value);
                break;
            case 'cost' :
                setCost(e.target.value);
                break;                                    
            default:
        }  
    };
    
    return (
        <div >            
            <div className='h2' style={{marginBottom:'16px'}}>Rôle</div>            
            <div style={{width:'250px'}}>
                <div >
                    <span className="label">Intitulé du Rôle</span>   
                    <Input 
                        title="Rôle"                                            
                        value={name}
                        id="name"
                        name="name"
                        placeholder="Rôle" 
                        className=""
                        onChange={(e)=>changeEntry(e,'name')}
                    />
                </div>
                <div style={{margin:'10px 0',display:"flex",flexDirection:"row"}}>
                    <div style={{marginRight:'16px'}}>
                        <span className="label">Tarif Hôte.sse</span>   
                        <Input                                    
                            title="Tarif Hôte"                                            
                            value={price}
                            id="price"
                            name="price"
                            placeholder="Tarif Hôte" 
                            className=""
                            onChange={(e)=>changeEntry(e,'price')}
                        />
                    </div>
                    <div style={{marginLeft:'16px'}}>
                        <span className="label">Tarif Client</span>   
                        <Input                               
                            title="Tarif Client"                                            
                            value={cost}
                            id="cost"
                            name="cost"
                            placeholder="Tarif Client" 
                            className=""
                            onChange={(e)=>changeEntry(e,'cost')}
                        />     
                    </div>                                         
                </div> 
           </div>
            <div style={{display:'flex',margin:margin,marginTop:'16px',justifyContent: "space-between"}}> 
                <div onClick={onCancel} className='button button-tiny button-secondary'>Annuler</div>
                <div onClick={()=>{onSubmit()}} className='button button-tiny button-with-accent'>Sauvegarder</div>
            </div>    
        </div>
    );
};
export default RoleForm;