import { createSlice } from '@reduxjs/toolkit'

const initialState = {};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    getNotification: (state) => {
      return state.value 
    },
    saveNotification: (state, action) => {
      state.value = action.payload;
    },
    resetNotification: (state) => {
      state.value = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { getNotification, saveNotification, resetNotification } = notificationSlice.actions

export default notificationSlice.reducer