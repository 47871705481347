import React  from 'react';
import dayjs from 'dayjs';
import { QuoteTotals, doS,FormatTotal } from '../../../utils/miscHelpers'

const QuoteDetail =  ({
        name,
        section, 
        missionSlots,
        quote,
        quoteVat,
        quoteCurrency,
        quoteHeader,
        quoteFooter,
        quoteFees,
        quoteExpire,
    }) => {

    const nlToBr = (text) => {
        if (!text || text === '') return '';
        return text.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ));
    };

    const quoteTotals = QuoteTotals(missionSlots,quoteFees,quoteVat);

    return(
        
        <div className='quote-sheet'>
            <div className='quote-sheet-row-label'><span className='h2'>Réf. Devis :</span> {quote}</div>
            <div className='quote-sheet-row-label'><span className='h2'>Expire le  :</span> {dayjs(quoteExpire).format('DD/MM/YYYY')}</div>
            <div className='quote-sheet-row'>{nlToBr(quoteHeader)}</div>                        
            {(quoteTotals.locations && Object.keys(quoteTotals.locations).length > 0) &&
                quoteTotals.locations.map((location,index)=>{
                    return(
                        <div key={'location-'+index.toString()} className='quote-sheet-row'>
                            <div style={{width:'100%'}}>
                                <div className='quote-sheet-row-label'>
                                    <span className='semibold' style={{marginRight:'6px'}}>{name} / </span>                       
                                    <span className='capitalize h2'>{location.location?.name}, {location.location?.address}, {location.location?.city}</span>
                                </div>
                                {(location.roles && location.roles.length > 0) &&
                                    location.roles.map((role,idx)=>{
                                        return(
                                            <div key={'role-'+idx} >
                                                <div  style={{ display: "flex",alignItems: "center",justifyContent: "space-between"}}>
                                                    <div className='capitalize flex' style={{width:'100px'}}>{role.name}</div>
                                                    <div style={{width:'12%',textAlign:'right'}}>{role.employees} Poste{doS(role.employees,'number')}</div>
                                                    <div style={{width:'12%',textAlign:'right'}}>{role.edays.length} Jrs</div>
                                                    <div style={{width:'12%',textAlign:'right'}} className='uppercase'>{FormatTotal(role.cost,quoteCurrency)}/<span className='uncapitalize'>hr</span></div>
                                                    <div style={{width:'12%',textAlign:'right'}}>{role.totalNormalHrs} Hrs</div>
                                                    <div style={{width:'16%',textAlign:'right'}} className='uppercase'>{FormatTotal(role.totalNormal,quoteCurrency)}</div>
                                                </div>
                                                {role.totalOverTimeHrs !== 0 &&
                                                    <div style={{ display: "flex",alignItems: "center",justifyContent: "space-between"}}>
                                                        <div className='capitalize flex' style={{width:'100px',paddingLeft:'16px'}}>Overtime</div>
                                                        <div style={{width:'12%',opacity:0}}>{role.workForce}  Poste{doS(role.workForce,'number')}</div>
                                                        <div style={{width:'12%',opacity:0}}>{role.edays.length} Jrs</div>
                                                        <div style={{width:'12%',textAlign:'right'}} className='uppercase'>{FormatTotal((role.cost * quoteTotals.extra),quoteCurrency)}/<span className='uncapitalize'>hr</span></div>
                                                        <div style={{width:'12%',textAlign:'right'}}>{role.totalOverTimeHrs} Hrs</div>
                                                        <div style={{width:'16%',textAlign:'right'}} className='uppercase'>{FormatTotal(role.totalOverTime,quoteCurrency)}</div>
                                                    </div>  
                                                }      
                                                {role.isHoliDay &&
                                                    <div style={{ display: "flex",alignItems: "center",justifyContent: "space-between"}}>
                                                        <div className='capitalize flex' style={{width:'100px',paddingLeft:'16px'}}>Férié</div>
                                                        <div style={{width:'12%',opacity:0}}>{role.workForce}  Poste{doS(role.workForce,'number')}</div>
                                                        <div style={{width:'12%',opacity:0}}>{role.edays.length} Jrs</div>
                                                        <div style={{width:'12%',textAlign:'right'}} className='uppercase'>{FormatTotal((role.cost * quoteTotals.extra),quoteCurrency)}/<span className='uncapitalize'>hr</span></div>
                                                        <div style={{width:'12%',textAlign:'right'}}>{role.totalHolidayHrs} Hrs</div>
                                                        <div style={{width:'16%',textAlign:'right'}} className='uppercase'>{FormatTotal(role.totalHoliday,quoteCurrency)}</div>
                                                    </div>  
                                                }                                                                   
                                            </div>
                                        )
                                    })
                                }   
                            </div>
                        </div>
                    )
                })
            }                                            
            <div className='quote-sheet-row' style={{padding:'6px 0',borderTop:'solid 1px var(--accent-thin-color)',borderBottom:'solid 1px var(--accent-thin-color)'}}>
                <div>Total Missions</div>
                <div className='uppercase'>{FormatTotal(quoteTotals.missions,quoteCurrency)}</div>
            </div>                        
            {(quoteFees && quoteFees.length !== 0) &&
                quoteFees.map((quoteFee,index)=>{
                    return(
                        <div key={'fee-'+index.toString()} className='quote-sheet-row'>
                            <div>{quoteFee.name}</div>
                            <div className='uppercase'>{FormatTotal(quoteFee.price,quoteCurrency)}</div>
                        </div>
                    )
                })
            }
            {(quoteFees && quoteFees.length !== 0) &&                     
                <div className='quote-sheet-row' style={{padding:'6px 0',borderTop:'solid 1px var(--accent-thin-color)',borderBottom:'solid 1px var(--accent-thin-color)'}}>
                    <div>Total Extra</div>
                    <div className='uppercase'>{FormatTotal(quoteTotals.fees,quoteCurrency)}</div>
                </div> 
            } 
            <div className='quote-sheet-row bold'>
                <div>Total Net</div>
                <div className='uppercase'>{FormatTotal(quoteTotals.net,quoteCurrency)}</div>
            </div>        
            <div className='quote-sheet-row'>
                <div>TVA ({quoteVat}%)</div>
                <div className='uppercase'>{FormatTotal(quoteTotals.vat,quoteCurrency)}</div>
            </div>  
            <div className='quote-sheet-row bold' style={{padding:'6px 0',borderBottom:'solid 1px var(--accent-thin-color)'}}>
                <div>Total</div>
                <div className='uppercase'>{FormatTotal(quoteTotals.full,quoteCurrency)}</div>
            </div>                                                                    
            <div className='quote-sheet-row'>{nlToBr(quoteFooter)}</div>
        </div>       

    )
};

export default QuoteDetail;