import { Post, ApiRoutes } from "./Api"

export const EditSectionProps = async (section, itemId,props) =>{
    try {

        let apiRoute = '';
        switch(section){
            case 'user':
                apiRoute = ApiRoutes.userEdit;
                break;
            case 'joboffer':
                apiRoute = ApiRoutes.jobofferEdit;
                break;    
            case 'client':
                apiRoute = ApiRoutes.clientEdit;
                break;
            case 'mission':
                apiRoute = ApiRoutes.missionEdit;
                break;   
            case 'operation':
                apiRoute = ApiRoutes.opEdit;
                break;                   
            case 'todo':
                apiRoute = ApiRoutes.todoEdit;
                break;                                                   
            default:
        }

        const res = await Post(apiRoute, { id: itemId, ...props});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error MISC EditProps');
        }        
    } catch (err) {
      console.error('MISC EditProps Err',err);
    }
};
