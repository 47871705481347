import React, { useState} from 'react';
import {  Input,} from 'antd';
import { useNotification } from '../../../hooks/NotificationProvider';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { saveClient } from '../../../features/client/clientSlice';
import ClientGeneral from "./ClientGeneral"
import {EditClientProps} from '../../../services/Client'
import FileUpload from "../../elements/FileUpload"

const { TextArea } = Input;

//const section = 'client';

const PresentationTab =  ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 

    const dispatch = useDispatch();
    const { openNotification } = useNotification();
    const { t } = useTranslation();    
    const storedClient = useSelector(state => state.client.value && state.client.value ? state.client.value  : {});    
    const [notes, setNotes] = useState(storedClient.notes);

    const onChangeNotes = (e) => {       
        setNotes(e.target.value);        
    };

    const saveNotes = async () => {  
        if (notes !== undefined) {
            const entrytUpdated = await EditClientProps(storedClient, { notes : notes});
            if (entrytUpdated && entrytUpdated._id) {                
                dispatch(saveClient(entrytUpdated));
                openNotification('success','Modification Enregistrée');
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
            }
        }
    };

    return(
        <div className="tab-content">
            <ClientGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className='tab-block'>
                <div className='presentation-block' >
                    <div className='presentation-block-inner-first' style={{paddingTop: "12px"}}>
                        <FileUpload
                            section={section}
                            record={storedClient}
                        />
                    </div>
                    <div className='presentation-block-inner'>
                        <div className="note-header h2">
                            <span className="h2 capitalize">{t('user.notes')}</span>
                            <span onClick={saveNotes} className="icon-save link" title="Enregistrer"><CheckCircleOutlined/></span>
                        </div>                         
                        <TextArea 
                            value={notes}
                            onChange={onChangeNotes}
                            className="bmg-textarea" 
                            rows={16} 
                            placeholder={t('user.notes')} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PresentationTab;