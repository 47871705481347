import React from 'react';
import dayjs from 'dayjs';

const GanttToolTip = ({ staffName,missionSlot,duration,dates,annexe=null }) => {
    return (
      <div>
        <div className='bold'>{staffName}</div>        
        {annexe && 
            <div>{annexe}</div>
        }        
        {missionSlot && 
            <div>{missionSlot.role}</div>
        }
        {missionSlot && 
            <div>{dayjs(missionSlot.eday).format('DD MMMM YYYY')}</div>
        }
        {missionSlot && 
            <div>De {missionSlot.stime} à {missionSlot.etime}</div>
        }
        {duration &&
            <div>{duration}</div>
        }
        {dates && 
            <div>Du {dayjs(dates.sdate).format('DD MMM')} au {dayjs(dates.edate).format('DD MMM YYYY')}</div>
        }
      </div>
    );
};

export default GanttToolTip;