import { Post, ApiRoutes } from "./Api"

export const ListMessages = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.messageList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('Message resultList',err);
    }
};

export const SendMessage = async (data) => {
    try {
        const res = await Post(ApiRoutes.messageSend, data);
        if (res) return res.value;        
    } catch (err) {
      console.error('Message resultList',err);
    }
};
