import React,{useEffect} from 'react';
import { BrowserRouter as Router, Routes,  Route } from 'react-router-dom';

import './assets/styles/App.css';

import LoginScreen from "./screens/auth/LoginScreen";
import DashboardScreen from "./screens/secured/DashboardScreen";
import AgendaList from "./screens/secured/agenda/AgendaList";
import ToDoScreen from "./screens/secured/todo/ToDoList";
import UserList from "./screens/secured/user/UserList";
import UserDetail from "./screens/secured/user/UserDetail";
import UserForm from "./screens/secured/user/UserForm";
import ClientList from "./screens/secured/client/ClientList";
import ClientDetail from "./screens/secured/client/ClientDetail";
import ClientForm from "./screens/secured/client/ClientForm";
import OpList from "./screens/secured/operation/OpList";
import OpDetail from "./screens/secured/operation/OpDetail";
import OpForm from "./screens/secured/operation/OpForm";


import JobOfferList from "./screens/secured/joboffer/JobOfferList";
import JobOfferForm from "./screens/secured/joboffer/JobOfferForm";
import MessageList from "./screens/secured/message/MessageList";
import SettingList from "./screens/secured/setting/SettingList";
import LabelList from "./screens/secured/setting/LabelList";
import AdminList from "./screens/secured/setting/AdminList";
import AdminForm from "./screens/secured/setting/AdminForm"

import AuthProvider from "./hooks/AuthProvider";
import { NotificationProvider } from "./hooks/NotificationProvider";

import PrivateRoute from "./router/route";

function App() {
  return (
    <div className="App">
       <Router>
       <NotificationProvider>        
          <AuthProvider>
            <Routes>
              <Route path="/" element={<LoginScreen />} />
              <Route element={<PrivateRoute />}>
                  <Route path="/dashboard" element={<DashboardScreen />} />               
                  <Route path="/agenda" element={<AgendaList />} />
                  <Route path="/todo" element={<ToDoScreen />} />
                  <Route path="/user" element={<UserList />} />
                  <Route path="/user/detail" element={<UserDetail />} />
                  <Route path="/user/form" element={<UserForm />} />                  
                  <Route path="/client" element={<ClientList />} />
                  <Route path="/client/detail" element={<ClientDetail />} />
                  <Route path="/client/form" element={<ClientForm />} />                  
                  <Route path="/operation" element={<OpList />} />
                  <Route path="/operation/detail" element={<OpDetail />} />
                  <Route path="/operation/form" element={<OpForm />} />                  
                  <Route path="/joboffer" element={<JobOfferList />} />
                  <Route path="/joboffer/form" element={<JobOfferForm />} />
                  <Route path="/message" element={<MessageList />} />
                  <Route path="/setting" element={<SettingList />} />
                  <Route path="/setting/label" element={<LabelList />} />
                  <Route path="/setting/admin" element={<AdminList />} />  
                  <Route path="/setting/admin/form" element={<AdminForm />} />  
              </Route>
            </Routes>
          </AuthProvider>
        </NotificationProvider>        
      </Router>
    </div> 
  );
}

export default App;
