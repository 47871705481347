import { Post, ApiRoutes } from "./Api"

export const ListToDos = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.todoList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('ToDo resultList',err);
    }
};

export const SaveToDo = async (data) => {
    try {
        const res = await Post(ApiRoutes.todoAdd, data);
        if (res) return res.value;        
    } catch (err) {
      console.error('ToDo resultList',err);
    }
};

export const EditToDoProps = async (itemId,props) =>{
    try {
        const res = await Post(ApiRoutes.todoEdit, { id: itemId, ...props});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error EditToDoProps');
        }        
    } catch (err) {
      console.error('EditToDoProps Err',err);
    }
};

export const GetToDo = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.todoGet, { id: itemId});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error DeleteToDo');
        }        
    } catch (err) {
      console.error('DeleteToDo Err',err);
    }
};

export const DeleteToDo = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.todoDel, { id: itemId});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error DeleteToDo');
        }        
    } catch (err) {
      console.error('DeleteToDo Err',err);
    }
};

