import React,{ useEffect, useState} from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Input } from 'antd';


import { saveLists } from '../../../features/lists/listsSlice';
import { ListLabels, SaveLabels } from '../../../services/Label';
import { useNotification } from '../../../hooks/NotificationProvider';
import PopOver from "../../../components/elements/PopOver"
import HeaderBar from "../../../components/layout/HeaderBar";

const section = 'label';

const LabelList = () => {

  const colorValues = [
    {
      idx:0,
      label:'-',
      color:'var(--secondary-color)',
    },
    {
      idx:1,
      label:'-',
      color:'var(--accent-color)',
    },
    {
      idx:2,
      label:'-',
      color:'var(--green-color)',
    },
    {
      idx:3,
      label:'-',
      color:'var(--orange-color)',
    },
    {
      idx:4,
      label:'-',
      color:'var(--red-color)',
    },    
    {
      idx:5,
      label:'-',
      color:'var(--label-1)',
    },       
    {
      idx:6,
      label:'-',
      color:'var(--label-2)',
    },    
    {
      idx:7,
      label:'-',
      color:'var(--label-3)',
    },    
    {
      idx:8,
      label:'-',
      color:'var(--label-4)',
    },    
    {
      idx:9,
      label:'-',
      color:'var(--label-5)',
    },
  ];
  
  const { openNotification } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [list, setList] = useState(storedList);

  useEffect(() => {

    const fetchData = async () => {
      try {      
        const results = await ListLabels();
        setList(results.resultList); 
        return;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();    
  }, []); 

  const addRow = () => {
    let newList = [...list];
    newList.push({name:'',color:'',status:1});
    setList(newList);
  };

  const delRow = (index,name) => {
    if (window.confirm('Êtes-vous sur de supprimer ce Label "'+name+'" ? ')) {
      setList(prevList => prevList.filter((_, idx) => idx !== index));
    }
  };

  const onNameChange = (e, index) => {
    //console.log(index, e.target.value);
  
    let newList = [...list]; // Copy the list array
    let updatedItem = { ...newList[index] }; 
  
    if (e.target.value) {
      updatedItem.name = e.target.value; 
    }
  
    newList[index] = updatedItem; 
    setList(newList);
  }; 

  const saveList = async () => {

    let isBad = 0
    let idx = 1;
    let cleanList = [];
    for(const item of list){
      if (!item.name || item.name === '') isBad++;
      cleanList.push({...item, pos:idx});
      idx++;
    }
    if (isBad) {
      const strS = isBad > 1 ? 's':'';
      openNotification('error','Il y a '+ isBad +' intitulé'+ strS +' non renseigné'+ strS +'');     
      return;
    }

    const resValue = await SaveLabels(cleanList);
    if (resValue) {
      setList(resValue);
      dispatch(saveLists({ section : section, data : resValue }));
      return openNotification('success',"Labels sauvegardés");      
    } else {
      return openNotification('error',"Erreur durant la sauvegarde");
    }
    

  };

  const onPopChange = (index,props)=>{
    const newList = list.map((item, idx) => {
      if (idx === index) {       
        return { ...item, color: props.color };
      }
      return item;
    });
    setList(newList);
    dispatch(saveLists({ section : section, data : newList }));
  };

  return (
    <div className="container">
        <HeaderBar 
          actionContent={
            <div className="header-actions" style={{ marginLeft:"16px"}}>
              <div onClick={()=>addRow()} className="secondary-button" >+</div>
            </div>
          }        
          hasBack={true} 
          hasFilters={false} 
          hasSort={false} 
          hasSearch={false} 
          section={section} 
          storedFilters={{}}
        />
        <div className="content" style={{display: "flex",flexDirection: "column",alignItems: "center"}}>    
          <div className="page-card" style={{width:"400px"}}>
            <div className="label-list">
              {(list && list.length !==0) && list.map((item,index)=>{
                return (
                  <div key={index.toString()} className="label-row">
                    <div>
                      <Input  
                        style={{marginBottom:'16px'}}
                        value={list[index] ? list[index].name : ''}
                        id="name"
                        name="name"
                        placeholder="Intitulé du Label" 
                        onChange={(e)=>onNameChange(e,index)}
                      />
                    </div>
                    <div>
                      <PopOver 
                        content={
                          <div className="tag" style={{backgroundColor:item.color,color:item.color ? "white" : 'black'}}>Color</div>
                        }
                        prop="color"
                        handlerMethod={onPopChange}
                        items={colorValues}
                        user={index}
                      />
                    </div>
                    <DeleteOutlined 
                      title="Effacer"
                      style={{ color: "var(--accent-color)", marginLeft :'8px'}} 
                      onClick={() => delRow(index,item.name)} 
                    /> 
                  </div>
                );
              })}   
            </div>
            {(list && list.length !==0) &&
              <div style={{display:'flex'}}> 
                <div className='button  button-secondary  button-tiny spacer'  onClick={()=>navigate('/setting')} style={{marginTop:'16px'}}>                
                    <span>{t('buttons.cancel')}</span>
                </div>            
                <div className='button button-with-accent button-tiny'  onClick={saveList} style={{marginTop:'16px'}}>                
                    <span>{t('buttons.save')}</span>
                </div>
              </div> 
            }      
            </div>     
        </div>
    </div>    
  );
};

export default LabelList;