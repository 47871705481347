import React,{useEffect,useState} from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
//import { useAuth } from "../../hooks/AuthProvider";
import { useTranslation } from 'react-i18next';
import {  Badge, Spin, Card , Divider } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import { 
  MetricsDashboard,
  MsgDashboard,
  UsersDashboard,
  MissionsDashboard,
  ToDoDashboard
} from "../../services/Dashboard"
import { capitalize } from "../../utils/miscHelpers";
import { saveUser } from '../../features/user/userSlice';

import HeaderBar from "../../components/layout/HeaderBar";
import PopOverForm from "../../components/elements/PopOverForm"

const section = 'dashboard';

const DashboardScreen = () => {
  // const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Meta } = Card;  
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});

  const { t } = useTranslation();
  const [metrics, setMetrics] = useState({});
  const [messages, setMessages] = useState([]);
  const [isLoadingMessage, setIsLoadingMessage]= useState(false);
  const [users, setUsers] = useState([]);
  const [isLoadingUser, setIsLoadingUser]= useState(false);
  const [operations, setOperations] = useState([]);
  const [isLoadingOp, setIsLoadingOp]= useState(false);
  const [todos, setTodos] = useState([]);
  const [isLoadingTodo, setIsLoadingTodo]= useState(false);
  
  const fetchData = async () => {
    try {

      setIsLoadingMessage(true);
      setIsLoadingUser(true);
      setIsLoadingOp(true);
      setIsLoadingTodo(true);

      const metricsResults = await MetricsDashboard({});        
      setMetrics(metricsResults); 

      const msgResults = await MsgDashboard({});
      setMessages(msgResults); 
      setIsLoadingMessage(false);

      const usersResults = await UsersDashboard({});        
      setUsers(usersResults); 
      setIsLoadingUser(false);

      const opsResults = await MissionsDashboard({});        
      setOperations(opsResults); 
      setIsLoadingOp(false);

      const todoResults = await ToDoDashboard({});        
      setTodos(todoResults);   
      setIsLoadingTodo(false);

      return;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();    
  }, []); 
  
  const linkMe = (page,data) =>{
    switch(page){
      case 'message' :
        if (data && data._id) navigate('/user/detail?staffId='+data._id,{state:{activeKey:'4'}});
      return;      
      case 'messageHome' : 
        navigate('/message');
      return;
      case 'userHome' : 
        navigate('/user');
      return;
      case 'opHome' : 
        navigate('/operation');
      return;       
      case 'todoHome' : 
        navigate('/todo');
      return;           
      case 'user' : 
        dispatch(saveUser(data));
        navigate('/user/detail');
      return;
      case 'operation' : 
        if (data && data._id) window.open('/operation/detail?opId='+data._id);
        // dispatch(saveOperation(data));
        // navigate('/operation/detail');
      return;      
      default:
    }
  };


  const onEditTodo = (record) =>{
    //console.log('onEdit',record);
    return true;
  };

  const messageContent = ()=>{
    return (
      <div>
        <h3 className="dashboard-h3 link" style={{width:'100%'}} onClick={()=>linkMe('messageHome')}>Derniers Messages reçus</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
        <div style={{height:'320px',overflow:'auto'}}>
          {isLoadingMessage &&
            <Spin className='spinner-dashboard'/>
          }
          {!isLoadingMessage && messages && messages.length > 0 && messages.map((message,idx)=>{
            return(
              <div key={'msg-'+idx.toString()} className="link flex flex-column" style={{marginBottom:'10px'}} onClick={()=>linkMe('message',message._user)}>
                <div className="flex flex-column-start">
                  <img alt="user" src={message._user.avatar ? message._user.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />
                  {!message.adminView && <Badge count={1} style={{    position:'absolute',marginTop: '-20px'}}/>}
                  <div className="h2" style={{
                      marginLeft: '10px',
                      minWidth: '120px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '120px',
                      marginRight: '10px',                    
                    }}>{capitalize(message._user?.firstName)} {capitalize(message._user?.lastName)}</div>
                </div>
                <div className="body" style={{width:'100%',minWidth:'160px', whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',marginRight: '10px',}}>{message.body}</div>
                <div className="body"  style={{minWidth:'140px'}}>{dayjs(message.cdate).format('DD MMM YYYY - HH:mm')}</div>
              </div>
            )
          })}
        </div>
      </div>      
    );
  };

  const userContent = ()=>{
    return (
      <div>
        <h3 className="dashboard-h3 link" onClick={()=>linkMe('userHome')}>Dernières Inscriptions</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
          <div style={{height:'320px',overflow:'auto'}}>            
            {isLoadingUser &&
              <Spin className='spinner-dashboard'/>
            }
            {!isLoadingUser && users && users.length > 0 && users.map((user,idx)=>{
              return(
                <div key={'user-'+idx.toString()} style={{marginBottom:'10px'}} className="link flex flex-column-start" onClick={()=>linkMe('user',user)}>
                  <img alt="user" src={user.avatar ? user.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />
                  <div style={{marginLeft:'10px'}}>
                    <div className="h2" style={{minWidth:'160px'}}>{capitalize(user.firstName)} {capitalize(user.lastName)}</div>         
                    <div className="body" >{dayjs(user.cdate).format('DD MMM YYYY - HH:mm')}</div>
                  </div>
                </div>
              )
            })}
          </div>
      </div>      
    );
  };

  const operationContent = ()=>{
    return (
      <div>
        <h3 className="dashboard-h3 link"  onClick={()=>linkMe('opHome')}>Dernières Opérations</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
        <div style={{height:'320px',overflow:'auto'}}>
          {isLoadingOp &&
              <Spin className='spinner-dashboard'/>
            }
          {!isLoadingOp && operations && operations.length > 0 && operations.map((op,idx)=>{
              return(
                <div key={'todo-'+idx.toString()} className="link flex flex-column" onClick={()=>linkMe('operation',op)}>
                  <div className="h2 capitalize" style={{marginBottom:'10px',overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',width:'150px'}} >{op.name}</div>                              
                  <div className="body" style={{whiteSpace: 'nowrap', }}>{dayjs(op.sdate).format('DD MMM')} {">"} {dayjs(op.edate).format('DD MMM YYYY')}</div>                                    
                </div>
              )
            })}          
        </div>
      </div>      
    );
  };

  const todoContent = ()=>{
    return (
      <div>
        <h3 className="dashboard-h3 link"  onClick={()=>linkMe('todoHome')}>Mes Tâches</h3>
        <Divider                   
          style={{padding:0,width:'100%', height: '1px' , marginTop:'10px', marginBottom:'10px', minWidth: '100%', borderColor: 'var(--accent-color)'}}
          />
        <div style={{height:'320px',overflow:'auto'}}>
          {isLoadingTodo &&
              <Spin className='spinner-dashboard'/>
            }
          {!isLoadingTodo && todos && todos.length > 0 && todos.map((todo,idx)=>{
              return(
                  <PopOverForm
                    key={'todo-'+idx.toString()} 
                    content={
                      <div className="flex flex-column link" style={{marginBottom:'10px'}}>
                        <div className="body capitalize" style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis',width:'150px'}} >{todo.name}</div>                              
                        <div className="body">{dayjs(todo.ddate).format('DD MMM YYYY')}</div>
                        <EyeOutlined 
                          title="Voir / Editer"
                          className='link icon-table'
                        /> 
                      </div>
                    }
                    form="todo"
                    handlerMethod={onEditTodo}
                    data={todo}                    
                  />                                   
              )
            })}          
        </div>
      </div>      
    );
  };

  return ( 
    <div className="container">
        <HeaderBar hasFilters={false} hasSort={false} hasSearch={false}  section={section} storedFilters={storedFilters}/>
        <div className="content" style={{top:'20px'}}>
          <div className="dashboard-layout-row">
            <div className="dashboard-layout-column">
              <div className="dashboard-metric">
              <div className="dashboard-metric-block" onClick={()=>navigate('/user')}>
                <div className="dashboard-circle accent-color-border">{metrics && metrics.user ? metrics.user.total : 0}</div>
                <div >{t('dashboard.members')}</div>
              </div>
              <div className="dashboard-metric-block" onClick={()=>navigate('/operation')}>
                <div className="dashboard-circle accent-color-border">{metrics && metrics.booked ? metrics.booked.total : 0}</div>
                <div >{t('dashboard.operations')}</div>
              </div>
              <div className="dashboard-metric-block" onClick={()=>navigate('/operation')}>
                <div className="dashboard-circle orange-color-border">{metrics && metrics.operation ? metrics.operation.total : 0}</div>
                <div >{t('dashboard.operationsRunning')}</div>
              </div>
              <div className="dashboard-metric-block" onClick={()=>navigate('/client')}>
                <div className="dashboard-circle red-color-border">{metrics && metrics.quote ? metrics.quote.total : 0}</div>
                <div >{t('dashboard.quotes')}</div>
              </div>                                    
            </div>
            </div>
            <div className="dashboard-layout-column">
              <Card className="dashboard-card large">
                <Meta description={messageContent()}/>
              </Card>
            </div>            
          </div>
          <div className="dashboard-layout-row">
            <div className="dashboard-layout-column">
              <Card className="dashboard-card">
                <Meta description={userContent()} />
              </Card>
            </div>
            <div className="dashboard-layout-column">
              <Card className="dashboard-card">
                <Meta description={operationContent()} />
              </Card>
            </div>               
            <div className="dashboard-layout-column">
              <Card className="dashboard-card">
                <Meta description={todoContent()} />
              </Card>
            </div>            
          </div>
        </div>
    </div>    
  );
};

export default DashboardScreen;