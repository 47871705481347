import React  from 'react';
import { Link , useLocation} from "react-router-dom";
import { useSelector  } from 'react-redux';
import { Badge, Menu, Button , Divider} from 'antd';
import { DashboardOutlined,MailOutlined,TeamOutlined, BarsOutlined, SettingOutlined, FieldTimeOutlined, UserOutlined, PoweroffOutlined, ApartmentOutlined, CarryOutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuth } from "../../hooks/AuthProvider";

const { Item } = Menu;

const MenuItems = () => {

    const storedNotification = useSelector(state => state.notification.value ? state.notification.value : null);

    const auth = useAuth();
    const { t } = useTranslation();  
    const { pathname } = useLocation();


    let selectedItemKey = '1';
    const parts = pathname.split('/');
    if (parts.length) {    
        const menuItem = parts[1];
        const menuItems = {
            dashboard : 1,
            agenda : 2,
            todo : 3,
            user : 4,        
            client : 5,
            operation : 6,
            joboffer : 7,
            message : 8,
            setting : 9
        };
        selectedItemKey = menuItems[menuItem].toString();
    }

    return (
            <Menu theme="dark" selectedKeys={[selectedItemKey]} mode="inline" style={{paddingTop:'16px'}}>
                <Item key="1" icon={<DashboardOutlined />} style={{textAlign:"left"}}>
                    <Link to="/dashboard">{t('menu.dashboard')}</Link>
                </Item>
                <Item key="2" icon={<FieldTimeOutlined />} style={{textAlign:"left"}}>
                    <Link to="/agenda">{t('menu.agenda')}</Link>
                </Item>        
                <Item key="3" icon={<CarryOutOutlined />} style={{textAlign:"left"}}>
                    <Link to="/todo">{t('menu.todo')}</Link>
                </Item>
                <Item key="4" icon={<UserOutlined />} style={{textAlign:"left"}}>
                    <Link to="/user">{t('menu.user')}</Link>
                </Item>
                <Item key="5" icon={<ApartmentOutlined />} style={{textAlign:"left"}}>
                    <Link to="/client">{t('menu.client')}</Link>
                </Item>
                <Item key="6" icon={<TeamOutlined />} style={{textAlign:"left"}}>
                    <Link to="/operation">{t('menu.operation')}</Link>
                </Item>
                <Item key="7" icon={<BarsOutlined />} style={{textAlign:"left"}}>
                    <Link to="/joboffer">{t('menu.joboffer')}</Link>
                </Item>
                <Item key="8" icon={<Badge count={storedNotification && storedNotification.message ? storedNotification.message.total : 0 }><MailOutlined /></Badge>} style={{textAlign:"left"}}>
                    <Link to="/message">{t('menu.message')}</Link>
                </Item>
                <Item key="9" icon={<SettingOutlined />} style={{textAlign:"left"}}>
                    <Link to="/setting">{t('menu.setting')}</Link>
                </Item>    
                <Item key="10">
                    <Divider 
                        dashed
                        style={{ margin: '0', marginTop:30, backgroundColor: '#aaaaaa', height: 1 }}
                    />              
                </Item>                           
                <Item  key="11" icon={<PoweroffOutlined />} style={{textAlign:"left"}}  onClick={() => auth.logOut()}>
                    <Button className="sidebar-button" type="text" block onClick={() => auth.logOut()}>{t('buttons.logout')}</Button>
                </Item>        
            </Menu>
    );
};

export default MenuItems;