import React,{useState,useEffect} from "react";
import moment from 'moment';
import { useSelector,useDispatch } from 'react-redux';
//import { useTranslation } from 'react-i18next';
import { Table, Pagination } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { DeleteOutlined,EditOutlined } from '@ant-design/icons';
import { useNotification } from '../../../hooks/NotificationProvider';
import { ListToDos, DeleteToDo } from "../../../services/ToDo"
import { Priorities } from '../../../utils/constants'
import HeaderBar from "../../../components/layout/HeaderBar";
import PopOverForm from "../../../components/elements/PopOverForm"
import LabelForm from "../../../components/label/LabelForm"
import { saveLists } from '../../../features/lists/listsSlice';
import { updateList } from "../../../utils/miscHelpers"

const section = 'todo';

const ToDoList = () => {

  const defaultPageSize = 20;

  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openNotification } = useNotification();
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  
  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  

  const [listItems, setListItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(100);
  
  const [sortBy, setSortBy] = useState('cdate');
  const [orderBy, setOrderBy] = useState(-1);

  // VIEW PORT DISPLAY SIZES
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const handleResize = () => {
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  };

  const fetchData = async () => {
    try {        
      const sort= {};
      sort[sortBy] = orderBy;
      
      // Get list
      const {resultList, total} = await ListToDos({page:currentPage,pageSize:pageSize,filters:{...storedFilters,...{sort:sort}}});        
      setListItems(resultList);        
      setTotalPages(total);
      dispatch(saveLists({section:section,data:resultList}));

      return;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setListItems(storedList);        
  }, [storedList]); 

  useEffect(() => {
    fetchData();    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, [currentPage,pageSize,storedFilters,sortBy,orderBy]); 


  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      setSortBy(sorter.columnKey);
      setOrderBy(sorter.order === 'ascend' ? 1 : -1);
    }    
  };
  
  const onEdit = (record) =>{
    //console.log('onEdit',record);
    return true;
  };

  const Delete = async (record) =>{    
    if (window.confirm('Êtes-vous sur de vouloir effacer cette Tache ?')) {
      const response = await DeleteToDo(record._id);
      if (response) {
        const newList = listItems.filter((ele)=>{return ele._id !== record._id})      
        openNotification('success',"Tache effacée");     
        setListItems(newList);
      } else {
        openNotification('error',"Un probléme est survenu lors de la suppression de la Tache");     
      }
      return true;
    }
  }

  const onPaginationChange = (page,pageSize) => {  
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onLabelUpdated = (record) => {
    const updatedList = updateList(storedList,record); 
    setListItems(updatedList);                
    dispatch(saveLists({ section : section, data : updatedList}));
    return true;
  };

  const onSubmit = (newlist) =>{
    // console.log('onSubmit newlist',newlist);
    // update Todo Stored list 
    return true;
  };
  //console.log(listItems);
  let columns = [   
    { 
      key: "ddate", 
      title: "Echéance" ,
      dataIndex: "ddate", 
      width: '120px',
      render: (record) => { 
        return ( 
        <> 
          <div>{record === 'invalid date' ? '-' : moment(record).format('DD/MM/YYYY')}</div> 
        </> 
        ); 
      },        
      sorter: {},
    },     
    { 
      key: "priority", 
      title: "Priorité", 
      dataIndex: "priority", 
      width: '100px',
      sorter: {}, 
      fixed: 'left',           
      render: (value,record) => { 
        const priority = Priorities.find((ele)=> ele.value === value);
        return ( 
          <> 
            <div>{priority ? priority.label : '-'}</div> 
          </> 
        ); 
      },      
    },       
    { 
      key: "name", 
      title: "Nom", 
      dataIndex: "name", 
      sorter: {},
      width: '300px',
      fixed: 'left',      
      render: (value,record) => { 
          return ( 
          <> 
            <div className="custom-name">{value}</div> 
          </> 
          ); 
        },      
      }, 
      { 
        key: "_admin", 
        title: "Géré par" ,
        dataIndex: "_admin", 
        render: (record) => { 
          return ( 
          <> 
            <div>{record && record._id ? record.firstName+' '+record.lastName : '-'}</div> 
          </> 
          ); 
        },        
        sorter: (a, b) => {          
          if (b && b._admin) return a._admin.lastName.localeCompare(b._admin.lastName);
        },
      },           
      { 
        key: "label", 
        title: "Labels",
        dataIndex: "label", 
        render: (value,record) => { 
          return(
            <LabelForm 
              section={section}
              labels={value}
              record={record}
              handlerMethod={onLabelUpdated}
              storedLabelList={storedLabelList || []}
          />
          );
        },      
      },                       
      { 
        key: "status", 
        title: "Status" ,
        dataIndex: "status", 
        width: '160px',
        render: (record) => { 
          return ( 
          <> 
            <div>{record === 1 ? 'Fait' : 'A faire'}</div> 
          </> 
          ); 
        },        
        sorter: {},
      },        
      { 
        key: "action", 
        title: "Actions", 
        width: '100px',
        fixed: 'right',
        render: (record) => { 
          
          return ( 
            <> 
              <div className="flex" key={record._id}> 

                <PopOverForm
                  content={
                    <EditOutlined 
                      title="Editer"
                      className='icon-table'
                    /> 
                  }
                  form="todo"
                  handlerMethod={onEdit}
                  data={record}
                />

                <DeleteOutlined 
                  title="Effacer"
                  className='icon-table'
                  onClick={() => Delete(record)} 
                /> 
              </div> 
            </> 
          ); 
        }, 
      },                  
  ];
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
  columns = columns.map(col => {
    //console.log(col)
    let cleanCol = {
      ...col,
      fixed: isMobile ? undefined : col.fixed,
    };
    if (isMobile && (cleanCol.key === 'name' || cleanCol.key === '_admin')) {
      cleanCol.width = '130px';
    }
    return(cleanCol);
  });

  //ListToDos
  return (
    <div className="container">
        <HeaderBar 
          actionContent={
            <div style={{ marginLeft:"16px"}}>
              <PopOverForm
                content={
                  <div className="secondary-button">+</div>
                }
                form="todo"
                handlerMethod={onSubmit}
                data={{}}
              />
            </div>
          }
          hasFilters={true} 
          hasSort={false} 
          hasSearch={true} 
          section={section} 
          storedFilters={storedFilters} 
          setCurrentPage={setCurrentPage}
        />
        <div className="content">    
          <div>
            <Table 
              dataSource={listItems} 
              rowKey="_id"
              columns={columns} 
              pagination={false} 
              className="capitalize custom-checkbox custom-table norowselection"
              onChange={onTableChange} 
              scroll={{
                y: viewportHeight*0.7,
                x: viewportWidth*0.5,
              }}
            />
            <Pagination
              showSizeChanger={true} 
              current={currentPage}
              pageSize={pageSize}                
              total={totalPages} 
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
              onChange={onPaginationChange}
              className="pagination-list"
            />
          </div> 
        </div>
    </div>    
  );
};

export default ToDoList;