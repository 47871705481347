import { Post, ApiRoutes } from "./Api"


export const SaveClient = async (data) => {
    try {
        const res = await Post(ApiRoutes.clientAdd, data);
        if (res) return res.value;        
    } catch (err) {
      console.error('SaveClient resultList',err);
    }
};

export const MissionQuoteClient = async (data) => {
    try {
        const res = await Post(ApiRoutes.clientMissionQuote, data);
        if (res) return res.value;        
    } catch (err) {
      console.error('SaveClient resultList',err);
    }
};

export const EditClientProps = async (itemId,props) =>{
    try {
        const res = await Post(ApiRoutes.clientEdit, { id: itemId, ...props});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error EditClientProps');
        }        
    } catch (err) {
      console.error('EditClientProps Err',err);
    }
};


export const ListClients = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.clientList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('Client resultList',err);
    }
};

