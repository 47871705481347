import React, { createContext, useContext } from 'react';
import { notification } from 'antd';
import { Config } from '../config'

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, message) => {
    api[type]({
      duration: Config.notif.duration, // Assuming Config.notif.duration is defined elsewhere
      message: message,
    });
  };

  return (
    <NotificationContext.Provider value={{ openNotification, contextHolder }}>
      {children}
      {contextHolder}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
