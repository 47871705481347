import React,{ useState} from "react";
//import { useDispatch, useSelector } from 'react-redux';
//import { useTranslation } from 'react-i18next';
//import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {  Form, Input } from 'antd';

//import { saveLists } from '../../../features/lists/listsSlice';
import { SaveAdmin, EditAdminProps } from '../../../services/Admin';
import { useNotification } from '../../../hooks/NotificationProvider';
//import PopOver from "../../../components/elements/PopOver"
import HeaderBar from "../../../components/layout/HeaderBar";

const section = 'admin';

const AdminForm = () => {

  const { openNotification } = useNotification();
  //const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  
  const admin = location.state || null;

  //const [admin, setAdmin] = useState({});
  const [firstName, setFirstName] = useState(admin && admin.firstName ? admin.firstName : '');
  const [lastName, setLastName] = useState(admin && admin.lastName ? admin.lastName : '');
  const [email, setEmail] = useState(admin && admin.email ? admin.email : '');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');

  const topSpacer = 50;

  const onCancel = () =>{
    navigate('/setting/admin');
  };

  function validatePassword(password, passwordConf) {
    const lengthCheck = /^.{10,}$/;              // At least 10 characters
    const numberCheck = /[0-9]/;                 // At least one number
    const uppercaseCheck = /[A-Z]/;              // At least one uppercase letter
    const specialCharCheck = /[!@#$%^&*(),.?":{}|<>]/; // At least one special character

    let isValid = true;

    if (!lengthCheck.test(password)) {
        openNotification('error',"Le mot de passe doit faire 10 caractères min.");   
        isValid = false;          
    }
    if (!numberCheck.test(password)) {
        openNotification('error',"Le mot de passe doit contenir au moins 1 chiffre.");   
        isValid = false;
    }
    if (!uppercaseCheck.test(password)) {
        openNotification('error',"Le mot de passe doit contenir au moins 1 lettre majuscule.");   
        isValid = false;
    }
    if (!specialCharCheck.test(password)) {
        openNotification('error',"Le mot de passe doit contenir au moins 1 caractère spéciale.");   
        isValid = false;
    }
    if (password !== passwordConf) {
      openNotification('error',"Les mot de passes ne correspondent pas");
      isValid = false;
  }    

    return isValid;
}

  const onSubmit = async () =>{
    
    let props = {
      firstName,
      lastName,
      email,        
    };

    if (password!=='') {
      const isValid = validatePassword(password,passwordConf);
      if (!isValid) return false;
      props.password = password;
    }

    if (        
      props.firstName  === '' ||
      props.lastName === '' ||
      props.email  === '') {
      openNotification('error',"Les champs Prénom, Nom et Email sont obligatoires");
      return false;
    }

    let resValue = null;  
    if (!admin || !admin._id) {
      if (props.password === '') {
        openNotification('error',"Le champ Mot de passe est obligatoire pour créer un nouvel utilisateur");
        return false;
      }
      resValue = await SaveAdmin(props);      
    } else {
      resValue = await EditAdminProps(admin, props);      
    }    
  
    if (resValue) {
      openNotification('success',"Utilisateur Sauvegardé");
      navigate('/setting/admin'); 
    } else {
      openNotification('error',"Erreur durant la sauvegarde");
      return false;
    }
  };

  const onChange = (e,field) =>{
    const value = e?.target?.value;    
    switch(field){
      case 'email' : 
        setEmail(value);
      break;
      case 'firstName' : 
        setFirstName(value);
      break;
      case 'lastName' : 
        setLastName(value);
      break;
      case 'password' : 
        setPassword(value);
      break;
      case 'passwordConf' : 
        setPasswordConf(value);
      break;                  
      default:
    }
  }

  return (
    <div className="container">
        <HeaderBar 
          actionContent={null}        
          hasBack={true} 
          hasFilters={false} 
          hasSort={false} 
          hasSearch={false} 
          section={section} 
          storedFilters={{}}
        />
          <Form
            name="basic"      
            autoComplete="do-not-autofill"
          >
            <div className='tab-form' style={{borderRadius:'10px',backgroundColor:'white',padding:'16px',marginTop:topSpacer+'px'}}>           
                <div className='tab-form-block' style={{padding:'0 16px'}}>              
                      <div className="form-label-name">
                          <span className="label">Email</span>                                
                          <Input 
                            title="Email"
                            autoComplete="do-not-autofill"
                            value={email}
                            id="field1"
                            name="field1"
                            placeholder="Email" 
                            className=""
                            onChange={(e)=>onChange(e,'email')}
                          />
                      </div>                
                </div>              
                <div className='tab-form-block'>
                  <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                        <div className="form-label-name">
                            <span className="label">Prénom</span>                                
                            <Input 
                              title="Prénom"
                              autoComplete="do-not-autofill"
                              value={firstName}
                              id="field2"
                              name="field2"
                              placeholder="Prénom" 
                              className=""
                              onChange={(e)=>onChange(e,'firstName')}
                            />
                        </div>
                    </div>                                   
                    <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                        <div className="form-label-name">
                            <span className="label">Nom</span>                                
                            <Input 
                              title="Nom"
                              autoComplete="do-not-autofill"
                              value={lastName}
                              id="field3"
                              name="field3"
                              placeholder="Nom" 
                              className=""
                              onChange={(e)=>onChange(e,'lastName')}
                            />
                        </div>
                    </div>                            
                </div>  
                <div className='tab-form-block'>
                  <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                        <div className="form-label-name">
                            <span className="label">Mot de passe</span>                                
                            <Input 
                              type="password"
                              autoComplete="do-not-autofill"
                              title="Mot de passe"
                              value={password}
                              id="field4"
                              name="field4"
                              placeholder="Mot de passe" 
                              className=""
                              onChange={(e)=>onChange(e,'password')}
                            />
                        </div>
                    </div>                                   
                    <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                        <div className="form-label-name">
                            <span className="label">Confirmer le mot de passe</span>                                
                            <Input 
                              type="password"
                              autoComplete="do-not-autofill"
                              title="Confirmer le mot de passe"
                              value={passwordConf}
                              id="field5"
                              name="field5"
                              placeholder="Confirmer le mot de passe" 
                              className=""
                              onChange={(e)=>onChange(e,'passwordConf')}
                            />
                        </div>
                    </div>                            
                </div>  
                <div className='tab-form-block' style={{marginTop:'32px'}}>
                  <div style={{ margin: '0 8px', width:"140px" }} className="button button-tiny cancel " onClick={onCancel}>
                    Annuler
                  </div>                
                  <div style={{ margin: '0 8px', width:"140px" }} className="button link button-with-accent button-tiny" onClick={onSubmit}>
                    Sauvegarder
                  </div>
                </div>

            </div> 
          </Form>        
      </div>            
    
  );
};

export default AdminForm;