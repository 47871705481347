import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    agenda: [],
    client: [],
    dashboard: [],
    joboffer: [],
    message: [],
    mission: [],
    operation: [],
    setting: [],
    todo: [],
    user: []
  },
};

export const constantsSlice = createSlice({
  name: 'constants',
  initialState: initialState,
  reducers: {
    getConstants: (state) => {
      return state.value 
    },
    saveConstants: (state, action) => {      
      if (action.payload && action.payload.section) {
        if (!state.value) state.value = {}; 
        if (!state.value[action.payload.section]) state.value[action.payload.section] = {};
        state.value[action.payload.section] = action.payload.data;
      }
    },
    resetConstants: (state, action) => {
      state.value = initialState;
    },
    saveAllConstants: (state, action) => {
      state.value = action.payload;
    },    
  },
})

// Action creators are generated for each case reducer function
export const { getConstants, saveConstants, saveAllConstants, resetConstants } = constantsSlice.actions

export default constantsSlice.reducer