import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
};

export const clientSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getClient: (state) => {
      return state.value 
    },
    saveClient: (state, action) => {
      state.value = action.payload;
    },
    resetClient: (state) => {
      state.value = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { getClient, saveClient, resetClient } = clientSlice.actions

export default clientSlice.reducer