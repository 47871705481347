import React,{ useState } from "react";
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Input,Select,DatePicker } from 'antd';
import { useNotification } from '../../../hooks/NotificationProvider';
import { EditJobOfferProps, SaveJobOffer} from "../../../services/JobOffer"
import { AppLangs, Languages, Currencies } from '../../../utils/constants';
import HeaderBar from "../../../components/layout/HeaderBar";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const section = 'joboffer';
const defaultLang = 'fr';

const JobOfferForm = () => {

  const status = [
    {label:'Publié',value:1},
    {label:'En attente',value:2}
  ];  

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  
  const navigate = useNavigate();
  const location = useLocation();
  const jobOffer = location.state || null;

//  console.log('jobOffer',jobOffer);

  const { openNotification } = useNotification();
  const { t } = useTranslation();
  const [jobLang, setJobLang] = useState(jobOffer && jobOffer.language ? jobOffer.language : defaultLang);
  const [jobStatus, setJobStatus] = useState(jobOffer &&  jobOffer.status ? jobOffer.status : 2);
  const [jobName, setJobName] = useState(jobOffer &&  jobOffer.name ? jobOffer.name : '');
  const [jobLocation, setJobLocation] = useState(jobOffer && jobOffer.location ? jobOffer.location : '');

  const [jobBodyFr, setJobBodyFr] = useState(jobOffer &&  jobOffer.fr &&  jobOffer.fr.body ? jobOffer.fr.body : '');
  const [jobTargetFr, setJobTargetFr] = useState(jobOffer &&  jobOffer.fr &&  jobOffer.fr.target ? jobOffer.fr.target : '');

  const [jobBodyEn, setJobBodyEn] = useState(jobOffer &&  jobOffer.en &&  jobOffer.en.body ? jobOffer.en.body : '');
  const [jobTargetEn, setJobTargetEn] = useState(jobOffer &&  jobOffer.en &&  jobOffer.en.target ? jobOffer.en.target : '');

  const [jobBodyDe, setJobBodyDe] = useState(jobOffer &&  jobOffer.de &&  jobOffer.de.body ? jobOffer.de.body : '');
  const [jobTargetDe, setJobTargetDe] = useState(jobOffer &&  jobOffer.de &&  jobOffer.de.target ? jobOffer.de.target : '');

  const [jobBodyIt, setJobBodyIt] = useState(jobOffer &&  jobOffer.it &&  jobOffer.it.body ? jobOffer.it.body : '');
  const [jobTargetIt, setJobTargetIt] = useState(jobOffer &&  jobOffer.it &&  jobOffer.it.target ? jobOffer.it.target : '');

  const [selectedDates, setSelectedDates] = useState([jobOffer &&  jobOffer.sdate ? jobOffer.sdate : dayjs().format('YYYY-MM-DD') , jobOffer &&  jobOffer.edate ? jobOffer.edate :  dayjs().format('YYYY-MM-DD') ]);
  const [price, setPrice] = useState(jobOffer &&  jobOffer.price ? jobOffer.price : '');
  const [currency, setCurrency] = useState(jobOffer &&  jobOffer.currency ? jobOffer.currency : 'chf');
  const [languages, setLanguages] = useState(jobOffer &&  jobOffer.languages ? jobOffer.languages : []);
  const [latitude, setLatitude] = useState(jobOffer && jobOffer.latitude ? jobOffer.latitude : '');
  const [longitude, setLongitude] = useState(jobOffer && jobOffer.longitude ? jobOffer.longitude : '');

 const onDateChange = (dates) => {
   setSelectedDates(dates);
 };

 const onPriceChange= (e) => {
  setPrice(e.target.value);
 };

  const onLangChange = (e) => {
    setJobLang(e);
  };

  const onBodyChange = (e,lang) => {
    switch(lang){
      case 'fr' :
        setJobBodyFr(e.target.value);
      break;
      case 'en' :
        setJobBodyEn(e.target.value);
      break;
      case 'de' :
        setJobBodyDe(e.target.value);
      break;
      case 'it' :
        setJobBodyIt(e.target.value);
      break;
      default:
    }
  };

  const onTargetChange = (e,lang) => {
    switch(lang){
      case 'fr' :
        setJobTargetFr(e.target.value);
      break;
      case 'en' :
        setJobTargetEn(e.target.value);
      break;
      case 'de' :
        setJobTargetDe(e.target.value);
      break;
      case 'it' :
        setJobTargetIt(e.target.value);
      break;
      default:
    }
  };  

  const onLongitudeChange = (e) => {
    setLongitude(e.target.value);
  };
  const onLatitudeChange = (e) => {
    setLatitude(e.target.value);
  };    

  const onLocationChange = (e) => {
    setJobLocation(e.target.value);
  };
  const onNameChange = (e) => {
    setJobName(e.target.value);
  };    
    
  const onJobOfferSave = async (e) =>{

    e.preventDefault();    

    if (!selectedDates || selectedDates.length !== 2  || !jobName || !jobLang) {
      openNotification('error',"Les champs Date, Titre et Langue sont obligatoires");     
      return;
    }
    
    const props = {
      language:jobLang,
      languages:languages,
      currency:currency,
      price:Number(price),
      status:jobStatus,
      name:jobName,
      location:jobLocation,
      fr:{
        body:jobBodyFr,
        target:jobTargetFr,
      },
      en:{
        body:jobBodyEn,
        target:jobTargetEn,
      },
      de:{
        body:jobBodyDe,
        target:jobTargetDe,
      },
      it:{
        body:jobBodyIt,
        target:jobTargetIt,
      },                  
      latitude,
      longitude,
      sdate:dayjs(selectedDates[0],'YYYY-MM-DD').format('YYYY-MM-DD'),
      edate:dayjs(selectedDates[1],'YYYY-MM-DD').format('YYYY-MM-DD'),
      mdate:new Date(),
    };
    
    let resValue = null;

    // Edit
    if (jobOffer && jobOffer._id) {
      resValue = await EditJobOfferProps(jobOffer._id, props);
    } 
    
    // Save
    else {
      resValue = await SaveJobOffer(props);      
    }

    if (resValue) {
      openNotification('success',"Annonce Sauvegardée");
      return navigate('/joboffer');      
    } else {
      openNotification('error',"Erreur durant la sauvegarde");
      return true;
    }

  };
  
  return (
    <div className="container">
      <HeaderBar
        hasBack={true}
        hasFilters={false}
        hasSearch={false}
        hasSort={false}
        section={section}
        storedFilters={storedFilters}
        setCurrentPage={1}
      />
      <div className="content">    
        <div className="page-card">

          <div className="tab-form-block">                     
            <div className="form-label-name column-left">
              <span className="label">Dates de l'événement</span>    
              <RangePicker 
                format="DD/MM/YYYY"
                value={[
                  dayjs(selectedDates[0], 'YYYY-MM-DD'),
                  dayjs(selectedDates[1], 'YYYY-MM-DD')
                ]}
                onChange={onDateChange} 
              />
            </div>                                                
          </div>
          
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Langue</span>    
              <Select
                style={{
                  width: '100px',
                }}
                optionLabelProp="label"
                optionFilterProp="label"
                placeholder="Langue de l'annonce"
                defaultValue={jobLang}
                value={jobLang}
                onChange={onLangChange}
                options={AppLangs}
                className="filter-select small  accented"
              />
            </div>
            <div className="form-label-name column-left" style={{margin:"0 16px"}}>
              <span className="label">Statut</span>   
              <Select
                style={{
                  width: '100px',
                }}
                optionLabelProp="label"
                optionFilterProp="label"
                placeholder="Statut de l'annonce"
                defaultValue={jobStatus}
                value={jobStatus}
                onChange={setJobStatus}
                options={status}
                className="filter-select small  accented"
              />  
            </div>

            <div className="form-label-name column-left">
              <span className="label">Langues recherchées</span>                
              <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: '100%',
                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Langues"
                  defaultValue={[]}
                  value={languages}
                  onChange={setLanguages}
                  options={Languages}
                  className="filter-select"
              /> 
            </div>
            <div className="form-label-name column-left" style={{
                  marginLeft:'16px',
                  marginRight:'16px',                  
                }}   >
              <span className="label">Tarif Horaire</span>                  
              <Input  
                // style={{marginBottom:'16px'}}
                defaultValue={price}
                id="price"
                name="Price"
                placeholder="Tarif Horaire" 
                onChange={onPriceChange}             
              />  
            </div>
            <div className="form-label-name column-left">
              <span className="label">Devise</span>                  
              <Select
                  style={{
                    width: '75px',                    
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Devises"
                  defaultValue={[]}
                  value={currency}
                  onChange={setCurrency}
                  options={Currencies}
                  className="uppercase accented"
              />  
            </div>  
            
            
          </div>
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Titre de l'annonce</span>              
              <Input  
                style={{marginBottom:'16px'}}
                defaultValue={jobName}
                id="jobName"
                name="jobName"
                placeholder="Titre de l'annonce" 
                onChange={onNameChange}
              />
            </div>
          </div>
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Lieu de l'annonce</span>    
              <Input  
                  style={{marginBottom:'16px'}}
                  defaultValue={jobLocation}
                  id="jobLocation"
                  name="jobLocation"
                  placeholder="Lieu de l'annonce" 
                  onChange={onLocationChange}
                />
            </div>
          </div>
          <div className='tab-form-block' style={{marginBottom:'26px',justifyContent:'space-between'}}>               
              <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                  <div className="form-label-name">
                      <span className="label">Latitude (App)</span>    
                      <Input 
                          title='Latitude (App)'
                          value={latitude}
                          id="latitude"
                          name="latitude"
                          placeholder="Latitude" 
                          onChange={onLatitudeChange}
                      />
                  </div>
              </div> 
              <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                  <div className="form-label-name">
                      <span className="label">Longitude (App)</span>    
                      <Input 
                          title='Longitude (App)'
                          value={longitude}
                          id="longitude"
                          name="longitude"
                          placeholder="Longitude" 
                          onChange={onLongitudeChange}
                      />
                  </div>
              </div>
              <div className='tab-form-block-column' style={{margin:"0 16px"}}>
                  <div className="form-label-name link" onClick={()=>window.open('https://www.latlong.net/')}>
                      <br/>
                      <span>Trouver les coordonnées >></span>    
                  </div>
              </div>                  
          </div>     

          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Description de l'annonce (FR)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobBodyFr}
                  onChange={(e)=>onBodyChange(e,'fr')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Description de l'annonce (FR)"
                />
            </div>
          </div>
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Cible des Candidats (FR)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobTargetFr}
                  onChange={(e)=>onTargetChange(e,'fr')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Cible de l'annonce (FR)"
                />
            </div>
          </div>    

                 
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Description de l'annonce (EN)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobBodyEn}
                  onChange={(e)=>onBodyChange(e,'en')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Description de l'annonce (EN)"
                />
            </div>
          </div>
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Cible des Candidats (EN)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobTargetEn}
                  onChange={(e)=>onTargetChange(e,'en')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Cible de l'annonce (EN)"
                />
            </div>
          </div>    

                 
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Description de l'annonce (DE)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobBodyDe}
                  onChange={(e)=>onBodyChange(e,'de')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Description de l'annonce (DE)"
                />
            </div>
          </div>
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Cible des Candidats (DE)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobTargetDe}
                  onChange={(e)=>onTargetChange(e,'de')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Cible de l'annonce (DE)"
                />
            </div>
          </div>    

                 
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Description de l'annonce (IT)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobBodyIt}
                  onChange={(e)=>onBodyChange(e,'it')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Description de l'annonce (IT)"
                />
            </div>
          </div>
          <div className="tab-form-block">
            <div className="form-label-name column-left">
              <span className="label">Cible des Candidats (IT)</span>    
              <TextArea 
                  style={{marginBottom:'16px'}}          
                  value={jobTargetIt}
                  onChange={(e)=>onTargetChange(e,'it')}
                  className="bmg-textarea" 
                  rows={6} 
                  placeholder="Cible de l'annonce (IT)"
                />
            </div>
          </div>                                  


          <div style={{display:'flex'}}> 
            <div className='button  button-secondary button-tiny spacer'  onClick={()=>navigate('/joboffer')} style={{marginTop:'16px'}}>                
                <span>{t('buttons.cancel')}</span>
            </div>            
            <div className='button button-with-accent button-tiny '  onClick={onJobOfferSave} style={{marginTop:'16px'}}>                
                <span>{t('buttons.save')}</span>
            </div>
          </div> 
        </div> 
      </div>
    </div>
  );
};

export default JobOfferForm ;
