import { configureStore } from '@reduxjs/toolkit'
import filtersReducer from '../features/filters/filtersSlice'
import listsReducer from '../features/lists/listsSlice'
import constantsReducer from '../features/lists/constantsSlice'
import userReducer from '../features/user/userSlice'
import clientReducer from '../features/client/clientSlice'
import missionReducer from '../features/mission/missionSlice'
import operationReducer from '../features/operation/operationSlice'
import messageReducer from '../features/message/messageSlice'
import notificationReducer from '../features/notification/notificationSlice'

export default configureStore({
  reducer: {
    filters: filtersReducer,
    lists: listsReducer,
    user : userReducer,
    client : clientReducer,
    mission : missionReducer,
    operation : operationReducer,
    message : messageReducer,
    constants: constantsReducer,
    notification: notificationReducer,
  },
})