import { Get,Post, ApiRoutes } from "./Api"

export const ListNotifications = async () => {
    try {
        let resultList = [];
        const res = await Get(ApiRoutes.notificationList);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
      console.error('Notification resultList',err);
    }
};

export const viewNotifications = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.notificationView,data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
      console.error('Notification resultList',err);
    }
};
