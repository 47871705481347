import React, { useState } from 'react';
//import { useSelector  } from 'react-redux';
import {  Layout } from 'antd';
//import { DashboardOutlined,MailOutlined,TeamOutlined, BarsOutlined, SettingOutlined, FieldTimeOutlined, UserOutlined, PoweroffOutlined, ApartmentOutlined, CarryOutOutlined } from '@ant-design/icons';
//import { useTranslation } from 'react-i18next';
//import { useAuth } from "../../hooks/AuthProvider";
import MenuItems from './MenuItems';

const { Sider } = Layout;
//const { Item } = Menu;

const SideBar = () => {

    //const storedNotification = useSelector(state => state.notification.value ? state.notification.value : null);

    //const auth = useAuth();
    //const { t } = useTranslation();  
    const [collapsed, setCollapsed] = useState(false);
  
    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };
    
    // const items = [
    //     {
    //       key: '1',
    //       icon: <DashboardOutlined />,
    //       label: <Link to="/dashboard">{t('menu.dashboard')}</Link>,
    //     },
    //     {
    //       key: '2',
    //       icon: <CalendarOutlined />,
    //       label: <Link to="/about">Navigation Two</Link>,
    //     },
    //   ];

    return (
        <Sider className="sider" collapsible collapsed={collapsed} onCollapse={onCollapse}>
            <img alt="logo" src="/img/logo_no_tagline.svg" width={"80%"} height={'auto'}/>
            <MenuItems/>
            {/* <Menu theme="dark" selectedKeys={[selectedItemKey]} mode="inline" style={{paddingTop:'16px'}}>
                <Item key="1" icon={<DashboardOutlined />} style={{textAlign:"left"}}>
                    <Link to="/dashboard">{t('menu.dashboard')}</Link>
                </Item>
                <Item key="2" icon={<FieldTimeOutlined />} style={{textAlign:"left"}}>
                    <Link to="/agenda">{t('menu.agenda')}</Link>
                </Item>        
                <Item key="3" icon={<CarryOutOutlined />} style={{textAlign:"left"}}>
                    <Link to="/todo">{t('menu.todo')}</Link>
                </Item>
                <Item key="4" icon={<UserOutlined />} style={{textAlign:"left"}}>
                    <Link to="/user">{t('menu.user')}</Link>
                </Item>
                <Item key="5" icon={<ApartmentOutlined />} style={{textAlign:"left"}}>
                    <Link to="/client">{t('menu.client')}</Link>
                </Item>
                <Item key="6" icon={<TeamOutlined />} style={{textAlign:"left"}}>
                    <Link to="/operation">{t('menu.operation')}</Link>
                </Item>
                <Item key="7" icon={<BarsOutlined />} style={{textAlign:"left"}}>
                    <Link to="/joboffer">{t('menu.joboffer')}</Link>
                </Item>
                <Item key="8" icon={<Badge count={storedNotification && storedNotification.message ? storedNotification.message.total : 0 }><MailOutlined /></Badge>} style={{textAlign:"left"}}>
                    <Link to="/message">{t('menu.message')}</Link>
                </Item>
                <Item key="9" icon={<SettingOutlined />} style={{textAlign:"left"}}>
                    <Link to="/setting">{t('menu.setting')}</Link>
                </Item>    
                <Item key="10">
                    <Divider 
                        dashed
                        style={{ margin: '0', marginTop:30, backgroundColor: '#aaaaaa', height: 1 }}
                    />              
                </Item>                           
                <Item  key="11" icon={<PoweroffOutlined />} style={{textAlign:"left"}}  onClick={() => auth.logOut()}>
                    <Button className="sidebar-button" type="text" block onClick={() => auth.logOut()}>{t('buttons.logout')}</Button>
                </Item>        
            </Menu> */}
        </Sider>
    );
};

export default SideBar;