import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import SideBar  from "../components/layout/SideBar";
import { Layout,} from 'antd';
//import '../assets/styles/App.css';

const {  Content } = Layout;

const PrivateRoute = () => {
  const user = useAuth();
  if (!user.token) return <Navigate to="/" />;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <SideBar />
      <Layout className="site-layout">
        <Content  style={{  overflow : 'auto', height: '100vh'}}>
          <div style={{ padding: 0, paddingTop:'80px', minHeight: 500 }}>
            <Outlet />
          </div> 
        </Content>
      </Layout>
  </Layout>            
  );
};

export default PrivateRoute;