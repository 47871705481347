import React  from 'react';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { UserOutlined, DeleteOutlined } from '@ant-design/icons';
import {sortMissionSlotsOnRoleByDays} from '../../../utils/miscHelpers';

const numbers = Array.from({ length: 101 }, (_, i) => ({ label: i.toString(), value: i }));

const MissionSlots =  ({
        section, 
        missionSlots,
        staffList,
        changeEntry,
        deleteEntry,
        fullScale
    }) => {    

    return(
        
        <div style={{width:'94%', textAlign: "left"}}>
            {(missionSlots && missionSlots.length !== 0) &&
                 <div className={`step-mission-list ${fullScale ? 'full-scale' : ''}`}>                    
                    {sortMissionSlotsOnRoleByDays(missionSlots).map((mission,index)=>{
                        if (mission
                            && mission.location
                            && mission.role
                            && mission.position
                            && mission.eday
                            && mission.timeSlot                        
                            ) {
                            return(
                                <div key={index.toString()}className='step-mission-item'>
                                    <div className='bold' style={{width:'333px'}}>
                                        <div className='capitalize' >{dayjs(mission.eday).format('dddd D MMMM YYYY')} > {mission.timeSlot.stime} <span className='uncapitalize'>à</span> {mission.timeSlot.etime} ({mission.edayGrp?.length} jours)</div>
                                        <div style={{color:'var(--accent-color)'}}>{mission.location.name} {mission.location.address} {mission.location.city} {mission.location.zipCode} {mission.location.country}</div>
                                    </div>   
                                    <div className='capitalize' style={{width:'200px'}}>
                                        <div>{mission.role.name}, {mission.position}</div>
                                        <div className='uppercase bold' style={{color:'var(--accent-color)'}}>{mission.role.price} / {mission.role.cost}{mission.role.currency}</div>
                                    </div>
                                    <div className='flex' style={{flexDirection: "row",alignItems: "center"}}>
                                        <div className='bold' style={{marginRight:'6px'}}>{mission.users ? mission.users.length : 0} / </div>
                                        <Select
                                            placeholder="0"
                                            defaultValue={0}
                                            value={mission.workForce}
                                            onChange={(e)=>changeEntry('missionWorkForce',e,index)}
                                            options={numbers}
                                            className="number-select bold"
                                        />   
                                        <UserOutlined style={{marginLeft:'6px',color:'var(--accent-color)'}}  />
                                    </div>
                                    <div>                                        
                                        <DeleteOutlined className="link" title='Effacer cette Mission'  style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('mission',index)}/>
                                    </div>
                                </div>
                            )
                        }
                        return false;
                    })}
                </div>
            }                
        </div>

    )
};

export default MissionSlots;