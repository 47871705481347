import { DownloadFile, Upload, GetFile, ApiRoutes } from "../services/Api"

export const getFileExtension = (fileName) => {
    return fileName.split('.').pop();
};


export const blobToDownload = (file, fileName = 'file') => {
    const blobStore = new Blob([file.binary], { type: file.contentType });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blobStore);
        return;
    }
    const data = window.URL.createObjectURL(blobStore);
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
};

export const UploadSectionFile = async (file, section, sectionId) => {
    try {        
        const formData = new FormData();
        formData.append('section', section);
        formData.append('sectionId', sectionId);
        formData.append('file', file);        
        //console.log('UploadSectionFile',section,sectionId)
        const response = await Upload(ApiRoutes.addFile, formData);
        if (response && response.success && response.value && response.value._id){
            return response.value;
        }
    } catch (err) {
      console.error('Catch err UploadFile',section, sectionId, err);
    }
};

export const DeleteSectionFile = async (file, section, id) => {
    try {        
        const data = {file, section, id};      
        const response = await Upload(ApiRoutes.deleteFile, data);
        if (response && response.success && response.value && response.value._id){
            return response.value;
        }
    } catch (err) {
      console.error('Catch err UploadFile',section, id, err);
    }
};

export const getMedia = async (data) => {
    try {
        const mediaUrl = ApiRoutes.mediaFile +'/?section='+data.section+'&file='+data.file+'&size='+data.size;
        return await GetFile(mediaUrl);
    } catch (err) {
      console.error('Catch err getMedia',data, err);
    }
};
  
export const downloadFile = async (data) => {
    try {
        const mediaUrl = ApiRoutes.mediaFile +'/?section='+data.section+'&file='+data.file+'&size='+data.size;
        return await DownloadFile(mediaUrl);
    } catch (err) {
      console.error('Catch err getMedia',data, err);
    }
};
  
export const MediaUrl = async (medias,section,size)=>{
    try {
        let avatarDone = false;
        let resMedia = null;
        if (medias.length) {
            if (section === 'avatar' && !avatarDone ) {
                // Get last avatar uploaded
                const fileRefArr = medias.filter((media)=>{ return media.section === section});
                if (fileRefArr  && fileRefArr.length && fileRefArr[fileRefArr.length-1].file){ 
                    resMedia = await getMedia({file: fileRefArr[fileRefArr.length-1].file,section,size});
                    avatarDone = true;
                }
            }
            if (section === 'media') {
                resMedia = [];
                const mediaRes = medias.filter((media)=>{ return media.section === section});
                if (mediaRes && mediaRes.length){
                    for (const media of mediaRes) {
                        if (media && media.file){
                            resMedia.push(await getMedia({file: media.file,section,size}));
                        }
                    }
                }
            }        
            if (['cv','idCard','workPermit'].includes(section)) {                
                resMedia = await getMedia({file: medias[0],section,size});
            }
        }
        return resMedia; 
    } catch (err) {
        console.error('Catch err MediaUrl',MediaUrl,medias,section,size);
    }           
};

export const ShowFile = async (section,fileName) => {
    try {
        if (fileName) {
            const fileArr = await MediaUrl([ fileName ],section,'large');
            if (['cv','idCard','workPermit'].includes(section)) {
                const newWindow = window.open();
                newWindow.document.write(`<img src="${fileArr}" />`);
            } else {
                if (fileArr && fileArr.length) {
                    const newWindow = window.open();
                    newWindow.document.write(`<img src="${fileArr[0]}" />`);
                }
            }            
        } else {
            console.log('File not found ', fileName);
        }
    } catch (err) {
        console.error('Catch err ShowFile',section,fileName,err);
    }
}