import React from 'react';
import { Tag } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { EditSectionProps } from '../../services/Misc'
import { useNotification } from '../../hooks/NotificationProvider';

import PopOver from '../elements/PopOver'

const LabelForm = ({handlerMethod,isFilter, labels,record,section,storedLabelList}) => {

    const { openNotification } = useNotification();

    const convertObjectLabelToStr = (labeList)=>{
        let list = [];
        if (labeList && labeList.length) {
            for(const label of labeList){
                list.push(label._id);
            }
        }
        return list;
    };

    const saveProps = async (props) =>{
        try {            
            let cleanProps = {label:props.label};
            const resUpdated = await EditSectionProps(section,record._id,cleanProps);
            if (resUpdated && resUpdated._id) {  
                let fullLabels = [];
                if (resUpdated && resUpdated.label) {
                  for(const label of resUpdated.label){
                    const labelId = label && label._id ?  label._id : label; // Due to populate in EDIT
                    const labelItem = storedLabelList.find((ele)=>ele._id === labelId);
                      if (labelItem && labelItem._id) fullLabels.push(labelItem);
                  }
                }
                handlerMethod({...resUpdated,...{label:fullLabels}});
                openNotification('success','Modification Enregistrée');
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
            }
            return true;
        } catch (error) {
            console.error('Error saveProps:', error);
            openNotification('error','Catch / Probléme lors de la sauvegarde');
        }           
    };

    const onDeleteTag = async (e,label,index)=>{
        try {            
            let labelIds = convertObjectLabelToStr(record.label);
            labelIds = labelIds.filter((id)=>id !== label._id);
            if (isFilter) return labelIds;             
            const labelProps = {...record,...{label:labelIds}};
            await saveProps(labelProps);
            return true;
        } catch (error) {
          openNotification('error','Catch / Probléme lors de la sauvegarde onPopChange');
        }   
    };

    const onPopChange = async (record,props) =>{
        try {
            let labelIds = convertObjectLabelToStr(record.label);
            labelIds.push(props.label);
            if (isFilter) return labelIds;
            const labelProps = {...record,...{label:labelIds}};            
            await saveProps(labelProps);
            return true;
        } catch (error) {
            console.log(error);
          openNotification('error','Catch / Probléme lors de la sauvegarde onPopChange');
        }   
    };

    const exlcusionList = (labelsList) =>{
        let list = [];        
        for (const storedLabel of labelsList) {
            const isAlreadyLabel = record && record.label && record.label.length ? record.label.filter((label)=> label._id === storedLabel._id ) : null;
            if (!isAlreadyLabel || !isAlreadyLabel.length) {
                list.push(storedLabel);
            }
        }
        return list ;
    };

    const AddLabelComponent = () =>{
        return (
            <PopOver 
                content={
                    <div 
                        className="tag link" 
                        style={{
                            display:'inline',
                            fontSize:'16px',
                            color:'var(--accent-color)'
                        }}>
                            <PlusCircleOutlined title="Ajouter un Label" className='icon-table'/>
                    </div> 
                }              
                prop="label" 
                handlerMethod={onPopChange}
                items={exlcusionList(storedLabelList)}
                user={record}
            />             
        )
    }

    if (!labels || !labels.length) {
        return ( 
            <AddLabelComponent/>
        ); 
    } else {
        return (               
            <div className="custom-name">
                {labels.map((label,index)=>{

                const onPreventMouseDown = (event) => {
                    event.preventDefault();
                    event.stopPropagation();
                };
                return (
                    <Tag
                        key={index.toString()}
                        color={"white"}
                        onMouseDown={onPreventMouseDown}
                        closable={true}
                        onClose={(e)=>onDeleteTag(e,label,index)}                      
                        className="tag" 
                        style={{
                            margin:"4px",
                            width:'80px',
                            display:'inline',
                            backgroundColor:label.color,
                            color:'white',
                            fontSize:'10px',
                        }}
                    >
                        {label.name}
                    </Tag>
                );
                })}  
                <AddLabelComponent/>
            </div> 
        ); 
      }
};
export default LabelForm;