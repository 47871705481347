import React,{useEffect,useRef, useState} from "react";
import moment from 'moment';
import { useNavigate,useSearchParams,useNavigationType } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import {  Table, Pagination } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { DeleteOutlined, EditOutlined, CopyOutlined } from '@ant-design/icons';

import { ListClients } from "../../../services/Client"
import { useNotification } from '../../../hooks/NotificationProvider';
import { saveFilters } from '../../../features/filters/filtersSlice';
import { saveLists } from '../../../features/lists/listsSlice';
import { saveOperation } from '../../../features/operation/operationSlice';
import { saveClient } from '../../../features/client/clientSlice';
import { updateList, deleteFromeList, capitalize, changeListToLabelValue,findObjDifferences } from "../../../utils/miscHelpers"
import { EditOpProps, CloneOp, ListOps, DeleteOp } from "../../../services/Operation"
import {operationBmgStatus} from "../../../utils/constants"
import HeaderBar from "../../../components/layout/HeaderBar";
import LabelForm from "../../../components/label/LabelForm"
import PopOver from "../../../components/elements/PopOver"
import PopOverForm from "../../../components/elements/PopOverForm"
import ToDoIcon from "../../../components/todo/ToDoIcon";

const section = 'operation';

const OpList = () => {
  
  const defaultPageSize = 40;

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [searchParams, setSearchParams] = useSearchParams();
  const actualPage = searchParams.get('page') || 1;
  
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  const { openNotification } = useNotification();

  const [listItems, setListItems] = useState(storedList);
  const [clientList, setClientList] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(100);
  
  const [sortBy, setSortBy] = useState('cdate');
  const [orderBy, setOrderBy] = useState(-1);

  const prevDependencies = useRef({ actualPage, pageSize, storedFilters, sortBy,orderBy });
  
  useEffect(() => {

    const fetchClients = async () => {
      try {     
        const {resultList } = await ListClients({size:'light'});      
        const listClients = changeListToLabelValue(resultList,'name','_id');
        const sortedListClients = listClients.sort((a, b) => a.label.localeCompare(b.label));        
        setClientList(sortedListClients);        
        return;
      } catch (error) {
        console.error('Error fetching clients data:', error);
      }
    };    

    const fetchData = async (hasFilterDiff) => {
      try {        
        const sort= {};
        sort[sortBy] = orderBy;        

        const goPage = hasFilterDiff ? 1 : actualPage;

        // Get list
        const {resultList, total} = await ListOps({page:goPage,pageSize:pageSize,filters:storedFilters,sort:sort});        

        //console.log('fetchData',resultList.length,total);

        setListItems(resultList);        
        setTotalPages(total);      
        setCurrentPage(goPage);    
        setSearchParams({ page: Number(goPage) });            
        dispatch(saveLists({ section : section, data : resultList}));
        return;

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    setCurrentPage(actualPage);
    const hasFilterDiff = findObjDifferences(prevDependencies.current.storedFilters,storedFilters);
    if (
      prevDependencies.current.actualPage !== actualPage ||
      prevDependencies.current.pageSize !== pageSize ||
      Object.keys(hasFilterDiff).length ||
      prevDependencies.current.sortBy !== sortBy ||
      prevDependencies.current.orderBy !== orderBy ||
      navigationType === 'POP'
    ) {
      fetchClients();
      fetchData(Object.keys(hasFilterDiff).length);      
      prevDependencies.current = { actualPage, pageSize, storedFilters, sortBy,orderBy };      
    }
  }, [ actualPage, pageSize, storedFilters,sortBy,orderBy]);

  // useEffect(() => {
  //   fetchData();    
  // }, [currentPage,pageSize,storedFilters,sortBy,orderBy]); 

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      setSortBy(sorter.columnKey);
      setOrderBy(sorter.order === 'ascend' ? 1 : -1);
    }
  };
  // const Edit = (record) =>{
  //   console.log('Edit record',record);
  // }

  const Delete = async (record) =>{
    
    if (record && record._id) {
      if (window.confirm('Êtes-vous sure de vouloir supprimer cette Opération : '+capitalize(record.name))) {
        const resultDelete = await DeleteOp(record._id);
        if (resultDelete && resultDelete.success) {
          const updatedList = deleteFromeList(storedList,record); 
          setListItems(updatedList);                
          dispatch(saveLists({ section : section, data : updatedList}));
        }
      }
    }    
  }
  
  // 1sr column checkboxes
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(`rowSelection selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };
  const linkMe = async (item,section)=>{    
    switch(section){
      case 'client':
          const clientOperations = await ListOps({filters:{_client:item._id}});
          let clientItem = {
            ...item,
            operations : clientOperations.resultList
          }
          dispatch(saveClient(clientItem));
          navigate('/client/detail');
        break;
      case 'operation':
          dispatch(saveFilters({section:'user',data:{listType:'default'}}));    
          dispatch(saveOperation(item));
          navigate('/operation/detail');
        break;  
      case 'opForm':
          dispatch(saveFilters({section:'user',data:{listType:'default'}}));    
          dispatch(saveOperation(item));
          navigate('/operation/form');
        break;                               
      default:
    }    
  };

  const cloneMe = async(item) =>{
    if (window.confirm('Vous vous apprêtez à Dupliquer cette Opération')) {
      const cloneResults = await CloneOp(item._id);
      if (cloneResults && cloneResults._id) {
        openNotification('success',"Opération dupliquée");
      } else {
        openNotification('error',"Probléme lors de la duplication de l'opération");
      }
      
      dispatch(saveFilters({section:'user',data:{listType:'default'}}));    
      dispatch(saveOperation(cloneResults));
      navigate('/operation/detail');
    }
  };

  const onPaginationChange = (page,pageSize) => {  
    setSearchParams({ page: Number(page) });
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onLabelUpdated = (record) => {
    const updatedList = updateList(storedList,record); 
    setListItems(updatedList);                
    dispatch(saveLists({ section : section, data : updatedList}));
    return true;
  };

  const onPopOpBmgStatusChange = async (operation,props)=>{
    try {
      const itemUpdated = await EditOpProps(operation._id,props);
      if (itemUpdated && itemUpdated._id) {  
        const updatedList = updateList(storedList,itemUpdated); 
        setListItems(updatedList);      
        dispatch(saveLists({ section : section, data :updatedList}));
        openNotification('success','Modification Enregistrée');
      } else {
        openNotification('error','Probléme lors de la sauvegarde');
      }
    } catch (error) {
        console.error('Error onPopOpBmgStatusChange EditUserProps:', error);
        openNotification('error','Catch / Probléme lors de la sauvegarde');
    }     
};

  const sortList = async (field,direction) =>{
    setSortBy(field);
    setOrderBy(direction === 'ascend' ? 1 : -1);
  };
  
  const onEditTodo = (todoUpdated) =>{    
    if (todoUpdated && todoUpdated._id) {
      let eleToModified = storedList.find((ele)=>  ele._id === todoUpdated.targetId )
      if (eleToModified && eleToModified._id) {
        const eleModified = {...eleToModified, todo : todoUpdated};
        const updatedList = updateList(storedList,eleModified); 
        setListItems(updatedList);                
        dispatch(saveLists({ section : section, data : updatedList}));
      }
    }    
  };

  let columns = [
    { 
      key: "name", 
      title: "Nom", 
      dataIndex: "name", 
      sorter: (a, b,direction) => {
        sortList('name',direction);
      },
      fixed: 'left',
      width: '200px',
      render: (value, record) => {         
        return ( 
          <> 
            <div className="custom-name capitalize link" onClick={()=>linkMe(record,'operation')}>{value}</div> 
          </> 
          ); 
        },      
      }, 
      { 
        key: "_admin", 
        title: "Géré par" ,
        dataIndex: "_admin", 
        //sorter: {},
        // sorter: (a, b) => {          
        //   if (a && b) return a._admin.lastName.localeCompare(b._admin.lastName);
        // },
        render: (value, record) => { 
          return ( 
            <> 
              <div >{value && value._id ? capitalize(value.firstName) : '-'}</div> 
            </> 
          ); 
        },          
      },
      { 
        key: "_client", 
        title: "Client" ,
        dataIndex: "_client",
        //sorter: {}, 
        // sorter: (a, b,direction) => {
        //   sortList('_client',direction);
        // },
        render: (value, record) => { 
          return ( 
            <> 
              <div className="custom-name capitalize link" onClick={()=>linkMe(record._client,'client')}>{value ? value.name : '-'}</div> 
            </> 
          ); 
        },      
      },
      { 
        key: "type", 
        title: "Type" ,
        dataIndex: "type", 
        sorter: (a, b,direction) => {
          sortList('type',direction);
        },
        render: (value, record) => { 
          return ( 
            <> 
              <div className="capitalize">{value}</div> 
            </> 
            ); 
          },          
      },  
      { 
        key: "bmgStatus", 
        title: "Statut" ,
        dataIndex: "bmgStatus", 
        width: '120px',
        render: (value,record) => { 
          let opStatus = operationBmgStatus.find((ele)=>ele.value === value);
          //if (!opStatus) opStatus = opStatus[0];
          return ( 
          <> 
          <div>
            <PopOver 
                  content={
                      <span className="capitalize tag" style={{backgroundColor:opStatus ? opStatus.color : 'white'}}>{opStatus ? opStatus.label : ''}</span>
                  }              
                  prop="OpBmgStatus" 
                  handlerMethod={onPopOpBmgStatusChange}
                  items={operationBmgStatus}
                  user={record}
              />              
          </div>
 
          </> 
          ); 
        },
        sorter: (a, b,direction) => {
          sortList('bmgStatus',direction);
        },
      },
      { 
        key: "sdate", 
        title: "Début" ,
        dataIndex: "sdate", 
        sorter: (a, b,direction) => {
          sortList('sdate',direction);
        },
        render: (record) => { 
          let formatDate = 'DD/MM/YYYY HH:mm';
          if (moment(record).format('HH:mm') === '00:00') formatDate = 'DD/MM/YYYY';          
          return ( 
            <> 
              <div>{moment(record).format(formatDate)}</div> 
            </> 
          ); 
        },        
      },  
      { 
        key: "edate", 
        title: "Fin" ,
        dataIndex: "edate", 
        sorter: (a, b,direction) => {
          sortList('edate',direction);
        },
        render: (record) => {
          let formatDate = 'DD/MM/YYYY HH:mm';
          if (moment(record).format('HH:mm') === '00:00') formatDate = 'DD/MM/YYYY';
          return ( 
          <> 
            <div>{moment(record).format(formatDate)}</div> 
          </> 
          ); 
        },         
      },  
      { 
        key: "booked", 
        title: "Booké" ,
        dataIndex: "booked", 
        render: (value,record) => { 
          let nbBooked = 0;
          if (record && record.missions && record.missions.length) {
            const nbBookedMissions = record.missions.filter((ele)=> ele.status === 1);
            nbBooked = nbBookedMissions && nbBookedMissions.length ? nbBookedMissions.length : 0;
          }
          return ( 
          <> 
            <div>{nbBooked}</div> 
          </> 
          ); 
        },
        sorter: (a, b,direction) => {
          sortList('booked',direction);
        },
      },    
      { 
        key: "bookedTotal", 
        title: "Total" ,
        dataIndex: "bookedTotal", 
        sorter: (a, b,direction) => {
          sortList('bookedTotal',direction);
        },
      },   
      { 
        key: "label", 
        title: "Labels",
        dataIndex: "label", 
        render: (value,record) => { 
          return(
            <LabelForm 
              section={section}
              labels={value}
              record={record}
              handlerMethod={onLabelUpdated}
              storedLabelList={storedLabelList || []}
           />
          );
        },      
      },                    
      { 
        key: "action", 
        title: "Actions", 
        width: '160px',
        fixed: 'right',
        render: (record) => { 
          return ( 
            <> 
              <div className="flex"> 
                <PopOverForm
                  content={
                    <ToDoIcon section={section} record={record}/>
                  }
                  form="todo"
                  handlerMethod={onEditTodo}
                  data={record.todo}
                  target='operation'
                  targetData={record}
                />
                <EditOutlined 
                  title="Editer l'Opération"
                  className='icon-table'
                  onClick={()=>linkMe(record,'opForm')}
                /> 
                <CopyOutlined 
                  title="Dupliquer l'Opération"
                  className='icon-table'
                  onClick={()=>cloneMe(record)}
                />                                 
                <DeleteOutlined 
                  title="Supprimer l'Opération"
                  className='icon-table'
                  onClick={() => Delete(record)} 
                /> 
              </div> 
            </> 
          ); 
        }, 
      },                  
  ];
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
  columns = columns.map(col => ({
    ...col,
    fixed: isMobile ? undefined : col.fixed,
  }));
  return (
    <div className="container" >
      <HeaderBar       
        actionContent={
          <div className="header-actions" style={{ marginLeft:"16px"}}>
            <div onClick={()=>linkMe({},'opForm')} className="secondary-button" >+</div>
          </div>
        }
        hasFilters={true} 
        hasSort={false} 
        hasSearch={true} 
        section={section} 
        storedFilters={storedFilters} 
        setCurrentPage={setCurrentPage}
        clientList={clientList}
        />
      <div className="content">    
        <div>
          <Table 
            dataSource={listItems} 
            scrollToFirstRowOnChange={true}
            rowKey="_id"
            columns={columns} 
            pagination={{
              onChange:onPaginationChange,
              position:['bottomCenter'],
              current:actualPage,
              pageSize,
              total:totalPages,
              showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`
            }} 
            className="capitalize custom-checkbox custom-table"
            onChange={onTableChange} 
            scroll={{
              y: 620,
              x: 1300
            }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
        </div> 
      </div>
      {/* <Pagination
            showSizeChanger={true} 
            current={currentPage}
            pageSize={pageSize}                
            total={totalPages} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
            onChange={onPaginationChange}
            className="pagination-list"
          />       */}
    </div>
  );
};

export default OpList ;
