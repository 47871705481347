import React, { useState  } from 'react';
import moment from 'moment';


import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../hooks/NotificationProvider';
import { FilePdfOutlined, MenuFoldOutlined,MenuUnfoldOutlined} from '@ant-design/icons';

import { useSelector,useDispatch } from 'react-redux';

import { saveLists } from '../../../features/lists/listsSlice';
import { saveOperation } from '../../../features/operation/operationSlice';
import { EditOpProps } from '../../../services/Operation';
import { operationBmgStatus} from "../../../utils/constants"
import { capitalize,updateList } from "../../../utils/miscHelpers"
import LabelForm from "../../label/LabelForm"
import FileUpload from "../../elements/FileUpload"
import PdfModal from "../elements/PdfModal"
import PopOver from "../../../components/elements/PopOver"

const OperationGeneral =  ({section, bmgFilters, staffList, openGeneral, setOpenGeneral}) => {
    
    const { openNotification } = useNotification();
    const dispatch = useDispatch();
    const { t } = useTranslation();    

    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);
    const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
    const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);     

    const [isModalOpen, setIsModalOpen] = useState(false);    

    const onLabelUpdated = (record) => {
        const operationUpdated = {...storedOperation,...{label:record.label}};
        const updatedList = updateList(storedList,operationUpdated) ;
        dispatch(saveOperation(operationUpdated));
        dispatch(saveLists({section:section,data:updatedList}));
        return true;
    };
   
    const onPopOpBmgStatusChange = async (operation,props)=>{
        try {
          const itemUpdated = await EditOpProps(operation._id,props);
          if (itemUpdated && itemUpdated._id) {  
            dispatch(saveOperation(itemUpdated));
            dispatch(saveLists({ section : section, data : updateList(storedList,itemUpdated) }));
            openNotification('success','Modification Enregistrée');
          } else {
            openNotification('error','Probléme lors de la sauvegarde');
          }
        } catch (error) {
            console.error('Error onPopOpBmgStatusChange EditUserProps:', error);
            openNotification('error','Catch / Probléme lors de la sauvegarde');
        }     
    };

    const onCloseGeneral = () =>{
        setOpenGeneral(openGeneral ? false : true);
    };

    const showModal = () =>{
        setIsModalOpen(true);
    };

    const closeModal = async () =>{
        setIsModalOpen(false);
    };  
    
    const getBookedHost = (item) =>{
        const bookedStaff = [];
        if (item && item.missions) {
            for(const mission of item.missions){
                if (mission.status === 1) {
                    if (!bookedStaff.includes(mission._user)) bookedStaff.push(mission._user);
                }
            }
        }

        return bookedStaff.length;
    };

    const cardMetaDescription = (item) =>{
        
        if (!openGeneral) return ;

        let formatDate = 'DD/MM/YYYY à HH:mm';
        if (moment(item.sdate).format('HH:mm') === '00:00') formatDate = 'DD/MM/YYYY';          
        
        const bmgStatus = operationBmgStatus.find((ele)=>ele.value === item.bmgStatus)

        return (
            <>                  
                <div className="card-content" style={{ width: "250px"}}>
                    <div>
                        <div className='flex link' title="Fermer" onClick={(onCloseGeneral)}>
                            {openGeneral === true &&                            
                                <MenuFoldOutlined  style={{fontSize:'18px',marginRight:'10px',color:'var(--accent-color)'}}/>                             
                            }                            
                            <h2 className="capitalize">{item.name}</h2>                  
                        </div>
                        <div style={{textAlign:'center'}}>
                            <PopOver 
                                content={
                                    <span className="capitalize tag-large" style={{backgroundColor:bmgStatus ? bmgStatus.color : 'white'}}>{bmgStatus ? bmgStatus.label : ''}</span>
                                }              
                                prop="OpBmgStatus" 
                                handlerMethod={onPopOpBmgStatusChange}
                                items={operationBmgStatus}
                                user={storedOperation}
                            />                   
                        </div>
                      
                        <div onClick={showModal} className='flex link' title='Générer le fichier de présentation en PDF' style={{
                                marginTop:'16px',
                                marginBottom:'32px',
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                            <span className="">+ Créer la Présentation PDF</span>
                            <FilePdfOutlined style={{fontSize:'16px'}}/>
                        </div>                                             
                        <div>
                            <span className="h2">{t('operation.client')} : </span>
                            <span className="capitalize">{item._client ? capitalize(item._client.name) : '-'}</span>
                        </div>
                        <div>
                            <span className="h2">{t('operation.type')} : </span>
                            <span className="capitalize">{capitalize(item.type)}</span>
                        </div>
                        <div>
                            <span className="h2">{t('operation.admin')} : </span>
                            <span className='capitalize'>{item._admin ? capitalize(item._admin.firstName) : '-'}</span>
                        </div>                              
                        <br/>
                        <div>
                            <span className="h2">{t('operation.sdate')} : </span>
                            <span className="capitalize">{moment(item.sdate).format(formatDate)}</span>
                        </div>    
                        <div>
                            <span className="h2">{t('operation.edate')} : </span>
                            <span className="capitalize">{moment(item.edate).format(formatDate)}</span>
                        </div>
                        <div>
                            <span className="h2">{t('operation.languages')} : </span>
                            <span className="uppercase">{item.languages  && item.languages.length ? item.languages.join(', ') : '-'}</span>
                        </div>                        
                        <br/>
                        <div>
                            <span className="h2">{t('operation.booked')} : </span>
                            <span className="capitalize">{getBookedHost(item)} / {item.bookedTotal}</span>
                        </div>                                                                     
                        <div style={{ marginTop:'10px', paddingBottom:"16px",textAlign: "right"}}>
                            <LabelForm 
                                section={section}
                                labels={item.label}
                                record={item}
                                handlerMethod={onLabelUpdated}
                                storedLabelList={storedLabelList || []}                
                            />
                       </div>                                  
                    </div>                    
                    <div style={{paddingTop: "12px"}}>
                        <FileUpload
                            section={section}
                            record={storedOperation}
                        />
                    </div>
                </div>
          </>
        );
      }
    return(
        <div className={'user-general '+ (openGeneral ? 'unfolded' : 'folded')} >
            <div onClick={(onCloseGeneral)} title="Ouvrir" className='link'>
                {openGeneral !== true &&
                    <div style={{marginLeft:'10px',marginTop:'3px',position:'absolute',}}>                        
                        <MenuUnfoldOutlined className='link' style={{marginRight:'12px',color:'var(--accent-color)'}}/> 
                        <span className="h4s capitalize">{storedOperation.name}</span>
                    </div>
                    
                }                
            </div>
            {cardMetaDescription(storedOperation)}
            <PdfModal
                section={section}
                staffList={staffList}
                storedOperation={storedOperation}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
            />     
        </div>
    )
};

export default OperationGeneral;
