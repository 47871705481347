import React, { useState} from 'react';
import {  Input,} from 'antd';
import { useNotification } from '../../../hooks/NotificationProvider';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { saveOperation } from '../../../features/operation/operationSlice';
import OperationGeneral from "./OperationGeneral"
import {EditOpProps} from '../../../services/Operation'
import QuoteDetail from '../elements/QuoteDetail'

const { TextArea } = Input;

const PresentationTab =  ({section, bmgFilters,  staffList, openGeneral, setOpenGeneral}) => { 

    const dispatch = useDispatch();
    const { openNotification } = useNotification();
    const { t } = useTranslation();    
    
    const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
    const [notes, setNotes] = useState(storedOperation.notes);

    const onChangeNotes = (e) => {       
        setNotes(e.target.value);        
    };

    const saveNotes = async () => {  
        if (notes !== undefined) {
            const entrytUpdated = await EditOpProps(storedOperation, { notes : notes});
            if (entrytUpdated && entrytUpdated._id) {                
                dispatch(saveOperation(entrytUpdated));
                openNotification('success','Modification Enregistrée');
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
            }
        }
    };

    return(
        <div className="tab-content">
            <OperationGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
                staffList={staffList}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')}>
                <div className='presentation-block'>
                    <div className='flex' style={{
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width:'100%'                   
                    }}>
                        <span className="h2 capitalize">Devis</span>
                        <QuoteDetail
                            name={storedOperation.name}
                            missionSlots={storedOperation.missionSlots}
                            quote={storedOperation.quote}
                            quoteFees={storedOperation.quoteFees}
                            quoteVat={storedOperation.quoteVat}
                            quoteCurrency={storedOperation.quoteCurrency}
                            quoteHeader={storedOperation.quoteHeader}
                            quoteFooter={storedOperation.quoteFooter}
                            quoteExpire={storedOperation.quoteExpire}
                        />
                    </div>
                    <div className='presentation-block-inner'>
                        <div className="note-header h2" style={{ marginTop: 0}}>
                            <span className="h2 capitalize">{t('user.notes')}</span>
                            <span onClick={saveNotes} className="icon-save link" title="Enregistrer"><CheckCircleOutlined/></span>
                        </div>                         
                        <TextArea 
                            value={notes}
                            onChange={onChangeNotes}
                            className="bmg-textarea" 
                            rows={25} 
                            placeholder={t('user.notes')} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PresentationTab;