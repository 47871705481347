import { Post, ApiRoutes } from "./Api"


export const ListAgenda = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.agendaList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
        console.error('Agenda resultList',err);
    }
};
