import React, { useState } from 'react';
import dayjs from 'dayjs';
//import { DashboardOutlined,MailOutlined,TeamOutlined, BarsOutlined, SettingOutlined, FieldTimeOutlined, UserOutlined, PoweroffOutlined, ApartmentOutlined, CarryOutOutlined } from '@ant-design/icons';
//import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import {  DownOutlined,UpOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

import { operationBmgStatus } from "../../../utils/constants"
import { doS, capitalize } from "../../../utils/miscHelpers"

import { saveFilters } from '../../../features/filters/filtersSlice';
import { saveOperation } from '../../../features/operation/operationSlice';
import { GetOp } from '../../../services/Operation'

import ClientGeneral from "./ClientGeneral"
import QuoteDetail from "../../../components/operation/elements/QuoteDetail"

const OperationTab = ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 

    // const { t } = useTranslation();   
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const storedClient = useSelector(state => state.client.value && state.client.value ? state.client.value  : {});
    
    const [toogledOps, setToogledOps] = useState([]);

    const toogleOperation = (opId)=>{
        let newToogledOps = [...toogledOps];
        if (newToogledOps.includes(opId)) {
            newToogledOps = newToogledOps.filter((ele)=>ele !== opId)
        } else {
            newToogledOps.push(opId);            
        }
        setToogledOps(newToogledOps);        
    };

    const linkMe = async (opId,section)=>{    
        switch(section){
          case 'operation':
              const operation = await GetOp(opId);
              dispatch(saveFilters({section:'user',data:{listType:'default'}}));    
              dispatch(saveOperation(operation));
              navigate('/operation/detail');
            break;                                    
          default:
        }    
    };

    return(
        <div className="tab-content">
            <ClientGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')} style={{paddingLeft:'16px',paddingRight:'16px',paddingTop:'26px',marginRight:'6px'}}>
                <div style={{marginTop:'16px',height:'70vh',overflow:'auto'}}>
                    {(!storedClient.operations || !storedClient.operations.length) &&
                        <div>Aucune Opération</div>
                    }         
                    {storedClient && storedClient.operations  && storedClient.operations.length  && storedClient.operations.map((operation,oidx)=>{
                        let opStatus = operationBmgStatus.find((ele)=>ele.value === operation.bmgStatus);                        
                        if (!opStatus) opStatus = {label:'-',value:0};
                        return (
                            <div key={'op-'+oidx}  style={{padding:'16px',textAlign:'left',borderBottom:'solid 1px var(--accent-thin-color)'}}>
                                <div className='flex font-medium flex-column'>
                                    <div className='flex'>
                                        <div onClick={()=>linkMe(operation._id,'operation')} style={{width:'250px'}} className='link h2'>{capitalize(operation.name)}</div>
                                        <div style={{width:'200px'}} >Du {dayjs(operation.sdate).format('DD MMM')} au {dayjs(operation.edate).format('DD MMM YYYY')}</div>
                                        <div style={{width:'75px',marginLeft:'16px'}} >{operation.missions ? operation.missions.length : 0} Mission{doS(operation.missions)}</div>
                                        <div style={{width:'75px',marginLeft:'32px'}} className={opStatus.value === 1 ? 'h2':'h2g'}>{opStatus.label}</div>
                                    </div>
                                    <div className='link' style={{cursor:'pointer', marginLeft:'16px',width:'20px'}} onClick={()=>toogleOperation(operation._id)}>
                                        {toogledOps.includes(operation._id) &&
                                            <UpOutlined style={{color:'var(--accent-color) !important'}}/>
                                        }
                                        {!toogledOps.includes(operation._id) &&
                                            <DownOutlined style={{color:'var(--accent-color) !important'}}/>                                            
                                        }                                    
                                    </div>                                  
                                </div>
                                
                                <div style={{marginTop:'16px',display:toogledOps.includes(operation._id) ? '' : 'none'}}>
                                    <QuoteDetail
                                        name={operation.name}
                                        missionSlots={operation.missionSlots}
                                        quote={operation.quote}
                                        quoteFees={operation.quoteFees}
                                        quoteVat={operation.quoteVat}
                                        quoteCurrency={operation.quoteCurrency}
                                        quoteHeader={operation.quoteHeader}
                                        quoteFooter={operation.quoteFooter}
                                        quoteExpire={operation.quoteExpire}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
};

export default OperationTab;