import React, { useState, useEffect } from 'react';
import {Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SendOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { SendMessage } from "../../services/Message"
import { useNotification } from '../../hooks/NotificationProvider';
import { saveMessage } from '../../features/message/messageSlice'; 
import { capitalize , formatUserListWithValueLabel} from "../../utils/miscHelpers"

const { TextArea } = Input;

const closeAfterSendMessage = 1;

const MessageForm = ({handlerMethod, messageUsers , defaultMessage='', formSize='',checkedData}) => {

    const { openNotification } = useNotification();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //const storedMessage = useSelector(state => state.message.value && state.message.value.user ? state.message.value : {});
    const storedMessage = useSelector(state => state.message.value ? state.message.value : {});
    const storedUserList = useSelector(state => state.constants.value && state.constants.value ? state.constants.value : []);
    const storedSelectedUsers   = useSelector(state => state.message.value && state.message.value.users ? state.message.value.users : null);

    const userFormatedList = formatUserListWithValueLabel(storedUserList.user);

    const [message, setMessage] = useState(defaultMessage);
    const [userSelectedList, setUserSelectedList] = useState(messageUsers ? messageUsers : (storedSelectedUsers ? storedSelectedUsers : null));
    
    useEffect(() => {   
        if (storedSelectedUsers && storedSelectedUsers !== null) {setUserSelectedList(storedSelectedUsers);}
        if (messageUsers && messageUsers.length) setUserSelectedList(messageUsers);

        // setMessage('');  
     }, [storedSelectedUsers,messageUsers]);

    const onChange = (e) => {
        setMessage(e.target.value);
    };

    const msgOk = (user) => {
        return 'Message envoyé à '+ capitalize(user.firstName) +' '+ capitalize(user.lastName) ;
    };
    
    const onSend = async ()=>{        

        if (checkedData) {                
            if (checkedData.selectedSlots && checkedData.selectedSlots.length === 0 && checkedData.selectedDemand && checkedData.selectedDemand !== 10) return openNotification('error',"Aucune tranche horaire séléctionnée");
            if (checkedData.selectedDemand && checkedData.selectedDemand === null) return openNotification('error',"Aucun Type de Demande séléctionné");
        }

        // Check Data
        if ((!storedMessage || !storedMessage.user || !storedMessage.user._id) && !userSelectedList.length) {
            openNotification('error',"Probleme lors de l'envoi - Aucun utilisateur");
            return;
        }
        
        const cleanMsg = message ? message : defaultMessage;
        if (!cleanMsg || cleanMsg === '') return openNotification('error',"Aucun Message");        
        
        // Send to 1 User
        if (storedMessage && storedMessage.user && storedMessage.user._id) {
            const msgResult = await SendMessage({ _user : storedMessage.user._id , body : cleanMsg });
            if (msgResult) {
                let newList = [...storedMessage.list];
                const user = {...storedMessage.user};
                newList.unshift(msgResult);
                dispatch(saveMessage({user:user,list:newList,users:[]}));
                openNotification('success', msgOk(storedMessage.user) );
                setMessage('');
                setUserSelectedList([]);
                if (messageUsers && handlerMethod) handlerMethod(false);
            } else {
                openNotification('error',"Probléme lors de l'envoi");
            }
        } 
        
        // Send to multiple Users (2 possible obj format : with value or directly as ID)
        else {
            for(const userSelected of userSelectedList) {
                const userId = userSelected.value ? userSelected.value : (userSelected._id ? userSelected._id : userSelected) ;
                let userData = storedUserList.user.find((user)=>{return user._id === userId});
                if (messageUsers) userData = messageUsers.find((user)=>{return user._id === userId});
                if (userData && userData._id) {
                    const msgResult = await SendMessage({ _user : userId , body : cleanMsg });
                    if (msgResult) {
                        openNotification('success', msgOk(userData) );
                    } else {
                        openNotification('error',"Probléme lors de l'envoi");
                    }
                } else {
                    openNotification('error',"Probléme lors de l'envoi à un utilisateur. Utilisateur " + userId + ' introuvable : ' + JSON.stringify(userData) );
                }
            }
            setMessage('');
            setUserSelectedList([]);
            if (closeAfterSendMessage && handlerMethod) handlerMethod(false);
        }
    };    
    
    return (
        <div className={'message-form '+formSize}>
            {(!storedMessage || !storedMessage.user) && !messageUsers && 
                <div>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '450px',
                            marginBottom:'16px'
                        }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        placeholder="Choisir les destinataires"
                        //defaultValue={}
                        value={userSelectedList}
                        onChange={setUserSelectedList}
                        options={userFormatedList}
                        className="filter-select user-select"
                    />
                </div>         
            }
            <TextArea
                value={message || defaultMessage}
                onChange={onChange}
                className="bmg-textarea" 
                rows={6} 
                placeholder={t('message.default')} 
            />
            {((storedMessage && storedMessage.user) || (messageUsers && messageUsers.length > 0) || (userSelectedList && userSelectedList.length > 0)) &&
                <div className='button button-with-accent button-tiny'  onClick={onSend} style={{marginTop:'16px'}}>                
                    <span>{t('buttons.send')}</span>
                    <SendOutlined style={{marginLeft:'16px'}}/>
                </div>
            }
         </div>
    );
};
export default MessageForm;