import React  from 'react';
import moment from 'moment';
import {  Divider} from 'antd';

//import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useNotification } from '../../../hooks/NotificationProvider';
import { useSelector } from 'react-redux';
import ClientGeneral from "./ClientGeneral"
import { MissionQuoteClient } from '../../../services/Client';

import { capitalize } from '../../../utils/miscHelpers'

const QuoteTab =  ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 

    // const { t } = useTranslation();    
    const { openNotification } = useNotification();
    //const dispatch = useDispatch();
    const navigate = useNavigate();

    const storedClient = useSelector(state => state.client.value && state.client.value ? state.client.value  : {});
    
    const doMission = async (quoteData) =>{
        const quote = {...quoteData,_client : storedClient._id}
        const returnMissionQuote = await MissionQuoteClient(quote);
        if (returnMissionQuote && returnMissionQuote._id) {
            openNotification('success',"Nouvelle Opération sauvegardée");     
            // dispatch(saveFilters({section:'user',data:{listType:'default'}}));    
            // dispatch(saveOperation(returnMissionQuote));
            navigate('/operation');
        } else{
            openNotification('error',"Erreur lors de la création de l'opération");     
        }                
    };
    
    return(
        <div className="tab-content">
            <ClientGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />         
            <div className='tab-block'>
                <div className="card-content" style={{ marginLeft: "0", width: "96%",marginTop:'33px'}}>
                    {(storedClient && storedClient.quotes) && 
                        storedClient.quotes.map((quote,index)=>{
                            return(
                                <div key={index.toString()}>                                    
                                    <div style={{marginTop:"20px",display:"flex",alignItems:'center',justifyContent: "space-between",flexDirection: "row"}}>
                                        <span style={{fontSize:'14px',color:'var(--accent-color)'}}>{capitalize(quote.name)}</span>                                        
                                    </div>  
                                    <Divider className="divider-full"/>
                                    <div style={{display:"flex",alignItems:'center',justifyContent: "space-between",flexDirection: "row"}}>
                                        <span style={{fontWeight:'bold'}}>Type d'événement : {quote.eventType}</span>
                                    </div>
                                    <div style={{display:"flex",alignItems:'center',justifyContent: "space-between",flexDirection: "row"}}>
                                        <span style={{fontWeight:'bold'}}>Rôle : {quote.eventRole}</span>
                                    </div>
                                    <div style={{display:"flex",alignItems:'center',justifyContent: "space-between",flexDirection: "row"}}>
                                        <div>
                                            <span style={{fontWeight:'bold'}}>Date de l'événement : {moment(quote.eventDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                                        </div>
                                        <div style={{textAlign:'right'}}>                                            
                                            <br/>
                                            <span className=''>{quote.days} jours de {quote.startTime}h à {quote.endTime}h</span>
                                        </div>                                                                           
                                    </div>                                        
                                    <div style={{display:"flex",alignItems:'flex-start',justifyContent: "space-between",flexDirection: "row"}}>
                                        <div>
                                            <span style={{marginTop:"16px",marginBottom:"16px"}}>{quote.totalWomen} Hôtesses / {quote.totalMen} Hôtes</span>
                                            <br/>
                                            <span style={{fontWeight:'bold'}}>Reçu le {moment(quote.cdate).format('DD/MM/YYYY à HH:mm')}</span>                                            
                                        </div>
                                        <div style={{textAlign:'right'}}>
                                            <span className='capitalize'>Langues / {(quote.languages.join(', ').toUpperCase())}</span>                        
                                        </div>                                                                           
                                    </div>                                                               
                                    <div>  
                                        <span className=''>{capitalize(quote.body)}</span>
                                    </div>
                                    {quote.status === 1 &&
                                        <div style={{display:'flex', flexDirection: 'row',justifyContent: 'flex-end',color: "var(--accent-color)"}}>  
                                            <div className='button button-with-accent button-tiny' onClick={()=>doMission(quote)}>Convertir en Mission</div>
                                        </div>    
                                    }                                
                                    <Divider className="divider-full"/>                                     
                                </div>
                            )
                        })
                    }                    
                </div>          
            </div>
        </div>
        
    )
};

export default QuoteTab;