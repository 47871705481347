import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    agenda: {},
    client: {},
    dashboard: {},
    joboffer: {},
    message: {},
    mission: {},
    operation: {},
    setting: {},
    todo: {},
    user: {}
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: initialState,
  reducers: {
    getFilters: (state) => {
      return state.value 
    },
    saveFilters: (state, action) => {      
      if (action.payload && action.payload.section) {
        if (!state.value) state.value = {}; 
        if (!state.value[action.payload.section]) state.value[action.payload.section] = {};
        state.value[action.payload.section] = action.payload.data;
      }
    },
    resetFilters: (state, action) => {
      state.value = initialState;
    },
  },
})

// Action creators are generated for each case reducer function
export const { getFilters, saveFilters, resetFilters } = filtersSlice.actions

export default filtersSlice.reducer