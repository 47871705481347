import React, { useState,useEffect } from 'react';
import OperationGeneral from "./OperationGeneral"
import { SlotBmgStatus } from "../../../utils/constants"
import { prepareDates, capitalize, CheckMissionSlotStaffStatus, FormatMissionSlotsByRoles, calculateHoursDifference} from '../../../utils/miscHelpers'

import GanttBlock from "../../../components/elements/GanttBlock";
import GanttToolTip from "../../../components/elements/GanttToolTip";

const NameDetails = ({ title,icon=null,annexe=null }) => {
    return (
      <div>
        <div className='flex flex-column-start'>
            {icon && 
                <img alt="user" src={icon ? icon : '/img/placeholder-avatar.jpg'} className="avatar-smaller" style={{marginRight:'6px'}}/>
            }
            <div className='flex flex-column-start' style={{alignItems:'flex-start',flexDirection:'column'}}>
                <span className='bold' style={{ lineHeight: '13px'}}>{title}</span>            
                {annexe &&
                    <span  style={{ lineHeight: '13px'}}>{annexe}</span>            
                }   
            </div>         
        </div>
      </div>
    );
};

const TimelineTab =  ({section, bmgFilters,storedOperation,staffList, openGeneral, setOpenGeneral}) => {      
    
    //const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});

    const [selectType, setSelectType] = useState('staff');
    // const [scaleCss, setScaleCss] = useState('col-large');
    const [roles, setRoles] = useState(FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : []));
    const [tasks, setTasks] = useState([]);

    const accentColor = 'var(--accent-color)';
    const defaultColor = '#433F3A'; // BMG BLACK secondary-color    
    const noStatusColor = 'white';// '#433F3A'; // BMG BLACK secondary-color    

    const getDuration = (missionSlot) =>{
        const stime = missionSlot.stime ? missionSlot.stime : missionSlot.timeSlot.stime;
        const etime = missionSlot.etime ? missionSlot.etime : missionSlot.timeSlot.etime;
        return calculateHoursDifference(stime,etime);
    };

    const doData = () =>{        

        let data = null;

        switch(selectType){

            case 'role' :
                data = [];                
                for(const roleKey in roles){
                    if (roleKey && roles[roleKey]){
                        const role = roles[roleKey];
                        
                        if (role.missionSlots && role.missionSlots.length) {
                            for(const missionSlot of role.missionSlots){

                                const date = prepareDates(missionSlot);
                                if (isNaN(date.startTime) || isNaN(date.endTime)) {
                                    console.error('Invalid date format', date);
                                } else {
                                    //const duration = getDuration(missionSlot);
                                    data.push({
                                        start: date.startTime,
                                        end: date.endTime,
                                        name: capitalize(missionSlot.role?.name),
                                        id: missionSlot._id,
                                        // type: 'task',
                                        // progress: 100,
                                        // duration:duration.cleanTotalHrs,
                                        // isDisabled: false,
                                        styles: { 
                                            backgroundSelectedColor:defaultColor, 
                                            backgroundColor:defaultColor, 
                                            progressColor: defaultColor, 
                                            progressSelectedColor: defaultColor 
                                        },
                                        // staffList: staffList ? staffList : [],
                                        // storedOperation:storedOperation,
                                        // missionSlot:missionSlot
                                    });
                                }

                            }
                        }
                    }
                }
                break;

            case 'staff' :                
                data = [];
                if (storedOperation && storedOperation.missions && storedOperation.missions.length) {
                    let idx = 0;
                    for(const mission of storedOperation.missions){
                        
                        const staff = staffList.find((ele)=> ele._id === mission._user);
                        if(!staff || !staff._id) continue;
                        
                        const staffName = capitalize(staff?.firstName) + ' ' + capitalize(staff?.lastName);
                        
                        if (mission.slots && mission.slots.length) {
                            for(const missionSlot of mission.slots){
                                const date = prepareDates(missionSlot);
                                if (isNaN(date.startTime) || isNaN(date.endTime)) {
                                    console.error('Invalid date format', date);
                                } else {
                                    const duration = getDuration(missionSlot);
                                    const staffSlotStatus = CheckMissionSlotStaffStatus(missionSlot, storedOperation,staff._id);
                                    //console.log('staffSlotStatus',staffSlotStatus,mission.status,missionSlot.status);
                                    data.push({
                                        start: date.startTime,
                                        end: date.endTime,
                                        name: <NameDetails icon={staff.avatar} title={staffName} annexe={missionSlot.role} />,                                        
                                        //id: missionSlot._missionSlot+'-'+idx,
                                        ref:mission._id,
                                        body: <GanttToolTip duration={duration.cleanTotalHrs} staffName={staffName} missionSlot={missionSlot} />,
                                        id: missionSlot._missionSlot+'-'+idx,
                                        // type: 'task',
                                        // progress: 100,
                                        // isDisabled: false,
                                        staffSlotStatus:staffSlotStatus,
                                        styles: { 
                                            backgroundSelectedColor:SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : noStatusColor, 
                                            backgroundColor:SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : noStatusColor, 
                                            progressColor:  SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : noStatusColor, 
                                            progressSelectedColor: staffSlotStatus && SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : noStatusColor, 
                                        },
                                        duration:duration.cleanTotalHrs,
                                        // staffList: staffList ? staffList : [],
                                        // storedOperation:storedOperation,
                                        // missionSlot:missionSlot
                                    });
                                    idx++;
                                }

                            }
                        }
                    }
                }
                break;      

            case 'missionSlot' :                
                data = [];
                if (storedOperation && storedOperation.missionSlots && storedOperation.missionSlots.length) {
                    for (const missionSlot of storedOperation.missionSlots) {      

                        const date = prepareDates(missionSlot);

                        if (isNaN(date.startTime) || isNaN(date.endTime)) {
                            console.error('Invalid date format', date);
                        } else {
                            const duration = getDuration(missionSlot);
                            data.push({
                                start: date.startTime,
                                end: date.endTime,
                                name: capitalize(missionSlot.role?.name),
                                id: missionSlot._id,
                                // type: 'task',
                                // progress: 100,
                                // isDisabled: false,
                                styles: { 
                                    backgroundSelectedColor:accentColor, 
                                    backgroundColor:accentColor, 
                                    progressColor: accentColor, 
                                    progressSelectedColor: accentColor 
                                },
                                duration:duration.cleanTotalHrs,
                                staffList: staffList ? staffList : [],
                                storedOperation:storedOperation,
                                missionSlot:missionSlot
                            });
                        }
                    }
                }
                break;                            
            default:
        }        


        return setTasks(data);        
    };

    useEffect(() => {
        const doRoles = ()=>{
            setRoles(FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : []))
        };
        doRoles();
        doData();
    }, [storedOperation]);

    useEffect(() => {
        const doRoles = ()=>{
            setRoles(FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : []))
        };
        doRoles();
        doData();
    }, [selectType]);


    const onSelectType = (val) =>{
        setSelectType(val);
        // if  (val === 'staff') {
        //     setScaleCss('col-large');
        // } else {
        //     setScaleCss('');
        // }        
    };
        
    return(
        <div className="tab-content" style={{height:"100%"}}>
            <OperationGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
                staffList={staffList}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')} style={{overflow:'auto',paddingLeft:'16px',paddingRight:'16px',paddingTop:'26px',marginRight:'6px'}}>
                <GanttBlock 
                    section={section}
                    events={tasks}
                    selectType={selectType}
                    onSelectType={onSelectType}
                    displayTypeSelector={true}
                    />
                {/* <GanttChart
                    section={section}
                    data={tasks}
                    selectType={selectType}
                    scaleCss={scaleCss}
                    onSelectType={onSelectType}
                /> */}
            </div>
        </div>
    )
};

export default TimelineTab;