import React,{ useState } from "react";
import dayjs from "dayjs";

import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { saveOperation } from '../../../features/operation/operationSlice';
import { saveFilters } from '../../../features/filters/filtersSlice';
import { TeamOutlined } from '@ant-design/icons';
import { getAddress } from "../../../utils/miscHelpers"
import {  Popover } from 'antd';

import PopOverForm from "../../../components/elements/PopOverForm";
import { Priorities,operationBmgStatus } from "../../../utils/constants"
import ToDoIcon from "../../../components/todo/ToDoIcon";

const EventContent = ({section,item,index, setTmpTodoList}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openPops,setOpenPops] = useState([]);

    const eventClick = (event) => {
        //console.log('event',event)
    };
      
    const onEditTodo = (recordList) =>{
        setTmpTodoList(recordList);
    };

    const tooglePops = (opId, slotId)=>{
        let newToogledOps = [...openPops];
        const key = opId+'-'+slotId;
        if (newToogledOps.includes(key)) {
            newToogledOps = newToogledOps.filter((ele)=>ele !== key)
        } else {
            newToogledOps.push(key);            
        }
        // console.log('tooglePops :>> ', key, openPops);
        setOpenPops(newToogledOps);        
    };

    const linkMe = (item) =>{
        tooglePops(item.data._id, item.attributes?._id);
        dispatch(saveOperation(item.data));
        dispatch(saveFilters({section:'user',data:{listType:'default'}}));        
        navigate('/operation/detail',{state:{activeKey:'5'}});
    };

    const PopContent = ({item,bookedStatus}) =>{
        //const status = operationBmgStatus.find((el)=>el.value === item.data?.bmgStatus);
        return(
            <div className="flex" style={{flexDirection:'column'}}  title='Voir l'opération>
            <div className="flex flex-column-start">
                <TeamOutlined style={{marginRight:'6px',fontSize:'16px',color:bookedStatus ? 'var(--green-color)' : 'var(--orange-color)'}}/>
                {/* <div className='circle-small' style={{marginRight:'6px',backgroundColor:status?.color,}} /> */}
                <span className="h2" style={{color:bookedStatus ? 'var(--green-color)' : 'var(--orange-color)'}}>{item.name}</span>
            </div>
            <span className="bold capitalize-full">{item.attributes?.role.name} - {dayjs(item.attributes?.eday).format('DD MMM 2024')}</span>        
            <span className="">De {item.attributes?.timeSlot?.stime} à {item.attributes?.timeSlot?.etime}</span>
            <span>{getAddress(item.attributes.location,'direct')}</span>
            {/* <span>({status?.label})</span> */}
            <div style={{marginTop:'16px',backgroundColor:bookedStatus ? 'var(--green-color)' : 'var(--orange-color)'}} className="link tag tag-small" onClick={()=>linkMe(item)}>Voir</div>
            </div>
        );
    };

    const isBookedOperationForDay = (cellData) => {

        let bookedNb = 0;
        let workForceNb = 0;
        const missionSlot = cellData.data?.missionSlots.find((ele)=>ele.eday === cellData.date)
        if (missionSlot && missionSlot.workForce) {
            workForceNb = missionSlot.workForce;
        }
        if (cellData.data && cellData.data.missions && cellData.data.missions.length) {
            for(const mission of cellData.data.missions){
                if (mission && mission.slots) {
                    for(const slot of mission.slots){
                        if (slot.eday === cellData.date && slot.status === 1) {
                            bookedNb++;
                        }
                    }
                }
            }
        }
        return (bookedNb >= workForceNb);
    };

    switch(item.type){
        case 'todo':
            const priority = Priorities.find((el)=>el.value === item.data?.priority);
            return (
                <PopOverForm
                content={
                    <li key={index} className="font-small link flex" style={{alignItems: 'flex-start', marginBottom:'6px',flexDirection:'row'}} onClick={()=>eventClick(item)} title={'Tâche : Priorité '+priority?.label}>
                        <ToDoIcon record={item.data} section={section}/>
                        {/* <div className="triangle rotate-90" style={{marginRight:'6px',borderBottomColor:priority?.color,}} /> */}
                        <div className='flex' >
                        <span className="capitalize-full bold flex" title={priority?.label}>{item.name} {item.targetName ? ' - '+item.targetName :''}</span> 
                        </div>
                    </li>
                }
                key={'todo-'+index}
                form="todo"
                handlerMethod={onEditTodo}
                data={item.data}
                target={item.data.target}
                targetData={item.data.targetData}
                />
                
            );
        case 'operation':

            //const status = operationBmgStatus.find((el)=>el.value === item.data?.bmgStatus);
            const bookedStatus = isBookedOperationForDay(item);
            
            return (

                <Popover    
                    key={'op-'+index}        
                    content={<PopContent item={item} bookedStatus={bookedStatus}/>}
                    trigger="click"
                    open={openPops.includes(item.data._id+'-'+item.attributes?._id)}
                    className="bmg-popover"
                    onOpenChange={()=>tooglePops(item.data._id, item.attributes?._id)}
                >
                    <li key={index} className="font-small link flex" style={{marginBottom:'6px',flexDirection:'row'}} onClick={()=>tooglePops(item.data._id, item.attributes?._id)} title={item.name}>
                        <TeamOutlined style={{alignItems: 'flex-start', marginRight:'6px',fontSize:'16px',color:bookedStatus ? 'var(--green-color)' : 'var(--orange-color)'}}/>
                        {/* <div className='circle-small' style={{marginRight:'6px',backgroundColor:status?.color,}} /> */}
                        <div style={{textAlign:"left"}}>
                            <span className="capitalize-full bold flex">{item.name}</span>
                            <span>{item.attributes?.role.name} - {item.attributes?.timeSlot?.stime} à {item.attributes?.timeSlot?.etime}</span>                
                        </div>
                    </li>
                </Popover>

            );
        default:
            return false;
    };

};

export default EventContent;