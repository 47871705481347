import dayjs from 'dayjs';
import React, { useState,useEffect } from 'react';
//import { useSelector,useDispatch } from 'react-redux';
import { Input,Checkbox, DatePicker,TimePicker } from 'antd';
//import { useTranslation } from 'react-i18next';

import { useNotification } from '../../../hooks/NotificationProvider';
//import { use } from 'i18next';

//const { TextArea } = Input;
//const section = 'todo';

const MissionSlotForm = ({data, open, setOpen, handlerMethod}) => {

    // const statuses = [
    //     {label:'Fait',value:1},
    //     {label:'A faire',value:2}
    // ];  

    const { openNotification } = useNotification();
    const defaultTimeSlot = {key:0,stime:null,etime:null};
    //const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

    const [eday, setEday] = useState(data && data.eday ? dayjs(data.eday).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'));
    const [timeSlot, setTimeSlot] = useState(data && data.timeSlot ? data.timeSlot : defaultTimeSlot);    
    const [location,setLocation] = useState(data.location);
    const [applyLocationToAll,setApplyLocationToAll] = useState(false);

    const margin = '8px';

    useEffect(() => {
        if (open) {
            setApplyLocationToAll(false);
        }
    }, [open]);   

    useEffect(() => {
        const fetchData = async () => {
            try {    
                setApplyLocationToAll(false);                
                setLocation(data.location);
                setEday(data && data.eday ? dayjs(data.eday).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'));    
                setTimeSlot(data && data.timeSlot ? data.timeSlot : defaultTimeSlot);     
              return;
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          fetchData();        
    }, [data]);   
    
    const onCancel = ()=>{
        resetSets();
        setOpen(false);
    };

    const resetSets = ()=>{        
        setApplyLocationToAll(false);
        setEday(data.eday);
        setTimeSlot(data.timeSlot);
        setLocation(data.location);
    };
    
    const onSubmit = async ()=>{

        if (!eday || eday === '') {
            openNotification('error',"Le champs Date est obligatoire");     
            return;
        }
        
        if (!timeSlot.stime || !timeSlot.etime) {
            openNotification('error',"Les Horaires sont obligatoires");     
            return;
        }

        const edayFormated = dayjs(eday,'YYYY-MM-DD').format('YYYY-MM-DD');    
        const edayGrp = [...data.edayGrp];
        if (!edayGrp.includes(edayFormated)) {edayGrp.push(edayFormated)};

        const props = {      
            ...data,  
            ...{
                eday:edayFormated,
                timeSlot,
                edayGrp,
                changed:true,
            },
            location:location,      
        };
     
        // Return here
        handlerMethod({data:props, applyLocationToAll:applyLocationToAll});
        
        setOpen(false);

        // Clean here
        resetSets();

    };

    const changeTimeSlot = (e,field) => {
        let newTimeSlot = {...timeSlot};
        newTimeSlot[field] = dayjs(e).format('HH:mm');   
        setTimeSlot(newTimeSlot);      
    };

    const changeLocation = (e,field) =>{
        let newLocation = {...location};
        newLocation[field] = e.target.value;
        setLocation(newLocation);      
    };

    const toogleCheckbox = () =>{
        setApplyLocationToAll(prev => !prev);
    };
    
    return (
        <div >            
            <div className='h2' style={{marginBottom:'16px'}}>Lieu</div>            
            <div style={{width:'250px'}}>
                <div >
                    <span className="label">Nom du Lieu</span>   
                    <Input 
                        title="Name"                                            
                        value={location.name}
                        id="name"
                        name="name"
                        placeholder="Nom du Lieu" 
                        className=""
                        onChange={(e)=>changeLocation(e,'name')}
                    />
                </div>                
                <div >
                    <span className="label">Adresse</span>   
                    <Input 
                        title="Adresse"                                            
                        value={location.address}
                        id="address"
                        name="address"
                        placeholder="Adresse" 
                        className=""
                        onChange={(e)=>changeLocation(e,'address')}
                    />
                </div>
                <div style={{marginTop:'6px'}}>
                    <span className="label">Code Postal</span>                   
                    <Input 
                        title="Code Postal"                                            
                        value={location.zipcode}
                        id="zipcode"
                        name="zipcode"
                        placeholder="Code Postal" 
                        className=""
                        onChange={(e)=>changeLocation(e,'zipcode')}
                    />
                </div>                
                <div style={{marginTop:'6px'}}>
                    <span className="label">Ville</span>                   
                    <Input 
                        title="Ville"                                            
                        value={location.city}
                        id="city"
                        name="city"
                        placeholder="Ville" 
                        className=""
                        onChange={(e)=>changeLocation(e,'city')}
                    />
                </div>                
                <div style={{marginTop:'6px'}}>
                    <span className="label">Pays</span>                   
                    <Input 
                        title="Pays"                                            
                        value={location.country}
                        id="country"
                        name="country"
                        placeholder="Pays" 
                        className=""
                        onChange={(e)=>changeLocation(e,'country')}
                    />  
                </div>
                <Checkbox className='custom-checkbox' checked={applyLocationToAll} onChange={toogleCheckbox}>
                    <div className='font-medium' style={{marginTop:'12px',lineHeight: '16px'}}>Appliquer le changement à toutes les Missions ayant le même lieu.</div>
                </Checkbox>
           </div>             
            <div className='h2' style={{margin:'16px 0'}}>Date & Horaires</div>            
            <div>
                <span className="label">Date de la Mission</span>   
                <DatePicker 
                    style={{                      
                        marginBottom:margin,
                        width:"100%"
                    }}
                    format="DD/MM/YYYY"
                    value={ dayjs(eday, 'YYYY-MM-DD') }
                    onChange={setEday} 
                />
           </div> 
           <div className='flex' style={{
                    alignItems:'center',
                    justifyContent: "space-between"
                }}>
                <div className='flex' style={{marginRight:'10px',flexDirection:'column'}}>
                    <span className="label">Début</span>   
                    <TimePicker
                        id='stime'
                        format={'HH:mm'}
                        minuteStep={15} 
                        needConfirm={false}
                        showNow={false}
                        value={timeSlot.stime ? dayjs(timeSlot.stime,"HH.mm") : ''}
                        onChange={(time)=>changeTimeSlot(time,'stime')}
                        />
                </div>                    
                <div className='flex' style={{flexDirection:'column'}}>
                    <span className="label">Fin</span>   
                    <TimePicker
                        id='etime'
                        format={'HH:mm'}
                        minuteStep={15} 
                        needConfirm={false}
                        showNow={false}
                        value={timeSlot.etime ? dayjs(timeSlot.etime,"HH.mm") : ''}
                        onChange={(time)=>changeTimeSlot(time,'etime')}
                        />   
                </div>
           </div>
            <div style={{display:'flex',margin:margin,marginTop:'16px',justifyContent: "space-between"}}> 
                <div onClick={onCancel} className='button button-tiny button-secondary'>Annuler</div>
                <div onClick={()=>{onSubmit()}} className='button button-tiny button-with-accent'>Sauvegarder</div>
            </div>    
        </div>
    );
};
export default MissionSlotForm;