import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {  Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteFilled, PlusOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { saveUser } from '../../features/user/userSlice';
import { useNotification } from '../../hooks/NotificationProvider';
import { EditUserProps } from "../../services/User"
import { MediaUrl, DeleteSectionFile, UploadSectionFile, ShowFile } from "../../services/File"

const mediaWidth = 200;

const MediaCarousel = ({ user, nbMedias }) => {       
    
    const carrouselWidth = (mediaWidth * nbMedias) + mediaWidth;

    const medias = user && user.medias ? user.medias.filter((ele)=>{return ele.section === 'media'}) : [];
    const othersMedias = user && user.medias ? user.medias.filter((ele)=>{return ele.section !== 'media'}) : [];
    
    const { t } = useTranslation();    
    const { openNotification } = useNotification(); 
    const dispatch = useDispatch();
    const [selectedMedias, setSelectedMedias] = useState([]);
    const stripRef = useRef(null);

    useEffect(() => {
        if (user && user.medias) {
            const userMedias = user.medias.filter((ele)=>{return ele.section === 'media'});
            const newSelectedMedias = [];
            if (userMedias && userMedias.length){
                let idx=0;
                for (const media of userMedias) {
                    if (media.selected) newSelectedMedias.push(idx);
                    idx++;
                }
            }
            setSelectedMedias(newSelectedMedias);
        }
    }, [user]);

    const checkMedia = async (index) => {

        let updatedMedias = [];
        if (medias && medias.length) {
            updatedMedias = medias.map((media, idx) => {
                if (idx === index) {
                    return {
                        ...media,
                        selected: media.selected ? false : true
                    };
                }
                return media;
            });        
        }
        
        const userUpdated = await EditUserProps(user, { medias : [...othersMedias, ...updatedMedias] });
        if (userUpdated && userUpdated._id) {
            dispatch(saveUser(userUpdated));
            setSelectedMedias(prevSelectedMedias => {
                if (prevSelectedMedias.includes(index)) {
                    return prevSelectedMedias.filter(idx => idx !== index);
                } else {
                    return [...prevSelectedMedias, index];
                }
            });
        }

    };
    
    const openMedia = async (index) => {
        const selectedFile = medias[index];        
        if (selectedFile) {
            return await ShowFile('media',selectedFile);
        } else {
            console.error('File not found at index', index);
        }
    };

    const addMedia = async ({ file, onSuccess, onError }) => {
        try {            
            const userUpdated = await UploadSectionFile(file, 'media', user._id); 
            if (userUpdated && userUpdated._id) {
                openNotification('success','Photo Sauvegardée');                 
                const files = await MediaUrl(userUpdated.medias,'media','small') ;             
                const avatar = await MediaUrl(userUpdated.medias,'avatar','small');                         
                dispatch(saveUser({ ...userUpdated ,avatar:avatar, files:files }));                    
                onSuccess();
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
                onError();
            }
        } catch (error) {
            console.error('addMedia',error);
            onError();
        }
    };

    const delMedia = async (index) =>{
        if (window.confirm('Êtes-vous sur de vouloir supprimer cette photo ? Attention, elle sera également supprimée dans l\'App de l\'utilisateur.')) {
            try {
                const media = medias.find((media,idx)=>idx===index);
                const userUpdated = await DeleteSectionFile(media.file, 'media', user._id); 
                if (userUpdated && userUpdated._id) {                            
                    openNotification('success','Photo Supprimée');
                    const files = await MediaUrl(userUpdated.medias,'media','small') ;             
                    const avatar = await MediaUrl(userUpdated.medias,'avatar','small');                         
                    dispatch(saveUser({ ...userUpdated ,avatar:avatar, files:files }));  

                    const filteredMedias = userUpdated.medias ? userUpdated.medias.filter((ele)=>{return ele.section === 'media'}) : [];
                    let newSelectedMedias = [];
                    let iddx = 0;                    
                    for(const media of filteredMedias){
                        if (media.selected) newSelectedMedias.push(iddx);
                        iddx++;
                    }
                    setSelectedMedias(newSelectedMedias);
                } else {
                    openNotification('error','Probléme lors de la suppression du fichier');                    
                }
            } catch (error) {
                console.error('delMedia',error);
            }
        }
    };    
    
    const scrollMedia = (scrollOffset) => {
        stripRef.current.scrollBy({
          left: scrollOffset,
          behavior: 'smooth'
        });
    };

    const MediaUploader = ({}) =>{

        return (
            <Upload 
                defaultFileList={[]}
                fileList={[]}
                customRequest={addMedia}
                name='file'         
                className='media-detail-uploader'       
                >
                <PlusOutlined className='media-detail-add-user' title='Ajouter une Photo'/>
            </Upload>  
        );

    };
    
    if (user && user.files) {
        return (
            <div className={ user.files.length > nbMedias ? "media-carousel-container" : "media-carousel-container alt-nb-media"}>
               { user.files.length > nbMedias && <div className="arrow-button" onClick={() => scrollMedia(-mediaWidth)}><LeftOutlined/></div> }
                <div className='media-carousel' ref={stripRef} style={{width:( carrouselWidth )}}>                    
                    { user.files.map((file,index) => (
                        <div key={'div-'+(index+1).toString()} className='media-block'>
                            <div className='add' onClick={()=>openMedia(index)}>{t('buttons.enlarge')}</div>
                            <div className='delete' onClick={()=>delMedia(index)}>
                                <DeleteFilled/>
                                <span style={{marginLeft:'10px'}}>Effacer</span>
                            </div>
                            <img 
                                title={t('media.clickPdf')}
                                onClick={()=>{checkMedia(index)}}
                                alt="media" 
                                src={file} 
                                style={{ width: mediaWidth + 'px' }} 
                                className={ 
                                    selectedMedias && selectedMedias.includes(index) ? "media-detail-user media-selected img-bw" : "media-detail-user img-bw"
                                }
                            />
                        </div>
                    ))}
                    <div key={'div-'+(user.files ? user.files.length+1: 1).toString()} className='media-block'>                        
                        <MediaUploader/>       
                    </div>                                      
                </div> 
                { user.files.length > nbMedias &&  <div className="arrow-button" onClick={() => scrollMedia(mediaWidth)}><RightOutlined/></div> }
            </div>
        );
    } else {
        return (
            <div className='media-carousel'>
                {/* <img alt="media" src={'/img/placeholder-media.jpg'}  style={{width:mediaWidth+'px'}} className="media-detail-user"/> */}
                <div key={'div-'+(user.files ? user.files.length+1: 1).toString()} className='media-block'>                        
                    <MediaUploader/>
                </div>   
            </div>
        );
    }
};

export default  MediaCarousel ;