import React, { useState,useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import ClientGeneral from "./ClientGeneral"
import { capitalize } from '../../../utils/miscHelpers'
import GanttBlock from "../../../components/elements/GanttBlock";
import GanttToolTip from "../../../components/elements/GanttToolTip";

const TimelineTab =  ({section, bmgFilters,storedClient, openGeneral, setOpenGeneral}) => {

    // const { t } = useTranslation();            

    const [selectType, setSelectType] = useState('staff');
    //const [scaleCss, setScaleCss] = useState('col-large');    
    const [tasks, setTasks] = useState([]);

    const accentColor = 'var(--accent-color)';    
    const enCoursColor = 'var(--green-color)';    

    const doData = () =>{
        
        //const defaultColor = '#433F3A'; // BMG BLACK secondary-color    

        let data = [];
        if (storedClient && storedClient.operations && storedClient.operations.length) {
            for (const operation of storedClient.operations) {      

                const date = {
                    startTime:new Date(operation.sdate),
                    endTime: new Date(operation.edate),
                };

                if (isNaN(date.startTime) || isNaN(date.endTime)) {
                    console.error('Invalid date format', date);
                } else {     
                    data.push({
                        start: date.startTime,
                        end: date.endTime,
                        name: capitalize(operation.name),
                        id: operation._id,
                        ref:operation._id,
                        body: <GanttToolTip annexe={operation.bmgStatus === 2 ? 'En Cours' : 'Terminé'} dates={{sdate:operation.sdate,edate:operation.edate}} staffName={operation.name}/>,
                        // type: 'project',
                        // progress: 100,
                        // isDisabled: false,
                        styles: { 
                            backgroundSelectedColor:operation.bmgStatus === 2 ? enCoursColor : accentColor, 
                            backgroundColor:operation.bmgStatus === 2 ? enCoursColor : accentColor, 
                            progressColor: operation.bmgStatus === 2 ? enCoursColor : accentColor, 
                            progressSelectedColor: operation.bmgStatus === 2 ? enCoursColor : accentColor, 
                        },                                
                    });
                }
            }
        }

        return setTasks(data);        
    };

    useEffect(() => {
        doData();
    }, [storedClient]);

    const onSelectType = (val) =>{
        setSelectType(val);
        // if  (val === 'staff') {
        //     setScaleCss('col-large');
        // } else {
        //     setScaleCss('');
        // }        
    };
    
    return(
        <div className="tab-content" style={{height:"100%"}}>
            <ClientGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')} style={{paddingLeft:'16px',paddingRight:'16px',paddingTop:'26px',marginRight:'6px'}}>
                <GanttBlock 
                    section={section}
                    events={tasks}
                    selectType={selectType}
                    onSelectType={onSelectType}
                    displayTypeSelector={false}
                    />               
            </div>
        </div>
    )
};

export default TimelineTab;