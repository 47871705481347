import React from 'react';
import {Priorities} from "../../utils/constants";
import {  CarryOutFilled, CarryOutOutlined } from '@ant-design/icons';

const ToDoIcon = ({section,record,styles}) =>{
    let todoColor = '';

    let toDo = record; // Agenda Case
    if (['client','operation','user','joboffer'].includes(section)) toDo = record.todo;

    if ( toDo && toDo._id) {
      const priority = Priorities.find((el)=>el.value === toDo.priority);
      if (priority && priority.color) {
        todoColor = priority.color;
      }
    }

    if (toDo && toDo.status === 1) {
        todoColor = 'black';
    }
    
    if (todoColor!=='') {                      
      return <CarryOutFilled
        title="Gérer la Tâche"
        className={'icon-table '+section}
        style={{color:todoColor,...styles}}
      /> 
    } else {       
      return <CarryOutOutlined
        title="Ajouter une Tâche"
        className={'icon-table '+section}
        style={styles}
      /> 
    }
  };

  export default ToDoIcon;