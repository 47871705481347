import { Post, ApiRoutes } from "./Api"

export const ListJobOffers = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.jobofferList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('JobOffer resultList',err);
    }
};

export const SaveJobOffer = async (data) => {
    try {
        const res = await Post(ApiRoutes.jobofferAdd, data);
        if (res) return res.value;        
    } catch (err) {
      console.error('JobOffer resultList',err);
    }
};

export const EditJobOfferProps = async (itemId,props) =>{
    try {
        const res = await Post(ApiRoutes.jobofferEdit, { id: itemId, ...props});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error EditJobOfferProps');
        }        
    } catch (err) {
      console.error('EditJobOfferProps Err',err);
    }
};

export const GetJobOffer = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.jobofferGet, { id: itemId});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error DeleteJobOffer');
        }        
    } catch (err) {
      console.error('DeleteJobOffer Err',err);
    }
};

export const DeleteJobOffer = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.jobofferDel, { id: itemId});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error DeleteJobOffer');
        }        
    } catch (err) {
      console.error('DeleteJobOffer Err',err);
    }
};

