import React  from 'react';
import FormQuote from "../elements/FormQuote";

const StepThree =  ({
    section, 
    bmgFilters, 
    handlerChangeMethod,
    handlerSelectMethod,
    addEntry,
    deleteEntry,
    changeEntry,
    quote,
    quoteVat,
    quoteHeader,
    quoteCurrency,
    quoteFooter,
    quoteFees,
    quoteExpire
}) => {

    return(
        <div className="step-content">    
            <div className='h2 step-title'>Devis</div>        
            <FormQuote
                topSpacer={12}
                section={section}
                bmgFilters={bmgFilters}
                quote={quote}
                quoteCurrency={quoteCurrency}
                quoteVat={quoteVat}
                quoteHeader={quoteHeader}
                quoteFooter={quoteFooter}
                quoteFees={quoteFees}
                quoteExpire={quoteExpire}
                handlerChangeMethod={handlerChangeMethod}
                handlerSelectMethod={handlerSelectMethod}
                addEntry={addEntry}
                deleteEntry={deleteEntry}
                changeEntry={changeEntry}
            />          
        </div>
    )
};

export default StepThree;