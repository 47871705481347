import dayjs from 'dayjs';
import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { Input,Select,DatePicker,Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListLabels } from '../../services/Label';
import { EditToDoProps, SaveToDo } from '../../services/ToDo';
import { Priorities } from '../../utils/constants'
import { saveLists } from '../../features/lists/listsSlice';
import { useNotification } from '../../hooks/NotificationProvider';
import { capitalize } from '../../utils/miscHelpers';

const { TextArea } = Input;
const section = 'todo';

const ToDoForm = ({data, setOpen, handlerMethod,target,targetData}) => {

    const statuses = [
        {label:'Fait',value:1},
        {label:'A faire',value:2}
    ];  

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openNotification } = useNotification();

    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

    const [name, setName] = useState(data && data.name ? data.name : '');
    const [priority, setPriority] = useState(data && data.priority ? data.priority : 1);
    const [body, setBody] = useState(data && data.body ? data.body : '');
    const [selectedDate, setSelectedDate] = useState(data && data.ddate ? dayjs(data.ddate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'));
    const [labels, setLabels] = useState([]);
    const [originalLabels, setOriginalLabels] = useState([]);
    const [labelItems, setLabelItems] = useState(data && data.label ? data.label : []);
    const [status, setStatus] = useState(data && data.status ? data.status : 2);

    const margin = '8px';

    useEffect(() => {
        const fetchData = async () => {
            try {      
            
              const results = await ListLabels();
              setOriginalLabels(results.resultList);
              let labelList = [];
              for(const item of results.resultList){
                  labelList.push({
                      label : item.name,
                      value:item._id,
                  });
              }

              setLabels(labelList); 
              let labelItems = [];
              if (data && data.label && data.label.length) {
                for(const item of data.label){
                    labelItems.push({
                        label : item.name,
                        value:item._id,
                    })
                }
              }
              setLabelItems(labelItems);

              setName(data && data.name ? data.name : '');
              setPriority(data && data.priority ? data.priority : 1);
              setBody(data && data.body ? data.body : '');
              setSelectedDate(data && data.ddate ? dayjs(data.ddate).format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD'));             
              setStatus(data && data.status ? data.status : 2);    

              return;
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
          fetchData();        
    }, [data]); 

    useEffect(() => {
        const fetchData = async () => {
          try {
            const results = await ListLabels();
            setOriginalLabels(results.resultList);
            let labelList = [];
            for(const item of results.resultList){
                labelList.push({
                    label : item.name,
                    value:item._id,
                });
            }
            setLabels(labelList); 
            return;
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        fetchData();    
      }, []); 
      
    const onCancel = ()=>{
        resetSets();
        setOpen(false);
    };

    const resetSets = ()=>{
        setName('');
        setPriority(1);
        setBody('');
        setSelectedDate(dayjs().format('YYYY-MM-DD'));
        setLabelItems([]);
    };

    const onSubmit = async ()=>{

        if (!name || name === '') {
            openNotification('error',"Le champs Intitulé est obligatoire");     
            return;
        }

        let props = {
            ...{name:name},
            ...{priority:priority},
            ...{body:body},
            ...{label:labelItems},
            ...{status:status},
            ...{ddate:dayjs(selectedDate,'YYYY-MM-DD').format('YYYY-MM-DD'),}
        };

        if (target && targetData) {
            props.target = target;
            props.targetId = targetData._id;
        }

        let newList = [...storedList];

        // Edit
        let resultValue = null;
        if (data && data._id) {
            resultValue = await EditToDoProps(data._id,props);
            newList = newList.filter((ele)=>ele._id !== data._id);
        } 
        // Save
        else {
            resultValue = await SaveToDo(props);
        }
        
        // Append Label full OBJ
        if (resultValue && resultValue.label && resultValue.label.length) {
            let fullLabelList = []
            for (const label of resultValue.label){
                const labelId = label && label._id ? label._id : label; // Due to populate in EDIT
                const originalLabel = originalLabels.find((ele)=> ele._id === labelId);
                if (originalLabel) fullLabelList.push(originalLabel); 
            }
            resultValue.label = fullLabelList;
        }
        newList.unshift(resultValue);

        // Update store
        dispatch(saveLists({section:section,data:newList}));

        // Clean here
        resetSets();

        // Return here
        handlerMethod(resultValue); // newList
    };

    const onNameChange = (e)=>{
        setName(e.target.value);
    };

    const onPriorityChange = (value)=>{        
        setPriority(value);
    };

    const onBodyChange = (e) =>{
        setBody(e.target.value);
    };

    const onLabelChange = (values) =>{        
        setLabelItems(values);
    };
    
    const onDueDateChange = (date)=>{
        setSelectedDate(date);
    }

    const tagRender = (props) => {
        
        const { label, value, closable, onClose } = props;

        const item = originalLabels.find((ele)=>ele._id === value);

        const onPreventMouseDown = (event) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color={"white"}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            className="tag" 
            style={{
                padding:"5px",
                backgroundColor: item && item.color ? item.color : 'var(--accent-color)',
                color:"white",
            }}
          >{label}</Tag>
        );
    };

    const targetName = () => {
        if (target && targetData) {
            switch(target){
                case 'operation' :
                    return ' - Op / ' + capitalize(targetData.name);
                case 'client' :
                    return ' - Client / ' + capitalize(targetData.name);
                case 'user' :
                    return ' - Hôte.sse / ' + capitalize(targetData.firstName) +' '+capitalize(targetData.lastName);                        
                default:
                    return '';
            }            
        }
    };    
    return (
        <div >
            <h2 style={{margin:'16px 0'}}>To Do{targetName()}</h2>
            <div style={{display:'flex', justifyContent: "space-between",alignItems: "center"}}>     
                <div className='flex flex-row'>
                    <span className="label">Priorité</span>
                    <Select
                        style={{
                            width: '100px',
                            marginRight:margin
                        }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        placeholder="Priorité"
                        defaultValue={priority}
                        value={priority}
                        onChange={onPriorityChange}
                        options={Priorities}
                        className="filter-select small"
                    />                     
                </div>        
                <div className='flex flex-row'>
                    <span className="label" >Statut</span>
                    <Select
                        style={{
                            width: '100px',
                        }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        placeholder="Statut "
                        defaultValue={status}
                        value={status}
                        onChange={setStatus}
                        options={statuses}
                        className="filter-select small"
                    />                      
                </div>    
                <div className='flex flex-row'>
                    <span className="label"  style={{marginLeft:'8px'}}>Date d'échéance</span>
                    <DatePicker 
                        style={{                      
                            marginLeft:margin
                        }}
                        format="DD/MM/YYYY"
                        value={ dayjs(selectedDate, 'YYYY-MM-DD') }
                        onChange={onDueDateChange} 
                    />                   
                </div>            
            </div>
            <div className='flex flex-row topper-small'>
                <span className="label" >Intitulé</span>
                <Input  
                    style={{            
                        width:'96%',
                        background: "white",
                        color:'var(--secondary-color) !important'
                    }}
                    value={name}
                    id="name"
                    name="name"
                    placeholder="Intitulé" 
                    onChange={onNameChange}
                />   
            </div>
            <div className='flex flex-row topper-small'>
                <span className="label" >Déscription</span>            
                <TextArea 
                    style={{ 
                        width:'96%',             
                        background: "white",
                        color:'var(--secondary-color) !important'                    
                    }}            
                    value={body}
                    onChange={onBodyChange}
                    className="bmg-textarea" 
                    rows={6} 
                    placeholder={t('message.default')} 
                />  
            </div>
            <div className='flex flex-row topper-small'>
                <span className="label" >Labels</span>            
                <Select
                    mode="multiple"
                    tagRender={tagRender}
                    allowClear
                    style={{
                        width: '96%',                        
                        background: "white",
                        color:'var(--secondary-color) !important'
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Labels"
                    defaultValue={labelItems}
                    value={labelItems}
                    onChange={onLabelChange}
                    options={labels}
                    className="filter-select small"
                />  
            </div>                     
            <div style={{display:'flex',margin:margin,justifyContent: "space-between"}}> 
                <div onClick={onCancel} className='button button-tiny button-secondary'>Annuler</div>
                <div onClick={()=>{onSubmit()}} className='button button-tiny button-with-accent'>Sauvegarder</div>
            </div>    
        </div>
    );
};
export default ToDoForm;