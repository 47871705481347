import React from 'react';
import dayjs from 'dayjs';
import { Select } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { doS , sortMissionSlotsByDays, ContactedUsersMetris } from "../../../utils/miscHelpers"
import {MissionBmgStatus} from "../../../utils/constants"

import PopOverForm from "../../../components/elements/PopOverForm"

const numbers = Array.from({ length: 101 }, (_, i) => ({ label: i.toString(), value: i }));

const MissionSlotsByRole =  ({
        section, 
        roles,
        storedOperation,
        staffList,
        changeEntry,
        deleteEntry,
        showEditMission,
        onSubmitMissionSlot,
        onSubmitRole
    }) => {    

    return (        
        <div style={{width:'100%', textAlign: "left"}}>
            {(roles && Object.keys(roles).length > 0) &&
                 <div className='step-mission-list full-scale'>
                    {Object.keys(roles).map((roleKey,index)=>{
                        const role = roles[roleKey];
                        if (role) {
                            return(
                                <div key={index.toString()}className='flex' style={{width:'100%',marginBottom:'16px',flexDirection:'column'}}> 
                                    <div className='flex flex-column-start'>
                                        <div>
                                            <span className='uppercase bold'>{role.name}</span>
                                            <span style={{margin:'0 6px'}}>-</span>
                                            <span className='uppercase bold' style={{color:'var(--accent-color)'}}>{role.price} / {role.cost} {role.currency}</span>
                                        </div>
                                        <PopOverForm
                                            content={<EditOutlined style={{marginLeft:'10px'}} title="Editer le rôle et le prix" className='icon-table-small' />}
                                            form="roleForm"
                                            color={'white'}
                                            handlerMethod={(props)=>onSubmitRole(props)}                                                            
                                            data={role}
                                        />     
                                    </div>
                                    <div>
                                        {role.missionSlots && role.missionSlots.length>0 && sortMissionSlotsByDays(role.missionSlots).map((mission,idx)=> {
                                            const metricStaffMission = ContactedUsersMetris(mission, storedOperation);
                                            return (
                                                <div key={idx.toString()}className='step-mission-item' style={{width:'100%'}}>
                                                    <div className='bold' style={{width:'333px'}}>
                                                        <div className='capitalize' >{dayjs(mission.eday).format('dddd D MMMM YYYY')} {'>'} {mission.timeSlot.stime} <span className='uncapitalize'>à</span> {mission.timeSlot.etime}</div>
                                                        <div style={{color:'var(--accent-color)'}}>{mission.location.name} {mission.location.address} {mission.location.city} {mission.location.zipcode} {mission.location.country}</div>
                                                    </div>
                                                    <div className='flex' style={{flexDirection: "column",alignItems: "center"}}>
                                                        <span className='bold' >{mission.users ? mission.users.length : 0}</span>
                                                        <span className=''>Affécté{doS(mission.users ? mission.users.length : 0,'number')}</span>
                                                    </div>                                                    
                                                    <div className='flex' style={{flexDirection: "column",alignItems: "center"}}>
                                                        <span className='bold' style={{color:MissionBmgStatus[3].color}}>{metricStaffMission.pending}</span>
                                                        <span className=''>En attente</span>
                                                    </div>
                                                    <div className='flex' style={{flexDirection: "column",alignItems: "center"}}>
                                                        <span className='bold' style={{color:MissionBmgStatus[2].color}}>{metricStaffMission.postulate}</span>
                                                        <span className=''>Postulé{doS(metricStaffMission.postulate,'number')}</span>
                                                    </div>    
                                                    <div className='flex' style={{flexDirection: "column",alignItems: "center"}}>
                                                        <span className='bold'  style={{color:MissionBmgStatus[1].color}}>{metricStaffMission.booked}</span>
                                                        <span className=''>Booké{doS(metricStaffMission.booked,'number')}</span>
                                                    </div>                                                                                                      
                                                    <div className='flex' style={{flexDirection: "row",alignItems: "center"}}>
                                                        <Select
                                                            placeholder="0"
                                                            defaultValue={0}
                                                            value={mission.workForce}
                                                            onChange={(e)=>changeEntry('missionWorkForce',e,index,mission._id)}
                                                            options={numbers}
                                                            className="number-select bold"
                                                        />   
                                                        {/* <UserOutlined title="Cible recherchée" style={{marginLeft:'6px',color:'var(--accent-color)'}}  /> */}
                                                    </div>
                                                    <div>
                                                        <PopOverForm
                                                            content={<EditOutlined title="Editer le lieu, la Date et Horaires" className='icon-table link' />}
                                                            form="missionSlot"
                                                            color={'white'}
                                                            handlerMethod={(props)=>onSubmitMissionSlot(props)}                                                            
                                                            data={mission}
                                                        />                                                           
                                                        <DeleteOutlined className="icon-table link" title='Effacer cette Mission'  style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('mission',index,mission._id)}/>
                                                    </div>
                                                </div>
                                            )}
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        return false;

                    })}
                </div>
            }                
        </div>
    )
};

export default MissionSlotsByRole;