import { Post, ApiRoutes } from "./Api"

export const ListAdmins = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.adminList, data);
        if (res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('Admin resultList',err);
    }
};

export const SaveAdmin = async (data) => {
    try {
        const res = await Post(ApiRoutes.adminAdd, data);
        return res.value;        
    } catch (err) {
      console.error('JobOffer resultList',err);
    }
};

export const EditAdminProps = async (admin,props) =>{
    try {
        const res = await Post(ApiRoutes.adminEdit, { id: admin._id, ...props});
        if (res.success && res.value){                     
            return res.value;
        } else {
            alert('Error EditAdminProps');
        }        
    } catch (err) {
      console.error('EditAdminProps',err);
    }
};

export const DeleteAdmin = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.adminDel, { id: itemId});
        if (res.success && res.value){          
            return res.value;
        } else {
            alert('Error DeleteAdmin');
        }        
    } catch (err) {
      console.error('DeleteAdmin Err',err);
    }
};

