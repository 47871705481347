import React from 'react';
import dayjs from 'dayjs';
import { Input, Select,DatePicker } from 'antd';
import { DeleteOutlined} from '@ant-design/icons';
import { Currencies } from '../../../utils/constants';

const { TextArea } = Input;

const FormQuote =  ({
        section, 
        bmgFilters,
        topSpacer,
        handlerChangeMethod,    
        addEntry,
        changeEntry,
        deleteEntry,
        quote,
        quoteCurrency,
        quoteVat,
        quoteHeader,
        quoteFooter,
        quoteFees,
        quoteExpire
    }) => {

    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };

    return(
        
        <div className='tab-form steps' style={{marginTop:topSpacer+'px'}}>
            <div className='tab-form-block'>       
                <div className='tab-form-block-column' style={{width: '200px'}}>
                    <div className="form-label-name">
                        <span className="label">Réf. Devis</span>    
                        <Input 
                            title='Réf. Devis'
                            defaultValue={quote}
                            id="quote"
                            name="quote"
                            placeholder="Réf. Devis" 
                            onChange={(e)=>handlerChangeMethod(e,"setQuote")}
                        />
                    </div> 
                </div>
                <div className='tab-form-block-column' style={{width: '80px'}}>
                    <div className="form-label-name">
                        <span className="label">TVA</span>    
                        <Input 
                            title='TVA'
                            defaultValue={quoteVat}
                            id="quoteVat"
                            name="quoteVat"
                            placeholder="TVA" 
                            addonAfter={<div>%</div>}
                            onChange={(e)=>handlerChangeMethod(e,"setQuoteVat")}
                        />
                    </div> 
                </div>    
                <div className='tab-form-block-column' style={{width: '80px'}}>
                    <div className="form-label-name">
                        <span className="label">Devise</span>    
                        <Select
                            style={{
                                width: '75px',                    
                            }}
                            optionLabelProp="label"
                            optionFilterProp="label"
                            placeholder="Devises"
                            value={quoteCurrency}
                            onChange={(e)=>handlerChangeMethod(e,"setQuoteCurrency")}
                            options={Currencies}
                            className="uppercase accented"
                        />  
                    </div> 
                </div> 
                <div className='tab-form-block-column' style={{width: '150px'}}>
                    <div className="form-label-name">
                        <span className="label">Date d'éxpiration</span>    
                        <DatePicker 
                            onChange={(date)=>changeEntry('quoteExpire',date)}
                            maxTagCount="responsive" 
                            value={quoteExpire} 
                            format={'DD/MM/YYYY'}
                            disabledDate={disabledDate}
                        />
                    </div> 
                </div> 
         

            </div>
            <div className='tab-form-block'>
                <div className="form-label-name">
                    <span className="label">Entête du devis</span>    
                    <TextArea 
                        title='Entête du devis'
                        value={quoteHeader}
                        onChange={(e)=>handlerChangeMethod(e,"setQuoteHeader")}
                        className="bmg-textarea" 
                        rows={4} 
                        placeholder='Entête du devis'
                    />
                </div>                
            </div>  
            <div className='tab-form-block'>
                <div className="form-label-name">
                    <span className="label">Pied de page du devis</span>    
                    <TextArea 
                        title='Pied de page du devis'
                        value={quoteFooter}
                        onChange={(e)=>handlerChangeMethod(e,"setQuoteFooter")}
                        className="bmg-textarea" 
                        rows={2} 
                        placeholder='Pied de page du devis'
                    />
                </div> 
            </div>
            <div className='tab-form-block' style={{justifyContent: 'flex-end'}}>
                <div className='button button-with-accent button-tiny' onClick={()=>addEntry('quoteFees')} style={{marginTop:'16px',width:'100px'}}>
                    <div>Extra +</div>
                </div>
            </div>  
            {(quoteFees && quoteFees.length !== 0) &&
                quoteFees.map((quoteFee,index)=>{
                    return(
                        <div key={index.toString()} className='tab-form-block'>                            
                            <div className='tab-form-block-column' style={{width: '80%'}}>
                                <div className="form-label-name">
                                    <span className="label">Intitulé de la ligne</span>    
                                    <Input 
                                        title='Intitulé de la ligne'
                                        defaultValue={quoteFee.name}
                                        id="quoteFeeName"
                                        name="quoteFeeName"
                                        placeholder="Intitulé de la ligne" 
                                        onChange={(e)=>changeEntry('quoteFees',e,index,'name')}
                                    />
                                </div>
                            </div>

                            <div className='tab-form-block-column' style={{width: '50px'}}>
                                <div className="form-label-name">
                                    <div className="label" style={{width: '50px'}}>Total</div>    
                                    <Input 
                                        style={{
                                            width: '80px',                    
                                        }}
                                        title="Total"                                            
                                        value={quoteFee.price}
                                        id="price"
                                        name="price"
                                        placeholder="Total" 
                                        className=""
                                        onChange={(e)=>changeEntry('quoteFees',e,index,'price')}
                                    /> 
                                </div>
                            </div> 
                            <DeleteOutlined className="link" style={{marginTop:'16px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('quoteFees',index)}/>
                        </div>
                    )
                })
            }
        </div>

    )
};

export default FormQuote;