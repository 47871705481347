import React from 'react';
import moment from 'moment';

//const section = 'quote';

const QuoteDetail = ({data, setOpen}) => {
    
    if (!data.name) return null;

    return (
        <div className='quote-detail'>
            <div className='h2 capitalize'>{data.name}</div>
            <div style={{fontWeight:'bold'}}>{moment(data.eventDate,'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
            <div className='capitalize'>{data.clientName}</div>
            <div style={{marginTop:"16px",marginBottom:"16px"}}>{data.totalWomen} Hôtesses / {data.totalMen} Hôtes</div>
            <div className=''>Nb. Jours / {data.days}</div>
            <div className=''>Horaires / {data.startTime} à {data.endTime}</div>
            <div className='capitalize'>Langues / {data.languages ? data.languages.join(', ') : ''}</div>                        
            <div style={{marginTop:"16px",marginBottom:"16px"}}>
                <div className='capitalize'>Lieu / {data.address}, {data.city}</div>
            </div>
            <div className=''>{data.body}</div>
        </div>
    );

};
export default QuoteDetail;