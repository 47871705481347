import React,{useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import {  EditOutlined } from '@ant-design/icons';

import { saveLists } from '../../../features/lists/listsSlice';
import { saveClient } from '../../../features/client/clientSlice';
import { updateList } from "../../../utils/miscHelpers"


import HeaderBar from "../../../components/layout/HeaderBar";
import PresentationTab from "../../../components/client/tabs/PresentationTab"
import QuoteTab from "../../../components/client/tabs/QuoteTab"
import OperationTab from "../../../components/client/tabs/OperationTab"
import TimelineTab from "../../../components/client/tabs/TimelineTab";

import PopOverForm from "../../../components/elements/PopOverForm"
import ToDoIcon from "../../../components/todo/ToDoIcon";

const section = 'client';

const UserDetail = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedClient = useSelector(state => state.client.value && state.client.value ? state.client.value  : {});
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [tabActiveKey, setTabActiveKey] = useState('1');
  const [openGeneral, setOpenGeneral] = useState(true);

  useEffect(() => {
    // console.log('storedUser',storedUser);
    // Force Go back to User list if Browser Reload
    if (!storedClient || !storedClient._id) {
      navigate('/client');
    } 
}, [storedClient, navigate]);

  const onTabChange = (key) => {
    setTabActiveKey(key);
  };

  const onEditTodo = (todoUpdated) =>{
    if (todoUpdated && todoUpdated._id) {    
        const eleModified = {...storedClient, todo : todoUpdated};
        dispatch(saveClient(eleModified));
        dispatch(saveLists({ section : section, data : updateList(storedList,eleModified) }));        
    }
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <span>
          {t('client.tabs.presentation')}
        </span>
      ),
      children: <PresentationTab
                section={section}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}                    
            />,
    },
    {
      key: '2',      
      label: (
        <span>
          {t('client.tabs.quote')}
        </span>
      ),
      children: <QuoteTab
                  section={section}
                  // storedClient={storedClient}
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}                    
              />,
    },
    {
      key: '3',      
      label: (
        <span>
          {t('client.tabs.operations')}
        </span>
      ),
      children: <OperationTab
                    section={section}      
                    storedClient={storedClient}
                    openGeneral={openGeneral}
                    setOpenGeneral={setOpenGeneral}                    
                />,
    }, 
     {
      key: '4',      
      label: (
        <span>
          {t('client.tabs.timeline')}
        </span>
      ),
      children: <TimelineTab
                  section={section}
                  storedClient={storedClient}
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}                  
                />,
    },           
  ];

  return (
    <div className="container">
      <HeaderBar 
        actionContent={
          <div className="header-actions" style={{ marginLeft:"16px"}}>
            <div title="Editer le client" onClick={()=>navigate('/client/form',{state:storedClient})} className="secondary-button" >
              <EditOutlined 
                    title="Editer le Client"
                    style={{ color: "var(--accent-color)"}}
                  /> 
            </div>
          </div>
        }
        todoContent= {
          <PopOverForm
              content={
                  <ToDoIcon section={section} record={storedClient} styles={{marginLeft:'16px',fontSize:'1.618rem'}}/>
              }
              form="todo"
              handlerMethod={onEditTodo}
              data={storedClient.todo}
              target='client'              
              targetData={storedClient}
          />  
        }
        hasBack={true} 
        hasFilters={false} 
        hasSearch={false} 
        hasSort={false} 
        section={section} 
        storedFilters={storedFilters} 
      />
      <div className="content">
        <Tabs
          //style={{border:'solid'}}
          activeKey={tabActiveKey}
          onChange={onTabChange}
          type="card"
          items={tabItems}
        />
      </div>
    </div>
  );
};

export default UserDetail ;
