import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { getMedia } from "../../services/File"

import { BmgStatus} from "../../utils/constants"
import { getAge, scoreContent,capitalize } from "../../utils/miscHelpers"

const UserListDrawer = ({record, openDrawer, handlerMethod}) => {    
    
    const [avatarFiles, setAvatarFiles] = useState([]);

    useEffect(() => {
        const fetchAvatarFiles = async () => {
            if (record && record.users) {
                const avatarFilesPromises = record.users.map(async (user) => {                    
                    const avatarArr = user.medias.find((media) => media.section === 'avatar');
                    const avatarFile = await getMedia({...avatarArr, size: 'small'});
                    return avatarFile;
                });
                const resolvedAvatarFiles = await Promise.all(avatarFilesPromises);
                setAvatarFiles(resolvedAvatarFiles);
            }
        };

        fetchAvatarFiles();
    }, [record]);

    const linkMeToUser = async (user,index)=>{
        try {            
            return window.open(process.env.REACT_APP_PUBLIC_BO_URL+'/user/detail?staffId='+user._id);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    };    

    return (
        <>
            <Drawer
                title={
                    <div className="h3b grey-dark message-drawer-title ">                                            
                        <div style={{marginLeft:'16px'}}>{capitalize(record.name)}</div>
                    </div>
                }
                placement="right"
                width={500}
                onClose={() => handlerMethod(false)}
                open={openDrawer}
                className=''                
            >  
                {record && record.users && record.users.map((user, index) => (
                    <div key={user._id} className="tab-table-row-block" style={{marginBottom:'16px',display:'flex',alignItems: "center"}}>
                        <div> 
                            <img alt="user" src={avatarFiles[index] ? avatarFiles[index] : '/img/placeholder-avatar.jpg'} className="avatar-small" />                                                    
                        </div>
                        <div style={{marginLeft:'6px'}}>
                            {(user.score !== null && user.score !== 0) && 
                                <div className="score-list-user-small">{scoreContent(user.score,'score-small')}</div>
                            }
                            <div className="custom-name capitalize bold link" onClick={()=>linkMeToUser(user,index)}>{user.firstName +' '+user.lastName}</div> 
                            <div>
                                <span className='tab-table-row-span'>({user.gender === 1 ? 'H' : 'F'})</span>
                                <span className='tab-table-row-span'>{user.birthday ? getAge(user.birthday)+' ans' : ''}</span>
                                <span className='tab-table-row-span'>{user.height ? (user.height/100).toFixed(2)+'m' : ''}</span>
                            </div>
                            <div style={{marginTop:'16px'}}>
                                <span className="capitalize tag" style={{backgroundColor:user.bmgStatus ? BmgStatus[user.bmgStatus].color : BmgStatus[0].color}}>{user.bmgStatus ? BmgStatus[user.bmgStatus].label : BmgStatus[0].label}</span>
                            </div>
                            
                        </div>    

                    </div>
                ))}
            </Drawer>
        </>
    );
};
export default UserListDrawer;
