import React,{useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs,Badge } from 'antd';
import { EditOutlined} from '@ant-design/icons';

import { saveUser } from '../../../features/user/userSlice';
import { saveLists } from '../../../features/lists/listsSlice';
import { MediaUrl } from "../../../services/File"
import { GetUser } from "../../../services/User";
import HeaderBar from "../../../components/layout/HeaderBar";
import PresentationTab from "../../../components/user/tabs/PresentationTab"
import TimelineTab from "../../../components/user/tabs/TimelineTab"
import MissionsTab from "../../../components/user/tabs/MissionsTab"
import MessagesTab from "../../../components/user/tabs/MessagesTab"
import { updateList, getNotificationCount } from "../../../utils/miscHelpers";

import PopOverForm from "../../../components/elements/PopOverForm"
import ToDoIcon from "../../../components/todo/ToDoIcon";

const section = 'user';

const UserDetail = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : {});
  const storedMessageNotification = useSelector(state => state.notification.value && state.notification.value.message ? state.notification.value.message : null);
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  //console.log('location.state?.activeKey',location.state?.activeKey)

  const [tabActiveKey, setTabActiveKey] = useState(location.state?.activeKey || '1');
  const [openGeneral, setOpenGeneral] = useState(true);
  const [paramStaffId, setParamStaffId] = useState((new URLSearchParams(location.search)).get('staffId') || null);

  const userNotificationCount = getNotificationCount(
    'user',
    storedMessageNotification && storedMessageNotification.list && storedMessageNotification.list.length ? storedMessageNotification.list : [], 
    storedUser ? { _user : storedUser} : null
  );
  
  useEffect(() => {
    
    const getUserMedias = async (user,medias,section='media') =>{
      user.files = await MediaUrl(medias,section,'small');
      dispatch(saveUser(user));
    }

    const userGetFromUrlQuery = async (userId) =>{      
      const userResult = await GetUser(userId);
      if (userResult && userResult.success && userResult.value) {        
        const files = await MediaUrl(userResult.value.medias,'media','small') ;             
        const avatar = await MediaUrl(userResult.value.medias,'avatar','small');                         
        dispatch(saveUser({ ...userResult.value ,avatar:avatar, files:files }));          
        const urlSearchParams = new URLSearchParams(location.search);
        if (urlSearchParams.has('staffId')) {
          urlSearchParams.delete('staffId');
          //window.history.replaceState({}, '', '/user/detail');
        }
        setParamStaffId(null);
      }
    }

    // Force Go back to User list if Browser Reload
    if (!paramStaffId) {
      if (!storedUser || !storedUser._id) {
        navigate('/user');
      }
    } else {
      userGetFromUrlQuery(paramStaffId);
    }

    // Get User Medias Files if none downloaded
    if (paramStaffId === null && storedUser && storedUser.medias && storedUser.medias.length) {
      const medias = storedUser.medias.filter((media)=>media.section === 'media');
      if (medias && medias.length && (!storedUser.files || !storedUser.files.length)) {
        //if (medias && medias.length && storedUser && storedUser._id ) {        
        getUserMedias({...storedUser},medias);
      }
    }

}, [storedUser, paramStaffId]);

  const onTabChange = (key) => {
    setTabActiveKey(key);
  };

  const onEditTodo = (todoUpdated) =>{
    if (todoUpdated && todoUpdated._id) {    
        const eleModified = {...storedUser, todo : todoUpdated};
        dispatch(saveUser(eleModified));
        dispatch(saveLists({ section : section, data : updateList(storedList,eleModified) }));        
    }
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <span>
          {t('user.tabs.presentation')}
        </span>
      ),
      children: <PresentationTab 
                section={section}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}                    
            />,
    },
    {
      key: '2',      
      label: (
        <span>
          {t('user.tabs.timeline')}
        </span>
      ),
      children: <TimelineTab
                section={section}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}                    
            />,
    },
    {
      key: '3',      
      label: (
        <span>
          {t('user.tabs.missions')}
        </span>
      ),
      children: <MissionsTab
              section={section}
              openGeneral={openGeneral}
              setOpenGeneral={setOpenGeneral}                    
          />,
    },    
    {
      key: '4',      
      label: (
        <span>
          {t('user.tabs.messages')} <Badge count={userNotificationCount} />
        </span>
      ),      
      children: <MessagesTab
          section={section}
          openGeneral={openGeneral}
          setOpenGeneral={setOpenGeneral}                    
      />,
    },            
  ];

  return (
    <div className="container">
      <HeaderBar 
          actionContent={
            <div className="header-actions" style={{ marginLeft:"16px"}}>
              <div title="Editer" onClick={()=>navigate('/user/form')} className="secondary-button" >
                <EditOutlined 
                      title="Editer"
                      style={{ color: "var(--accent-color)"}}
                    /> 
              </div>
            </div>
          }
          todoContent= {
            <PopOverForm
                content={
                    <ToDoIcon section={section} record={storedUser} styles={{marginLeft:'16px',fontSize:'1.618rem'}}/>
                }
                form="todo"
                handlerMethod={onEditTodo}
                data={storedUser.todo}
                target='user'              
                targetData={storedUser}
            />  
          }          
          hasBack={true} 
          hasFilters={false} 
          hasSearch={false} 
          hasSort={false} 
          section={section} 
          storedFilters={storedFilters} 
          setCurrentPage={1}
      />
      <div className="content">
        <Tabs
          //style={{border:'solid'}}
          activeKey={tabActiveKey}
          onChange={onTabChange}
          type="card"
          items={tabItems}
        />
      </div>
    </div>
  );
};

export default UserDetail ;
