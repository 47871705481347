import React, { useContext,useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { resetFilters } from '../features/filters/filtersSlice';
import { resetLists } from '../features/lists/listsSlice';
import { resetConstants, saveAllConstants } from '../features/lists/constantsSlice';
import { resetNotification, saveNotification } from '../features/notification/notificationSlice';
import { saveLists } from '../features/lists/listsSlice';
import { Post, Get, ApiRoutes,getToken, setToken  , getAdmin, setAdmin, removeToken } from "../services/Api"
import { ErrorMsg } from '../utils/errorHelpers'
import { useNotification } from './NotificationProvider';
import { useTranslation } from 'react-i18next';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

  const { openNotification } = useNotification(); 

  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const navigate = useNavigate();
  const token = getToken();
  const admin = getAdmin();  

  useEffect(() => {

    const bootStrapReload = async () =>{

      console.log('AuthProvider bootStrapReload RELOAD');
      try {
        if (token) {

          await sideLoad();

          // const resConst = await Post(ApiRoutes.constants, {});
          // if (resConst && resConst.success && resConst.value ) {
          //   dispatch(saveAllConstants(resConst.value));
          // }
          // const resNotif = await Get(ApiRoutes.notificationList, {});
          // if (resNotif && resNotif.success && resNotif.value ) {
          //   dispatch(saveNotification(resNotif.value));
          // }
          // const resLabel = await Post(ApiRoutes.labelList, {});
          // if (resLabel && resLabel.success && resLabel.value ) {
          //   dispatch(saveLists({section:'label',data:resLabel.value}));
          // }
        }  
        return;
      } catch (err) {
        console.error('bootStrapReload',err);
        navigate('/');
      }
    };
    bootStrapReload();
  
  }, []);

  const sideLoadNotif = async () =>{
    try {
      const resNotif = await Get(ApiRoutes.notificationList, {});
      if (resNotif && resNotif.success && resNotif.value ) {
        dispatch(saveNotification(resNotif.value));
      }
    } catch (err) {
      console.error('sideLoad Error ',err);      
    }
  };

  const sideLoad = async () => {
    try {

      await sideLoadNotif();

      const resConst = await Post(ApiRoutes.constants, {});
      if (resConst && resConst.success && resConst.value ) {
        dispatch(saveAllConstants(resConst.value));
      }
      const resLabel = await Post(ApiRoutes.labelList, {});
      if (resLabel && resLabel.success && resLabel.value ) {
        dispatch(saveLists({section:'label',data:resLabel.value}));
      }
      
      // Loop Side Loading Notifiation every 1mn
      const intervalSideLoadNotif = setInterval(sideLoadNotif, 60000);       
      return () => clearInterval(intervalSideLoadNotif);

    } catch (err) {
      console.error('sideLoad Error ',err);      
    }
  };

  const loginAction = async (data) => {
    try {
      const res = await Post(ApiRoutes.login, data);        
      if (res && res.success && res.value.token && res.value.user) {        
        setAdmin(res.value.user);
        setToken(res.value.token);
        openNotification('success',`Welcome Back ${res.value.user?.firstName} !`);

        navigate("/dashboard");

        await sideLoad();
        // dispatch(saveAllConstants(res.value.constants));        
        // dispatch(saveNotification(res.value.notification));
        // dispatch(saveLists({section:'label',data:res.value.label}));


        return;
      } else {
        openNotification('error',ErrorMsg(t,res.error));
      }      
    } catch (err) {
      console.error(err);
    }
  };

  const logOut = () => {
    setAdmin();
    dispatch(resetFilters());    
    dispatch(resetLists());    
    dispatch(resetConstants());        
    dispatch(resetNotification());   
    removeToken();
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ token, admin, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};