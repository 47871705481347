import React from 'react';
import dayjs from 'dayjs';
import {  Input, Checkbox, DatePicker,TimePicker,ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR';
import { DeleteOutlined} from '@ant-design/icons';
import MissionSlots from '../elements/MissionSlots';

import 'dayjs/locale/fr';
import 'dayjs/plugin/updateLocale';

if (dayjs.updateLocale){
    dayjs.updateLocale('fr', {
        weekStart: 0,
      });
}

const StepTwo =  ({section, missionSlots,bmgFilters,addEntry,deleteEntry,changeEntry,roles,positions,locations,edays,timeSlots ,selectEntry,selectedEntries,selectDisables,onDeleteMissionsSlots}) => {    
    
    // const { openNotification } = useNotification();
    // const dispatch = useDispatch();
    // const { t } = useTranslation();    
    
    // const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
    // const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);      

    const hasRole = selectedEntries.some(item => item.startsWith('role'));
    const hasPosition = selectedEntries.some(item => item.startsWith('position'));
    const hasLocation = selectedEntries.some(item => item.startsWith('location'));
    const hasTimeSlot = selectedEntries.some(item => item.startsWith('timeSlot'));
    const hasEDay = selectedEntries.some(item => item.startsWith('eday'));
    
    const isEnabled = hasRole && hasPosition && hasLocation && hasTimeSlot && hasEDay;    
    
    const disabledDate = (current) => {
        return current && current < dayjs().endOf('day');
    };
    // let cnt = 0;
    // for(const mission of missionSlots){
    //     console.log('miss',mission.workForce);
    //     if (mission
    //         //&& mission.location
    //          && mission.role
    //         // && mission.position
    //         // && mission.eday
    //         // && mission.timeSlot                        
    //         ) {
    //             cnt++;
    //         } else {
    //             console.log(mission)
    //         }

    // }
    // console.log('miss',missionSlots.length,cnt);
    return(
        <div className="step-content">    
            <div className='step-title flex'>
                <span className='h2'>Planification des Missions {missionSlots.length ? ' (' +missionSlots.length+' Missions)' : ''}</span>
                {missionSlots.length > 0 &&
                    <div onClick={()=>onDeleteMissionsSlots()} style={{marginLeft:'32px'}} className='link'>Effacer toutes les lignes</div>
                }                
            </div>        
            <div className='step-container step2'>                
                <MissionSlots
                    section={section}
                    missionSlots={missionSlots}
                    changeEntry={changeEntry}
                    deleteEntry={deleteEntry}
                />
                <div className='operation-form-block'>
                    <div className='button-step' onClick={()=>addEntry('location')}>
                        <div>Lieu +</div>
                    </div>
                    <div className='list-step'>
                        {(locations && locations.length !== 0) &&
                            locations.map((location,index)=>{
                                return(
                                    <div key={index} className='list-form-line-edit'>
                                        <Checkbox 
                                            style={{marginRight:'6px'}}
                                            id={'location_'+index}
                                            checked={selectedEntries.includes('location_'+index)} 
                                            disabled={selectDisables.includes('location_'+index)} 
                                            className="custom-checkbox bigger"
                                            onChange={selectEntry}
                                        />         
                                        <div style={{border:'solid 1px var(--accent-color)',padding:'6px',borderRadius:'6px'}}>
                                            <Input 
                                                title="Nom du Lieu"                                            
                                                value={location.name}
                                                id="name"
                                                name="name"
                                                placeholder="Nom du Lieu" 
                                                className=""
                                                onChange={(e)=>changeEntry('location',e,index,'name')}
                                            />
                                            <Input 
                                                title="Adresse"                                            
                                                value={location.address}
                                                id="address"
                                                name="address"
                                                placeholder="Adresse" 
                                                className=""
                                                onChange={(e)=>changeEntry('location',e,index,'address')}
                                            />
                                            <Input 
                                                title="Code Postal"                                            
                                                value={location.zipcode}
                                                id="zipcode"
                                                name="zipcode"
                                                placeholder="Code Postal" 
                                                className=""
                                                onChange={(e)=>changeEntry('location',e,index,'zipcode')}
                                            />
                                            <Input 
                                                title="Ville"                                            
                                                value={location.city}
                                                id="city"
                                                name="city"
                                                placeholder="Ville" 
                                                className=""
                                                onChange={(e)=>changeEntry('location',e,index,'city')}
                                            />
                                            {/* <Input 
                                                title="Pays"                                            
                                                value={location.country}
                                                id="country"
                                                name="country"
                                                placeholder="Pays" 
                                                className=""
                                                onChange={(e)=>changeEntry('location',e,index,'country')} 
                                            /> 
                                            */}
                                        </div>
                                        <DeleteOutlined className="link" style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('location',index)}/>
                                    </div>
                                )
                            })
                        }
                    </div>                    
                </div>    
                <div className='operation-form-block'>
                    <div className='button-step' onClick={()=>addEntry('role')}>
                        <div>Rôles +</div>
                    </div> 
                    <div className='list-step'>
                        {(roles && roles.length !== 0) &&
                            roles.map((role,index)=>{
                                return(
                                    <div key={index} className='list-form-line-edit'>
                                        <Checkbox 
                                            style={{marginRight:'6px'}}
                                            id={'role_'+index}
                                            checked={selectedEntries.includes('role_'+index)} 
                                            disabled={selectDisables.includes('role_'+index)}  
                                            className="custom-checkbox bigger"
                                            onChange={selectEntry}
                                        />
                                         <div style={{border:'solid 1px var(--accent-color)',padding:'6px',borderRadius:'6px'}}>                                           
                                            <Input 
                                                title="Rôle"                                            
                                                value={role.name}
                                                id="name"
                                                name="name"
                                                placeholder="Nouveau Rôle" 
                                                className=""
                                                onChange={(e)=>changeEntry('role',e,index,'name')}
                                            />
                                            <div style={{display:"flex",flexDirection:"row"}}>
                                                <Input                                    
                                                    title="Tarif Hôte"                                            
                                                    value={role.price}
                                                    id="price"
                                                    name="price"
                                                    placeholder="Tarif Hôte" 
                                                    className=""
                                                    onChange={(e)=>changeEntry('role',e,index,'price')}
                                                />
                                                <Input                               
                                                    title="Tarif Client"                                            
                                                    value={role.cost}
                                                    id="cost"
                                                    name="cost"
                                                    placeholder="Tarif Client" 
                                                    className=""
                                                    onChange={(e)=>changeEntry('role',e,index,'cost')}
                                                />                                              
                                            </div>
                                        </div>
                                        <DeleteOutlined className="link" style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('role',index)}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>  
                <div className='operation-form-block'>
                    <div className='button-step' onClick={()=>addEntry('position')}>
                        <div>Postes +</div>
                    </div> 
                    <div className='list-step'>
                        {(positions && positions.length !== 0) &&
                            positions.map((ele,index)=>{
                                return(
                                    <div key={index} className='list-form-line-edit'>
                                        <Checkbox 
                                            style={{marginRight:'6px'}}
                                            id={'position_'+index}
                                            checked={selectedEntries.includes('position_'+index)} 
                                            disabled={selectDisables.includes('position_'+index)} 
                                            className="custom-checkbox bigger"
                                            onChange={selectEntry}
                                        />     
                                        <Input 
                                            title="Poste"                                            
                                            value={ele}
                                            id="name"
                                            name="name"
                                            placeholder="Nouveau Poste" 
                                            className=""
                                            onChange={(e)=>changeEntry('position',e,index)}
                                        />
                                        <DeleteOutlined className="link" style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('position',index)}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>                    
                <div className='operation-form-block'>
                    <div className='button-step' onClick={()=>addEntry('eday')}>
                        <div>Date +</div>
                    </div>
                    <div className='list-step'>
                        {(edays && edays.length !== 0) &&
                                edays.map((days,index)=>{
                                    return(
                                        <div key={index} className='list-form-line-edit'>
                                            <Checkbox 
                                                style={{marginRight:'6px'}}
                                                id={'eday_'+index}
                                                checked={selectedEntries.includes('eday_'+index)} 
                                                disabled={selectDisables.includes('eday_'+index)} 
                                                className="custom-checkbox bigger"
                                                onChange={selectEntry}
                                            />   
                                             <ConfigProvider locale={frFR}>
                                                <DatePicker 
                                                    multiple 
                                                    onChange={(dates)=>changeEntry('eday',dates,index)}
                                                    maxTagCount="responsive" 
                                                    value={days} 
                                                    format='DD/MM/YYYY'
                                                    disabledDate={disabledDate}
                                                />
                                            </ConfigProvider>
                                            <DeleteOutlined className="link" style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('eday',index)}/>
                                        </div>
                                    )
                                })
                            }                        
                    </div>
                </div>
                <div className='operation-form-block'>
                    <div className='button-step' onClick={()=>addEntry('timeSlot')}>
                        <div>Plage Horaire +</div>
                    </div>
                    <div className='list-step'>
                        {(timeSlots && timeSlots.length !== 0) &&
                                timeSlots.map((timeSlot,index)=>{
                                    return(
                                        <div key={index} className='list-form-line-edit'>
                                            <Checkbox 
                                                style={{marginRight:'6px'}}
                                                id={'timeSlot_'+index}
                                                checked={selectedEntries.includes('timeSlot_'+index)} 
                                                disabled={selectDisables.includes('timeSlot_'+index)} 
                                                className="custom-checkbox bigger"
                                                onChange={selectEntry}
                                            />
                                            <TimePicker
                                                id='stime'
                                                format={'HH:mm'}
                                                minuteStep={15} 
                                                needConfirm={false}
                                                showNow={false}
                                                value={timeSlot.stime ? dayjs(timeSlot.stime,"HH.mm") : ''}
                                                onChange={(time)=>changeEntry('timeSlot',time,index,'stime')}
                                                />
                                            <span style={{margin:'0 3px'}}>à</span>
                                            <TimePicker
                                                id='etime'
                                                format={'HH:mm'}
                                                minuteStep={15} 
                                                needConfirm={false}
                                                showNow={false}
                                                value={timeSlot.etime ? dayjs(timeSlot.etime,"HH.mm") : ''}
                                                onChange={(time)=>changeEntry('timeSlot',time,index,'etime')}
                                                />                                     
                                            <DeleteOutlined className="link" style={{marginLeft:'6px', color:'var(--accent-color)'}} onClick={()=>deleteEntry('timeSlot',index)}/>
                                        </div>
                                    )
                                })
                            }                        
                    </div>
                </div>  
                <div className='operation-form-block'>
                    <div className={isEnabled ? 'button-step' : 'button-step-disabled'} onClick={()=>isEnabled ? addEntry('team'): null}>
                        <div>Besoin Effectifs +</div>
                    </div>     
                </div>                                                                    
            </div>         
        </div>
    )
};

export default StepTwo;