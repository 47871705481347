import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    user: {},
    list: [],
    users: [],
  },
};

export const messageSlice = createSlice({
  name: 'message',
  initialState: initialState,
  reducers: {
    getMessage: (state) => {
      return state.value 
    },
    saveMessage: (state, action) => {
      state.value = action.payload;
    },
    resetMessage: (state, action) => {
      state.value = initialState;
    },    
  },
})

// Action creators are generated for each case reducer function
export const { getMessage, saveMessage,resetMessage} = messageSlice.actions

export default messageSlice.reducer