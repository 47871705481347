import React, { useState,useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import UserGeneral from "./UserGeneral";
import { saveMessage } from '../../../features/message/messageSlice';
import MessageForm from '../../message/MessageForm';
import MessageThread from '../../message/MessageThread';
import { GetAvatar } from '../../../services/User'

const MessagesTab = ({section, bmgFilters,  openGeneral, setOpenGeneral}) => { 
    
    const dispatch = useDispatch();

    const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : null);

    const [avatarFile, setAvatarFile] = useState(null);

    useEffect(() => {

        if (storedUser && storedUser._id) {

            const getAvatar = async ()  =>{
                if (storedUser) {
                    const avatarImg = await GetAvatar(storedUser);
                    setAvatarFile( avatarImg );
                }
            }
            getAvatar();

            dispatch(saveMessage({user:storedUser,list:[],users:[]}));
        }
    }, [storedUser]); 
    
    return(
        <div className="tab-content">
            <UserGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
            />
            <div className={'tab-block user-block '+ (openGeneral ? '' : 'folded')}>
                <MessageThread avatarFile={avatarFile} storedUser={storedUser}/>
                <MessageForm handlerMethod={null}/>                
            </div>
        </div>
    )
};

export default MessagesTab;