import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
// import { useTranslation } from 'react-i18next';
import { NumberOutlined, UserAddOutlined } from '@ant-design/icons';
import HeaderBar from "../../../components/layout/HeaderBar";

const section = 'setting';

const SettingList = () => {
  
  const navigate = useNavigate();  
  // const { t } = useTranslation();

  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});

  return (
    <div className="container">
        <HeaderBar hasFilters={false} hasSort={false} hasSearch={false} section={section} storedFilters={storedFilters}/>
        <div className="content flex">    
          <div onClick={()=>navigate('/setting/admin')} className="button-block">
            <UserAddOutlined/>
            <div  style={{marginLeft:'16px'}}>Admins</div>
          </div>  
          <div onClick={()=>navigate('/setting/label')} className="button-block">
            <NumberOutlined/>
            <div  style={{marginLeft:'16px'}}>Labels</div>
          </div>        
        </div>
    </div>    
  );
};

export default SettingList;