import { Post, ApiRoutes } from "./Api"

export const SaveOp = async (data) => {
    try {
        const res = await Post(ApiRoutes.opAdd, data);
        if (res) return res.value;        
    } catch (err) {
      console.error('SaveOp resultList',err);
    }
};

export const EditOpProps = async (itemId,props,origin='',staffId=null) =>{
    try {
        const res = await Post(ApiRoutes.opEdit, { staffId, origin, id: itemId, ...props});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error EditOpProps');
        }        
    } catch (err) {
      console.error('EditOpProps Err',err);
    }
};

export const GetOp = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.opEdit, { id: itemId});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error EditOpProps');
        }        
    } catch (err) {
      console.error('EditOpProps Err',err);
    }
};

export const DeleteOp = async (itemId) =>{
    try {
        return await Post(ApiRoutes.opDelete, { id: itemId});   
    } catch (err) {
      console.error('DeleteOp Err',err);
    }
};

export const CloneOp = async (itemId) =>{
    try {
        const res = await Post(ApiRoutes.opClone, { id: itemId});
        if (res && res.success && res.value){          
            return res.value;
        } else {
            alert('Error CloneOp');
        }        
    } catch (err) {
      console.error('CloneOp Err',err);
    }
};

export const CountOps = async (data) => {
    try {
        const res = await Post(ApiRoutes.opCount, data);
        return res.total;
    } catch (err) {
      console.error('CountOps total error',err);
    }
};

export const ListOps = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.opList, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('ListOps resultList',err);
    }
};
