import React,{ useEffect, useState} from "react";
// import { useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {  Table } from 'antd';
import { useMediaQuery } from 'react-responsive';

//import { saveLists } from '../../../features/lists/listsSlice';
import { ListAdmins, DeleteAdmin } from '../../../services/Admin';
import { useNotification } from '../../../hooks/NotificationProvider';
//import PopOver from "../../../components/elements/PopOver"
import HeaderBar from "../../../components/layout/HeaderBar";
import { getAdmin } from "../../../services/Api"
import {capitalize, sortOnField} from "../../../utils/miscHelpers"

const section = 'admin';

const AdminList = () => {

  const { openNotification } = useNotification();
  //const { t } = useTranslation();
  const navigate = useNavigate();
  //const dispatch = useDispatch();

  //const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [list, setList] = useState([]);

  const admin = getAdmin();
  
  useEffect(() => {

    const fetchData = async () => {
      try {      
        const results = await ListAdmins();
        setList(sortOnField(results.resultList,'lastName')); 
        return;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();    
  }, []); 

  const addAdmin = () =>{
    navigate('/setting/admin/form');
  };

  const linkMe = async (record)=>{    
    navigate('/setting/admin/form',{state:record});
  };

  const Delete = async (record) =>{    
    if (window.confirm('Êtes-vous sur de vouloir effacer cet Utilisateur ?')) {
      const resValue = await DeleteAdmin(record._id);     
      if (resValue) {        
        setList([...list].filter((ele)=>ele._id !== record._id))
        openNotification('success',"Utilisateur Supprimé");
      } else {
        openNotification('error',"Erreur durant la suppression");
        return true;
      } 
    }
  };

  let columns = [
    { 
      key: "name", 
      title: "Nom", 
      dataIndex: "name", 
      fixed: 'left',
      width: '200px',
      render: (_, record) => { 
        return ( 
          <> 
            <div className="custom-name">{capitalize(record.firstName)} {capitalize(record.lastName)}</div> 
          </> 
          ); 
        },      
      },    
      { 
        key: "email", 
        title: "Email", 
        dataIndex: "email",      
      },  
      { 
        key: "role", 
        title: "Rôle", 
        dataIndex: "role",
        render: (_, record) => { 
          return ( 
            <> 
              <div>{process.env.REACT_APP_SUPER_ADMIN.includes(record._id) ? 'Admin' : '-'}</div> 
            </> 
            ); 
          },     
      },                         
      { 
        key: "action", 
        title: "Actions", 
        width: '100px',
        fixed: 'right',
        render: (record) => { 
          return ( 
            <> 
              <div className="flex"> 
                {(process.env.REACT_APP_SUPER_ADMIN.includes(admin._id) || record._id === admin._id) && 
                  <EditOutlined 
                    title="Editer"
                    className='icon-table'
                    onClick={()=>linkMe(record)}
                  /> 
                }
                {(process.env.REACT_APP_SUPER_ADMIN.includes(admin._id)) && 
                  <DeleteOutlined 
                    title="Effacer"
                    className='icon-table'
                    onClick={() => Delete(record)} 
                  /> 
                }
              </div> 
            </> 
          ); 
        }, 
      },                  
  ];
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
  columns = columns.map(col => ({
    ...col,
    fixed: isMobile ? undefined : col.fixed,
  }));
  return (
    <div className="container">
        <HeaderBar 
          actionContent={
            <div className="header-actions" style={{ marginLeft:"16px"}}>
              <div onClick={()=>addAdmin()} className="secondary-button" >+</div>
            </div>
          }        
          hasBack={true} 
          hasFilters={false} 
          hasSort={false} 
          hasSearch={false} 
          section={section} 
          storedFilters={{}}
        />
        <div className="content">    
          <div>
            <Table 
              dataSource={list} 
              rowKey="_id"
              columns={columns} 
              pagination={false} 
              className="capitalize norowselection custom-table"
              scroll={{
                y: 620,
                x: 1300
              }}
            />           
          </div>
        </div>
    </div>    
  );
};

export default AdminList;